import React from "react";


const FormReentering = ({
    Form, Badge, pwdConfirmed, hide2nd, FaEyeSlash, FaEye, toggle2nd, confirmPassword, setConfirmPassword, handleReenteringKeyUp
}) => {

    return (
        <Form.Floating className="my-4 position-relative">
            <div className="position-absolute top-50 end-0 translate-middle-y px-3 fs-5 text-secondary">
                <Badge pill bg="danger" className={`xxs-fs me-1 ${pwdConfirmed?"d-none":""}`}>Re-entering Not Same</Badge>
                { hide2nd ? <FaEyeSlash className="clickable-icon" onClick={() => toggle2nd()}/> : <FaEye className="clickable-icon" onClick={() => toggle2nd()}/> }
            </div>
            <Form.Control 
                className="input-field border-dark-subtle" 
                id="register-confirm-password"
                type={hide2nd ? "password" : "text"}
                name="confirm-password"
                placeholder="Confirm password"
                onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                onKeyUp={handleReenteringKeyUp}
                value={confirmPassword}
                autoComplete="re"
                required
            />
            <label htmlFor="register-confirm-password" className="input-label text-black-50">Re-enter Password</label>
        </Form.Floating>
    );

}

export default FormReentering;