import React from "react";
import Header from "../Header/Header";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

const EmptyHandler = ({ title, marginFromFoot=0 }) => {

    return (
        <>
            <Header />
            <div id="empty-cart-container" className='p-5'>
                <Container className='py-4'>
                    <Row className='my-4 mx-3 fs-4 text-center text-sm-start'>
                        <p className='m-0'>
                            {title}
                        </p>
                    </Row>
                    <Row className="my-5 fs-5 text-center">
                        <Col className='d-inline'>
                            <Image src='/bgImg/cart-img-1.png' alt='wine-cartoon' className='empty-cart-img mx-2 d-none d-xl-inline'/>
                            <Image src='/bgImg/cart-img-2.png' alt='wine-cartoon' className='empty-cart-img mx-2 d-none d-md-inline'/>
                            <Image src='/bgImg/cart-img-3.png' alt='wine-cartoon' className='empty-cart-img mx-2 d-none d-sm-inline'/>
                            <Link to="/product" className='mx-sm-5 fs-5'>Shop Now</Link>
                            <Image src='/bgImg/cart-img-4.png' alt='wine-cartoon' className='empty-cart-img mx-2 d-none d-sm-inline'/>
                            <Image src='/bgImg/cart-img-5.png' alt='wine-cartoon' className='empty-cart-img mx-2 d-none d-md-inline'/>
                            <Image src='/bgImg/cart-img-6.png' alt='wine-cartoon' className='empty-cart-img mx-2 d-none d-xl-inline'/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer marginTop={marginFromFoot}/>
        </>
    );
}

export default EmptyHandler;