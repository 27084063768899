import React, { useEffect, useRef } from "react";
import { 
    loginByGoogle, 
    loginByLocal, 
    selectGoogleHasError, 
    selectLoginErrorMsg, 
    selectLoginHasError, 
} from "../../features/sessionSlice";
import "../../stylesheets/Login.css";
import { Modal, Button, Form, Container, Row, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import isEmail from 'validator/lib/isEmail';

const LoginModal = ({
    dispatch, useState, useSelector, showLoginModal, setShowLoginModal, setShowRegisterModal, FaEye, FaEyeSlash, PiWarningCircleLight, validationBorder, invalidationBorder
}) => {
    const loginHasError = useSelector(selectLoginHasError);
    const googleLoginHasError = useSelector(selectGoogleHasError);
    const localLoginMsg = useSelector(selectLoginErrorMsg);
    const rightsideRef = useRef(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [promptLocalUser, setPromptLocalUser] = useState(false);
    const [promptGoogleUser, setPromptGoogleUser] = useState(false);
    const [hide, setHide] = useState(true);

    useEffect(() => {

        if (showLoginModal) {

            const updateGoogleBtnSize = () => {

                const containerWidth = rightsideRef.current && rightsideRef.current.offsetWidth;

                const width = Number(containerWidth - containerWidth * 0.12).toFixed(0);

                window.google.accounts && window.google.accounts.id.renderButton(
                    document.getElementById("googleSignInDiv"),
                    { 
                        theme: "outline", 
                        size: "large",
                        width,
                        heigt: 40,
                        shape: "pill",
                        longtitle: true,
                    },
                );

            }

            /* global google */
            window.google.accounts && window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleGoogleCallBackRes,
            });

            updateGoogleBtnSize();
            window.addEventListener('resize', updateGoogleBtnSize);

            return () => window.removeEventListener('resize', updateGoogleBtnSize);

        }


    }, [showLoginModal]);

    useEffect(() => {

        if (loginHasError) {
            setPromptLocalUser(true);
        }

    }, [loginHasError]);

    useEffect(() => {

        if (googleLoginHasError) {
            setPromptGoogleUser(true);
        }

    }, [googleLoginHasError]);

    const handleGoogleCallBackRes = (response) => {

        setPromptLocalUser(false);
        setPromptGoogleUser(false);

        dispatch(loginByGoogle({ut: response.credential}));

    };
    
    const handleLocalSignIn = (e) => {

        e.preventDefault();

        setPromptLocalUser(false);
        setPromptGoogleUser(false);

        dispatch(loginByLocal({email, password}));

        setPassword('');

    };

    const handleEmailKeyUp = (e) => {

        const { value } = e.target;
        
        if(isEmail(value)) {
            validationBorder(e.target);
        } else {
            invalidationBorder(e.target);
        }

    };

    const handlePwdKeyUp = (e) => {
        const { value } = e.target;

        if(value.length>=6) {
            validationBorder(e.target);
        } else {
            invalidationBorder(e.target);
        }
    };

    const swapModal = () => {
        dispatch(setShowLoginModal(false));
        dispatch(setShowRegisterModal(true));
    };

    const handleExited = () => {
        setEmail('');
        setPassword('');
        setPromptGoogleUser('');
        setPromptLocalUser('');
        setPromptLocalUser(false);
        setPromptGoogleUser(false);
    };

    return (
        <Modal 
            centered
            size="lg" 
            show={showLoginModal} 
            fullscreen={true}
            dialogClassName="modal-width"
            onHide={() => dispatch(setShowLoginModal(false))}
            onExited={() => handleExited()}
            aria-labelledby="example-modal-sizes-title-lg"
            id="login-modal"
        >

            <Modal.Body className="py-0 ps-md-0">
                <div className="modal-close-cube">
                    <FontAwesomeIcon 
                        icon={faXmark} 
                        className="modal-close-icon fs-4" 
                        onClick={() => dispatch(setShowLoginModal(false))}
                    />
                </div>
                <Container className="ps-md-0">
                    <Row className="modal-row">
                        <Col md={5} lg={6} className="d-none d-md-block"><div id="login-image"></div></Col>
                        <Col md={7} lg={6} ref={rightsideRef} className="my-auto">

                            <p className="m-0 modal-logo">
                                <span className="modal-logo-j">J·</span>
                                <span className="modal-logo-cellar">CELLAR</span>
                                <span className="text-secondary mx-1 mx-xl-2 modal-vertical-rules"> | </span>
                                <span className="modal-red-text text-secondary">Log in</span>
                            </p>
                            
                            <Form id="login-form" onSubmit={handleLocalSignIn}>
                                <Form.Floating className="my-4">
                                    <Form.Control 
                                        className="input-field border-dark-subtle" 
                                        id="login-email"
                                        type="email" 
                                        name="email"
                                        placeholder="name@example.com"
                                        onChange={(e) => setEmail(e.currentTarget.value)}
                                        onFocus={() => setPromptLocalUser(false)}
                                        onKeyUp={handleEmailKeyUp}
                                        value={email}
                                    />
                                    <label htmlFor="login-email" className="input-label text-black-50">Email Address</label>
                                </Form.Floating>
                                <Form.Floating className="my-4">
                                    <div className="position-absolute top-50 end-0 translate-middle-y px-3 fs-5 text-secondary">
                                        { hide ? <FaEyeSlash className="clickable-icon" onClick={() => setHide(!hide)}/> : <FaEye className="clickable-icon" onClick={() => setHide(!hide)}/> }
                                    </div>
                                    <Form.Control 
                                        className="input-field border-dark-subtle" 
                                        id="login-password"
                                        type={hide? "password" : "text"}
                                        name="password"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.currentTarget.value)}
                                        onFocus={() => setPromptLocalUser(false)}
                                        onKeyUp={handlePwdKeyUp}
                                        value={password}
                                        autoComplete="pw"
                                    />
                                    <label htmlFor="login-password" className="input-label text-black-50">Password</label>
                                </Form.Floating>
                                <div className="d-grid my-4">
                                    <Button variant="outline-success" size="lg" type="submit" className="rounded-pill">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                            { 
                                promptLocalUser ? 
                                    <Alert variant="danger" className="rounded-3 py-1 px-2 m-0 fst-italic d-flex align-items-center">
                                        <PiWarningCircleLight className="me-2 fs-5"/> {localLoginMsg}
                                    </Alert>
                                : 
                                    ''
                            }
                            <Container className="mt-4">
                                <Row>
                                    <Col xs={5} className="m-0 p-0">
                                        <hr className="border border-danger border-1 opacity-50"></hr>
                                    </Col>
                                    <Col xs={2} className="d-flex align-items-center justify-content-center p-0">
                                        <h5 className="m-0 text-center">Or</h5>
                                    </Col>
                                    <Col xs={5} className="m-0 p-0">
                                        <hr className="border border-danger border-1 opacity-50"></hr>
                                    </Col>
                                </Row>
                                <div className="my-4" id="googleSignInDiv"></div>
                            </Container>
                            {
                                promptGoogleUser ?
                                    <p className="prompt-text">Authentication failed.</p> 
                                :
                                    ''
                            }
                            <div id="to-register" className="mt-2">
                                <Button 
                                    variant="outline-danger" 
                                    className="border-0 rounded-pill" 
                                    onClick={() => swapModal()}
                                >
                                    New here? Sign up
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
};

export default LoginModal;