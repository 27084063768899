import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { 
    addItemToWishlist,
    createGoogleUser, 
    createUser, 
    getUserInfoById, 
    getWishlist, 
    getWishlistSummary, 
    loginLocalUser, 
    removeWishlistItem, 
    signOut, 
    updateProfile, 
    verifyAuthentication 
} from "../utils/user"; 
import { defaultCartStates, loadCartSummary } from "./cartSlice";
import { defaultOrderStates } from "./orderSlice";

export const loadUserInfo = createAsyncThunk(
    'session/loadUserInfo',
    async(_, { rejectWithValue, dispatch }) => {

        const userAuth = await verifyAuthentication();

        const { authenticated, userId } = userAuth;

        if (!authenticated) {
            return rejectWithValue([]);
        }

        await dispatch(loadCartSummary());

        await dispatch(loadWishlistSummary());

        const response = await getUserInfoById(userId);

        return { user: response };

    }
);

export const registerUser = createAsyncThunk(
    'session/registerUser',
    async(userObj, { rejectWithValue }) => {

        const response = await createUser(userObj);

        if(!response.ok) {
            return rejectWithValue(response.message);
        }

        return { user: response.data };
    }
);

export const loginByGoogle = createAsyncThunk(
    'session/logInByGoogle',
    async(utObj, { rejectWithValue, dispatch }) => {

        const response = await createGoogleUser(utObj);

        if (!response.ok) {

            if(response.status===403) {
                return rejectWithValue("Email has been used");
            } 

            return rejectWithValue("Authentication Failed");
        };

        await dispatch(loadCartSummary());

        await dispatch(loadWishlistSummary());

        return { user: response.data };
    }
);

export const loginByLocal = createAsyncThunk(
    'session/loginByLocal',
    async(userObj, { rejectWithValue, dispatch }) => {

        const response = await loginLocalUser(userObj);

        if (!response.ok) {
            return rejectWithValue("Invalid email or password");
        };

        await dispatch(loadCartSummary());

        await dispatch(loadWishlistSummary());

        return { user: response.data };

    }
);

export const logOutUser = createAsyncThunk(
    'session/logOutUser',
    async(_, { dispatch, rejectWithValue }) => {

        const response = await signOut();

        if(!response.ok) {
            rejectWithValue("Error, Try Again");
        }
        
        dispatch(defaultCartStates());

        dispatch(defaultOrderStates());

    }
);

export const loadWishlist = createAsyncThunk(
    'session/loadWishlist',
    async() => {

        const response = await getWishlist();

        return response;

    }
);

export const loadWishlistSummary = createAsyncThunk(
    'session/loadWishlistProductList',
    async() => {

        const response = await getWishlistSummary();

        return response;

    }
);

export const addNewAndLoadWishlist = createAsyncThunk(
    'session/addNewAndLoadWishlist',
    async(data, { rejectWithValue }) => {

        const { productId } = data;

        const response = await addItemToWishlist(productId);

        if(!response.ok) {
            return rejectWithValue();
        }

        return response.productArr;

    }
);

export const removeItemAndLoadWishlist = createAsyncThunk(
    'session/removeItemAndLoadWishlist',
    async(data, { rejectWithValue }) => {

        const { productId } = data;

        const response = await removeWishlistItem(productId);

        if(!response.ok) {
            return rejectWithValue();
        }

        return response.productArr;

    }
);

export const updateProfileInfo = createAsyncThunk(
    'session/updateProfileInfo',
    async(data, { rejectWithValue, dispatch }) => {

        const startTime = Date.now();
        const minimumPeriod = 1500;

        const { aspect, newValue } = data;

        const response = await updateProfile(aspect, newValue);

        if(!response.ok) {

            if(aspect==="password" && response.status===304) {
                return rejectWithValue("New password can't be same with the old");
            }

            if(aspect==="email" && response.status===403) {
                return rejectWithValue("Email has been used");
            }

            return rejectWithValue("Error. Please try again");

        };

        const elapsedTime = Date.now() - startTime;
        if(elapsedTime < minimumPeriod) {
            await new Promise((resolve) => setTimeout(resolve, minimumPeriod - elapsedTime));
        };

        const st2nd = Date.now();
        const mp2nd = 1000;

        dispatch(setProfileSuccessNotification(true));

        const et2nd = Date.now() - st2nd;
        if(et2nd < mp2nd) {
            await new Promise((resolve) => setTimeout(resolve, mp2nd - et2nd));
        };

        const { userInfoAspect, userInfoValue } = response.data;

        return { userInfoAspect, userInfoValue };

    }
);

const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        session: {},
        isLoading: true,
        hasError: false,
        errorMessage: '',
        loginStatus: false,
        login: { 
            isLoading: false, 
            hasError: false, 
            errorMessage: '',
        }, 
        googleLogin: {
            isLoading: false,
            hasError: false,
            errorMessage: '',
        },
        register: {
            isLoading: false,
            hasError: false,
            errorMessage: '',
        },
        profile: {
            isLoading: false,
            hasError: false,
            errorMessage: '',
            showProfileModal: false,
            notifySuccess: false,
        },
        wishlist: [],
        isLoadingWishlist: false,
        hasErrorWithWishlist: false,
        wishlistSummary: [],
        showLoginModal: false,
        showRegisterModal: false,
    },
    reducers: {
        setShowLoginModal: (state, action) => {
            state.showLoginModal = action.payload;
        },
        setShowRegisterModal: (state, action) => {
            state.showRegisterModal = action.payload;
        },
        setRegisterMsgDefault: (state) => {
            state.register.errorMessage = '';
        },
        setShowProfileModal: (state, action) => {
            state.profile.showProfileModal = action.payload;
        },
        setProfileMsgDefault: (state) => {
            state.profile.errorMessage = '';
        },
        setProfileSuccessNotification: (state, action) => {
            state.profile.notifySuccess = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(registerUser.pending, (state) => {
            state.register.isLoading = true;
            state.register.hasError = false;
            state.register.errorMessage = '';
            state.loginStatus = false;
        })
        .addCase(registerUser.fulfilled, (state, action) => {
            state.register.isLoading = false;
            state.register.hasError = false;
            state.session = action.payload;
            state.loginStatus = true;
            state.showRegisterModal = false;
            state.isLoading = false;
            state.hasError = false;
        })
        .addCase(registerUser.rejected, (state, action) => {
            state.register.isLoading = false;
            state.register.hasError = true;
            state.register.errorMessage = action.payload;
            state.loginStatus = false;
        })
        .addCase(loginByGoogle.pending, (state) => {
            state.googleLogin.isLoading = true;
            state.googleLogin.hasError = false;
            state.googleLogin.errorMessage = '';
            state.loginStatus = false;
        })
        .addCase(loginByGoogle.fulfilled, (state, action) => {
            state.googleLogin.isLoading = false;
            state.googleLogin.hasError = false;
            state.session = action.payload;
            state.loginStatus = true;
            state.showLoginModal = false;
            state.isLoading = false;
            state.hasError = false;
        })
        .addCase(loginByGoogle.rejected, (state, action) => {
            state.googleLogin.isLoading = false;
            state.googleLogin.hasError = true;
            state.googleLogin.errorMessage = action.payload;
            state.loginStatus = false;
        })
        .addCase(loginByLocal.pending, (state) => {
            state.login.isLoading = true;
            state.login.hasError = false;
            state.login.errorMessage = '';
            state.loginStatus = false;
        })
        .addCase(loginByLocal.fulfilled, (state, action) => {
            state.login.isLoading = false;
            state.login.hasError = false;
            state.session = action.payload;
            state.loginStatus = true;
            state.showLoginModal = false;
            state.isLoading = false;
            state.hasError = false;
        })
        .addCase(loginByLocal.rejected, (state, action) => {
            state.login.isLoading = false;
            state.login.hasError = true;
            state.login.errorMessage = action.payload;
            state.loginStatus = false;
        })
        .addCase(logOutUser.pending, (state) => {
            state.isLoading = true;
            state.hasError = false;
            state.errorMessage = '';
        })
        .addCase(logOutUser.fulfilled, (state) => {
            state.isLoading = false;
            state.hasError = false;
            state.session = {};
            state.loginStatus = false;
            state.wishlist = [];
            state.wishlistSummary = [];
        })
        .addCase(logOutUser.rejected, (state, action) => {
            state.isLoading = false;
            state.hasError = true;
            state.errorMessage = action.payload;
        })
        .addCase(loadUserInfo.pending, (state) => {
            state.isLoading = true;
            state.hasError = false;
            state.errorMessage = '';
            state.loginStatus = false;
        })
        .addCase(loadUserInfo.fulfilled, (state, action) => {
            if (action.payload.length===0) {
                state.loginStatus = false;
            } else {
                state.loginStatus = true;
                state.session = action.payload;
            }
            state.isLoading = false;
            state.hasError = false;
        })
        .addCase(loadUserInfo.rejected, (state, action) => {
            state.isLoading = false;
            state.hasError = true;
            state.errorMessage = action.payload;
            state.loginStatus = false;
        })
        .addCase(loadWishlist.pending, (state) => {
            state.isLoadingWishlist = true;
            state.hasErrorWithWishlist = false;
        })
        .addCase(loadWishlist.fulfilled, (state, action) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = false;
            state.wishlist = action.payload;
        })
        .addCase(loadWishlist.rejected, (state) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = true;
        })
        .addCase(loadWishlistSummary.pending, (state) => {
            state.isLoadingWishlist = true;
            state.hasErrorWithWishlist = false;
        })
        .addCase(loadWishlistSummary.fulfilled, (state, action) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = false;
            state.wishlistSummary = action.payload;
        })
        .addCase(loadWishlistSummary.rejected, (state) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = true;
        })
        .addCase(addNewAndLoadWishlist.pending, (state) => {
            state.isLoadingWishlist = true;
            state.hasErrorWithWishlist = false;
        })
        .addCase(addNewAndLoadWishlist.fulfilled, (state, action) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = false;
            state.wishlistSummary = action.payload;
        })
        .addCase(addNewAndLoadWishlist.rejected, (state) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = true;
            state.wishlistSummary = [];
        })
        .addCase(removeItemAndLoadWishlist.pending, (state) => {
            state.isLoadingWishlist = true;
            state.hasErrorWithWishlist = false;
        })
        .addCase(removeItemAndLoadWishlist.fulfilled, (state, action) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = false;
            state.wishlistSummary = action.payload;
        })
        .addCase(removeItemAndLoadWishlist.rejected, (state) => {
            state.isLoadingWishlist = false;
            state.hasErrorWithWishlist = true;
            state.wishlistSummary = [];
        })
        .addCase(updateProfileInfo.pending, (state) => {
            state.profile.isLoading = true;
            state.profile.hasError = false;
            state.profile.errorMessage = '';
            state.profile.notifySuccess = false;
        })
        .addCase(updateProfileInfo.fulfilled, (state, action) => {

            const { userInfoAspect, userInfoValue } = action.payload;
            
            state.profile.isLoading = false;
            state.profile.hasError = false;
            state.profile.notifySuccess = true;

            if (userInfoAspect!=="password") {
                state.session.user[userInfoAspect] = userInfoValue;
            } else {
                state.profile.showProfileModal = false;
            }
        })
        .addCase(updateProfileInfo.rejected, (state, action) => {
            state.profile.isLoading = false;
            state.profile.hasError = true;
            state.profile.errorMessage = action.payload;
        })
    }
});

// session
export const selectUser = state => state.session.session.user;
export const selectUserErrorMsg = state => state.session.errorMessage;
export const selectLoginStatus = state => state.session.loginStatus;
export const selectSessionIsLoading = state => state.session.isLoading;
export const selectSessionHasError = state => state.session.hasError;

// register
export const selectRegisterIsLoading = state => state.session.register.isLoading;
export const selectRegisterHasError = state => state.session.register.hasError;
export const selectRegisterErrorMsg = state => state.session.register.errorMessage;

// login
export const selectLoginIsLoading = state => state.session.login.isLoading;
export const selectLoginHasError = state => state.session.login.hasError;
export const selectLoginErrorMsg = state => state.session.login.errorMessage;

// google
export const selectGoogleIsLoading = state => state.session.googleLogin.isLoading;
export const selectGoogleHasError = state => state.session.googleLogin.hasError;
export const selectGoogleErrorMsg = state => state.session.googleLogin.errorMessage;

// wishlist
export const selectWishlist = state => state.session.wishlist;
export const selectIsLoadingWishlist = state => state.session.isLoadingWishlist;
export const selectHasErrorWithWishlist = state => state.session.hasErrorWithWishlist;
export const selectWishlistSummary = state => state.session.wishlistSummary;

// profile
export const selectProfileIsLoading = state => state.session.profile.isLoading;
export const selectProfileHasError = state => state.session.profile.hasError;
export const selectProfileErrorMessage = state => state.session.profile.errorMessage;
export const selectShowProfileModal = state => state.session.profile.showProfileModal;
export const selectProfileNotifySuccess = state => state.session.profile.notifySuccess;

// modal
export const selectShowLoginModal = state => state.session.showLoginModal;
export const selectShowRegisterModal = state => state.session.showRegisterModal;


export const {
    setShowLoginModal, 
    setShowRegisterModal,
    setRegisterMsgDefault,
    setShowProfileModal,
    setProfileMsgDefault,
    setProfileSuccessNotification,
} = sessionSlice.actions;

export default sessionSlice.reducer;