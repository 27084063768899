import React from "react";

const CartItemsBtnSet = ({
    toggleEditQuantity, Row, Col, Button, quantity, onRemoveItemFromCart, index, HiMinusSm, onAddItemToCart, product_id, HiPlusSm, handleSetToggleEditQuantity, FaCheck, FaXmark
}) => {

    return (
        <>
        {
            toggleEditQuantity ? 
            <>
                
                <Col xs={9} sm={8}
                    className="border border-secondary-subtle rounded-pill"
                >
                    <Row className="w-100 mx-auto">
                        <Col xs={4} className="text-center text-sm-start">
                            <Button variant="light" 
                                className="rounded-circle mx-auto my-auto h-100 p-2" 
                                disabled={quantity>1?false:true}
                                onClick={(e) => onRemoveItemFromCart(e, index)}
                            >
                                <HiMinusSm className="fs-6 text-light-emphasis my-auto mx-auto d-block"/>
                            </Button>
                        </Col>
                        <Col xs={4} className="text-center d-flex align-items-center justify-content-center">
                            <p className="fw-normal fs-6 my-auto">{quantity}</p>
                        </Col>
                        <Col xs={4} className="text-center text-sm-end">
                            <Button variant="light" 
                                className="rounded-circle mx-auto my-auto h-100 p-2"
                                onClick={(e) => onAddItemToCart(e, product_id)} 
                            >
                                <HiPlusSm className="fs-6 text-light-emphasis my-auto mx-auto d-block"/>
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={3} sm={4} className="mx-auto">
                    <Button onClick={(handleSetToggleEditQuantity)}
                        variant="success"
                        className="rounded-pill fw-semibold cart-edit-btn mx-auto fs-6 px-2 py-1 d-block"
                    >
                        <FaCheck className="fs-6"/>
                    </Button>
                </Col>

            </>
            :
            <>
                
                <Col xs={9} sm={8}
                    className="px-0 mx-auto d-flex align-items-center justify-content-center h-100"
                >
                    <p className="fs-6 lg-screen-fs-1point2rem m-0 w-100 text-center text-sm-start fw-medium">
                        <FaXmark className="m-0 fs-6 my-auto"/> {quantity} {quantity>1?"bottles":"bottle"}
                    </p>
                </Col>
                <Col xs={3} sm={4} className="mx-auto">
                    <Button onClick={(handleSetToggleEditQuantity)}
                        variant="outline-success"
                        className="rounded-pill fw-semibold cart-edit-btn mx-auto fs-6 px-2 py-1 d-block"
                    >
                        Edit
                    </Button>
                </Col>

            </>
        }
        </>
    );

}

export default CartItemsBtnSet;