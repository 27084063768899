import React from "react";
import { Badge, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { selectShowProfileModal, setShowProfileModal } from "../../features/sessionSlice";
import PwdAlphaNumericValidation from "../ValidationUnity/PwdAlphaNumericValidation";
import PwdUppercaseValidation from "../ValidationUnity/PwdUppercaseValidation";
import PwdLengthValidation from "../ValidationUnity/PwdLengthValidation";
import { isAlphanumeric } from "validator";

const EditionPassword = ({ 
    useSelector, useEffect, useState, dispatch, updateProfileInfo, profileIsLoading, profileHasError, profileErrorMessage, setProfileMsgDefault, profileNotifySuccess, setProfileSuccessNotification, ListGroup, Button, Form, Spinner, FaCheckCircle 
}) => {

    const showProfileModal = useSelector(selectShowProfileModal);

    const [btnDisabled, setBtnDisabled] = useState(false)
    const [newValue, setNewValue] = useState('');
    const [reentering, setReentering] = useState('');
    const [hide1st, setHide1st] = useState(true);
    const [hide2nd, setHide2nd] = useState(true);
    const [pwdConfirmed, setPwdConfirmed] = useState(true);

    // validation
    const [checkAlphaNumeric, setCheckAlphaNumeric] = useState(false);
    const [checkLength, setCheckLength] = useState(false);
    const [checkUppercase, setCheckUppercase] = useState(false);

    useEffect(() => {

        if(newValue===reentering) {
            setPwdConfirmed(true);
        } else {
            setPwdConfirmed(false);
        }

    }, [newValue, reentering]);

    useEffect(() => {

        if(!newValue || !reentering || !pwdConfirmed) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }

    }, [newValue, reentering, pwdConfirmed]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setHide1st(true);
        setHide2nd(true);
        dispatch(updateProfileInfo({aspect: 'password', newValue}));
        setNewValue('');
        setReentering('');
    };

    const handleModalShow = () => {
        dispatch(setProfileMsgDefault());
        dispatch(setProfileSuccessNotification(false))
    };

    const handleModalHide = () => {
        dispatch(setShowProfileModal(false));
    };

    const defaultPwdEditionModal = () => {
        dispatch(setShowProfileModal(false));
        setNewValue('');
        setReentering('');
        setHide1st(true);
        setHide2nd(true);
        setCheckAlphaNumeric(false);
        setCheckLength(false);
        setCheckUppercase(false);
        setPwdConfirmed(true);
    }

    const handlePwdChange = (e) => {
        const { value } = e.currentTarget;
        setNewValue(value);
        setCheckAlphaNumeric(value && isAlphanumeric(value));
        setCheckUppercase(value && value.match(/[A-Z]/));
        setCheckLength(value && value.length >= 6);
    };

    const renderButton = () => {

        if(!profileNotifySuccess) {
            return (
                profileIsLoading ? 
                <Button variant="success" size="sm" type="submit" 
                    className="rounded-pill w-50 py-2 mx-auto fst-italic d-flex align-items-center justify-content-center"
                    disabled={true}
                >
                    <Spinner 
                        variant="light"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2 fs-6 my-0"
                    />
                    Loading
                </Button>
                :
                <Button variant="success" size="sm" type="submit" 
                    className="rounded-pill w-50 py-2"
                    disabled={btnDisabled ? true : false}
                >
                    Submit
                </Button>
            );
        } else {
            return (
                <Button variant="success" size="sm" type="submit" 
                    className="rounded-pill w-50 py-2 mx-auto fst-italic d-flex align-items-center justify-content-center"
                    disabled={true}
                >
                    <FaCheckCircle className="fs-5"/> 
                </Button>
            );

        }
    };

    const pwdPopover = (
        <Popover>
            <Popover.Body className="px-1">
                <ListGroup variant="flush">
                    <PwdAlphaNumericValidation check={checkAlphaNumeric}/>
                    <PwdUppercaseValidation check={checkUppercase}/>
                    <PwdLengthValidation check={checkLength}/>
                </ListGroup>
            </Popover.Body>
        </Popover>
    ); 


    return (
        <>
        <ListGroup.Item className="d-flex justify-content-between align-items-center">
            <div>
                <p className="my-2 fw-light fs-6">Password</p>
            </div>
            <div>
                <Button variant="outline-dark" className="rounded-pill"
                    onClick={() => dispatch(setShowProfileModal(true))}
                >
                    Edit
                </Button>
            </div>
        </ListGroup.Item>
        <Modal centered
            show={showProfileModal} 
            onShow={() => handleModalShow()}
            onHide={() => handleModalHide()} 
            onExited={() => defaultPwdEditionModal()}
        >
            <Modal.Body className="bg-body-subtle">
                <Form onSubmit={handleSubmit}>

                    <OverlayTrigger 
                        trigger="focus"
                        placement="top"
                        overlay={pwdPopover}
                    >
                        <Form.Floating className="my-2">
                            <div className="position-absolute top-50 end-0 translate-middle-y px-3 fs-5 text-secondary">
                                { hide1st ? <FaEyeSlash className="clickable-icon" onClick={() => setHide1st(!hide1st)}/> : <FaEye className="clickable-icon" onClick={() => setHide1st(!hide1st)}/> }
                            </div>
                            <Form.Control
                                id="profile-edit-new-pwd"
                                type={hide1st ? "password" : "text"}
                                placeholder="Enter New Password"
                                onChange={handlePwdChange}
                                onFocus={() => dispatch(setProfileMsgDefault())}
                                value={newValue}
                                autoComplete="new-one"
                            />
                            <label htmlFor="profile-edit-new-pwd" className="fw-light">New Password</label>
                        </Form.Floating>
                    </OverlayTrigger>

                    <Form.Floating className="my-2">
                        <div className="position-absolute top-50 end-0 translate-middle-y px-3 fs-5 text-secondary">
                            <Badge pill bg="danger" className={`xxs-fs me-1 ${pwdConfirmed?"d-none":""}`}>Re-entering Not Same</Badge>
                            { hide2nd ? <FaEyeSlash className="clickable-icon" onClick={() => setHide2nd(!hide2nd)}/> : <FaEye className="clickable-icon" onClick={() => setHide2nd(!hide2nd)}/> }
                        </div>
                        <Form.Control
                            id="profile-edit-reentering"
                            type={hide2nd ? "password" : "text"}
                            placeholder="Confirm New Password"
                            onChange={(e) => setReentering(e.currentTarget.value)}
                            onFocus={() => dispatch(setProfileMsgDefault())}
                            value={reentering}
                            autoComplete="reenter-one"
                        />
                        <label htmlFor="profile-edit-reentering" className="fw-light">Re-Entering</label>
                    </Form.Floating>

                    <p className="text-danger my-0 fst-italic">{profileErrorMessage}</p>

                    <div className="text-center mt-4">
                        {renderButton()}
                    </div>
                </Form>
            </Modal.Body>

        </Modal>
        </>
    );
};

export default EditionPassword;