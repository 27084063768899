import React from "react";

const HeaderGrapesNavDropdown = ({ NavLink, modifyConditionToSingleGRC, dispatch }) => {
    
    const grapesInNavObj = {
        Red: ["Cabernet Sauvignon", "Malbec", "Grenache", "Merlot", "Pinot Noir", "Nebbiolo", "Shiraz/Syrah", "Tempranillo"],
        White: ["Sauvignon Blanc", "Chardonnay", "Riesling", "Semillon"],
    };

    const handleClickGrapesNav = (grape) => {
        dispatch(modifyConditionToSingleGRC({targetEle: grape, category: "grapes"}));
    };
    
    return (
        <>
        {
            Object.values(grapesInNavObj).flat() && Object.values(grapesInNavObj).flat().sort().map((grape, index) => (
                <NavLink key={index}
                    className="nav-dropdown-element-normal responsive-nav-element my-1 my-lg-0"
                    onClick = {() => handleClickGrapesNav(grape.toLowerCase())}
                    to="/product"
                >
                    {grape}
                </NavLink>
            ))
        }
        </>
    );
}

export default HeaderGrapesNavDropdown;