import React from "react";
import { Card, Placeholder } from "react-bootstrap";

const WineInfoloading = () => {

    return (
        <Card.Body className="mt-4">
            <Placeholder as={Card.Title} animation="glow"> 
                <Placeholder xs={6} className="my-2" bg="secondary"/>
                <Placeholder xs={12} className="my-2" bg="secondary"/>
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow" className="my-2"> 
                <Placeholder xs={1} bg="secondary"/> <Placeholder xs={6} bg="secondary"/>
            </Placeholder>
        </Card.Body>
    );

}

export default WineInfoloading;