import React from "react";


const FormName = ({ Form, firstName, setFirstName, lastName, setLastName, handleNameKeyUp }) => {

    return (
        <>
            <Form.Floating className="my-4">
                <Form.Control 
                    className="input-field border-dark-subtle" 
                    id="register-first-name"
                    type="text" 
                    name="firstName"
                    placeholder="First name"
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                    onKeyUp={handleNameKeyUp}
                    value={firstName}
                    required
                />
                <label htmlFor="register-first-name" className="input-label text-black-50">First Name</label>
            </Form.Floating>
            <Form.Floating className="my-4">
                <Form.Control 
                    className="input-field border-dark-subtle" 
                    id="register-last-name"
                    type="text" 
                    name="lastName"
                    placeholder="Last name"
                    onChange={(e) => setLastName(e.currentTarget.value)}
                    onKeyUp={handleNameKeyUp}
                    value={lastName}
                    required
                />
                <label htmlFor="register-last-name" className="input-label text-black-50">Last Name</label>
            </Form.Floating>
        </>
    );

}

export default FormName;