import React from "react";
import { ListGroup } from "react-bootstrap";
import { PiWineFill, PiWine, PiWineDuotone } from "react-icons/pi";


const InitialResultDropdown = ({ NavLink, handleClickTypeResult }) => {

    return (
        <>
            <ListGroup variant="flush">
                <ListGroup.Item className="text-uppercase fw-medium fs-result-title border-0">shop wine by type</ListGroup.Item>
                <NavLink to="/product" className="text-decoration-none"><ListGroup.Item action className="text-capitalize fw-light border-0 d-flex align-items-center text-body-secondary" onClick={() => handleClickTypeResult("red")}><PiWineFill className="fs-5 me-2 text-dark"/>Red</ListGroup.Item></NavLink>
                <NavLink to="/product" className="text-decoration-none"><ListGroup.Item action className="text-capitalize fw-light border-0 d-flex align-items-center text-body-secondary" onClick={() => handleClickTypeResult("white")}><PiWine className="fs-5 me-2 text-dark"/>White</ListGroup.Item></NavLink>
                <NavLink to="/product" className="text-decoration-none"><ListGroup.Item action className="text-capitalize fw-light border-0 d-flex align-items-center text-body-secondary" onClick={() => handleClickTypeResult("rose")}><PiWineDuotone className="fs-5 me-2 text-dark"/>Rose</ListGroup.Item></NavLink>
            </ListGroup>
        </>
    );

}

export default InitialResultDropdown;