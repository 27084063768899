import React from "react";
import { Card, Button } from "react-bootstrap";
import WineStarRating from "./WineStarRating";

const WineStarRatingBtn = ({ rating, price, product_id, handleClickPrice, prefixName }) => {

    return (
        <Card.Body className="p-0 text-center">
            <WineStarRating rating={rating} prefixName={prefixName}/>

            <Button variant="success" 
                className="rounded-pill w-75"
                onClick={(e) => handleClickPrice(e, product_id)}
            >
                £{price.toFixed(2)}
            </Button>
        </Card.Body>
    );

}

export default WineStarRatingBtn;