import React, { useEffect, useRef, useState } from "react";
import HeaderGrapesNavDropdown from "./HeaderGrapesNavDropdown";

const HeaderGrapesNav = ({ 
    dispatch, NavLink, GiGrapes, showProductDropdown, hideProductDropdown, modifyConditionToSingleGRC, 
}) => {
    
    const [showDropdown, setShowDropDown] = useState(false);
    const dropdownTimeoutRef = useRef();

    useEffect(() => {
        if (dropdownTimeoutRef.current) {
            clearTimeout(dropdownTimeoutRef.current);
        }
    }, []);

    return (
        <div className="hover-cursor d-flex align-items-center text-decoration-none text-black"
            onMouseEnter = {() => showProductDropdown(dropdownTimeoutRef, setShowDropDown)}
            onMouseLeave = {() => hideProductDropdown(dropdownTimeoutRef, setShowDropDown)}
        >
            <GiGrapes className="products-navigation-icons"/> 
            <h6 className="products-navigation-text">Grapes</h6>
            {
                showDropdown && 
                <div className="header-grape-dropdown p-4">
                    <HeaderGrapesNavDropdown 
                        NavLink={NavLink} 
                        dispatch={dispatch}
                        modifyConditionToSingleGRC={modifyConditionToSingleGRC}
                    />
                </div>
            }
        </div>
    )
}

export default HeaderGrapesNav;