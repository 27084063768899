import React from "react";

const AromaCube = ({ aromaElement, Col }) => {
    const aromaName = aromaElement.trim();

    return (
        <Col className="aroma-cube text-center my-2">
            <img className="aroma-img w-50"
                src={`/aromaImg/${aromaName}.png`} 
                alt={`icon for ${aromaName} aroma`} 
            />
            <h6 className="text-center fw-light fs-6 m-0">{aromaElement}</h6>
        </Col>
    );
}

export default AromaCube;