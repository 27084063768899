import React, { useCallback, useEffect, useRef } from "react";
import FilterType from "./FilterType";
import FilterSlider from "./FilterSlider";
import FilterRating from "./FilterRating";
import FilterGrape from "./FilterGrape";
import FilterCountry from "./FilterCountry";
import FilterRegion from "./FilterRegion";
import { loadProductList } from "../../features/productSlice";

const FilterList = ({ filter, dispatch, condition, prefixName, Row, Col }) => {

    const delayedActionRef = useRef();

    const debouncedAction = useCallback(() => {

        if (delayedActionRef.current) {
            clearTimeout(delayedActionRef.current);
        }

        delayedActionRef.current = setTimeout(() => {
            dispatch(loadProductList());
        }, 500);

    }, [condition, dispatch]);

    useEffect(() => {

        debouncedAction();

        return () => {
            if (delayedActionRef.current) {
                clearTimeout(delayedActionRef.current);
            }
        };

    }, [condition, debouncedAction]);

    return (
        <>
            {filter.type && <FilterType type={filter.type} Row={Row} Col={Col}/>}
            <FilterSlider />
            <FilterRating prefixName={prefixName}/>
            {filter.grapes && <FilterGrape grapes={filter.grapes}/>}
            {filter.region && <FilterRegion region={filter.region}/>}
            {filter.country && <FilterCountry country={filter.country} countryCode={filter.countryCode}/>}
        </>
    )
}

export default FilterList;