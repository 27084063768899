import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import WineImgLoading from "./WineImgLoading";
import WineInfoloading from "./WineInfoloading";
import WineStarRatingBtnLoading from "./WineStarRatingBtnLoading";

const ProductLoading = ({ fixedDoubleDecker }) => {

    return (
        <Col className="p-1 mb-5">
            <Card className="product-container px-0 mx-auto ms-lg-auto bg-white">
                <Row>
                    <Col xs={6} lg={fixedDoubleDecker?'':3} 
                        className="d-flex align-items-center justify-content-center"
                    >
                        <WineImgLoading />
                    </Col>
                    {
                        fixedDoubleDecker ? 
                        ''
                        :
                        <Col lg={6} className="d-none d-lg-block">
                            <WineInfoloading />
                        </Col>
                    }
                    <Col xs={6} lg={fixedDoubleDecker?'':3} className="d-grid align-items-center">
                        <WineStarRatingBtnLoading />
                    </Col>
                </Row>
                <Row className={fixedDoubleDecker?"":"d-block d-lg-none"}>
                    <Col xs={12}>
                        <WineInfoloading />
                    </Col>
                </Row>
            </Card>
        </Col>
    );

}

export default ProductLoading;