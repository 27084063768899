import React from "react";
import WineImg from "./WineImg";
import WineInfo from "./WineInfo";
import WineStarRatingBtn from "./WineStarRatingBtn";

const WineCard = ({
    Row, Col, hovered, picture, winery, grapes, vintage, country_code, region, country, rating, price, product_id, handleClickPrice, starPrefixName, fixedDoubleDecker=false
}) => {

    return (
        <>
            <Row>
                <Col xs={6} lg={fixedDoubleDecker?'':3} 
                    className="mx-0 ms-auto text-center text-lg-center"
                >
                    <WineImg 
                        hovered={hovered}
                        picture={picture}
                    />
                </Col>
                {
                    fixedDoubleDecker ? 
                    ''
                    :
                    <Col lg={6} className="mx-auto d-none d-lg-flex text-capitalize">
                        <WineInfo 
                            winery={winery}
                            grapes={grapes}
                            vintage={vintage}
                            country_code={country_code}
                            region={region}
                            country={country}
                            fixedDoubleDecker={false}
                        />
                    </Col>
                }

                <Col xs={6} lg={fixedDoubleDecker?'':3} className="mx-auto my-auto p-0">
                    <WineStarRatingBtn 
                        rating={rating}
                        price={price}
                        product_id={product_id}
                        handleClickPrice={handleClickPrice}
                        prefixName={starPrefixName}
                    />
                </Col>
            </Row>
            <Row className={`w-100 mx-auto text-capitalize fs-6 ${fixedDoubleDecker?'':'d-lg-none'}`}>
                <WineInfo 
                    winery={winery}
                    grapes={grapes}
                    vintage={vintage}
                    country_code={country_code}
                    region={region}
                    country={country}
                    fixedDoubleDecker={true}
                />
            </Row>
        </>
    );

}

export default WineCard;