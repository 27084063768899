import React, { useEffect, useState } from "react";
import HeaderForIntermedia from "../Header/HeaderForIntermedia";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PaymentIntermediaSuccess = () => {

    const navigate = useNavigate();

    const [countdown, setCountdown] = useState(4);

    useEffect(() => {

        const countdownToRedirect = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);
        return () => clearInterval(countdownToRedirect);

    }, []);

    const handleResultRedirection = () => {
        setTimeout(() => {
            return navigate('/orders');
        }, 4000);
    };

    return (
        <>
            <HeaderForIntermedia />
            <div id="payment-intermedia-body">
                <Container className="py-5 px-3 px-sm-5 h-100 w-100">
                    <Row className="d-block mx-auto my-5 text-center fs-5">
                        <p className="intermedia-top-text-fs fs-5 fw-semibold text-danger-emphasis mb-4 text-capitalize">
                            Your order has been placed
                        </p>
                        <p className="lg-screen-fs-1point3rem fst-italic text-success-emphasis">
                            Redirecting in <span id="redirection-countdown">{countdown}</span> seconds...
                        </p>
                        { handleResultRedirection() }
                    </Row>
                </Container>
            </div>
        </>
    );

}

export default PaymentIntermediaSuccess;