import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { 
    loadProductDetail, 
    selectHasErrorWithDetail, 
    selectIsLoadingDetail, 
    selectProductDetail, 
} from "../../features/productSlice";
import { 
    addNewAndLoadWishlist, 
    removeItemAndLoadWishlist, 
    selectLoginStatus, 
    selectWishlistSummary, 
    setShowLoginModal
} from "../../features/sessionSlice";
import { handleAddItemToCart, setRequirePrompt } from "../../features/cartSlice";
import "../../stylesheets/ProductDetails.css";
import { CiBookmarkPlus, CiBookmarkMinus, CiDeliveryTruck } from "react-icons/ci";
import { HiPlusSm, HiMinusSm, HiOutlineInformationCircle } from 'react-icons/hi';
import { PiCastleTurretLight } from 'react-icons/pi';
import { GiGrapes, GiWineBottle } from 'react-icons/gi';
import { SlLocationPin } from "react-icons/sl";
import { RiWaterPercentLine } from 'react-icons/ri';
import { estimateArrival } from "../../utils/estimateArrival";
import TastingFeature from "../Card/TastingFeature";
import AromaCube from "../Card/AromaCube";
import Header from "../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import ProductDetailsWineInfo from "./ProductDetailsWineInfo";
import ProductDetailsRatingWishlist from "./ProductDetailsRatingWishlist";
import ProductDetailsFunctionBox from "./ProductDetailsFunctionBox";
import DotsPreloader from "../../loading/DotsPreloader";
import Footer from "../Footer/Footer";
import ErrorHandler from "../ErrorPage/ErrorHandler";


const ProductDetails = () => {
    const dispatch = useDispatch();
    const { productId } = useParams();
    const loginStatus = useSelector(selectLoginStatus);
    const product = useSelector(selectProductDetail);
    const isLoadingDetail = useSelector(selectIsLoadingDetail);
    const hasErrorWithDetail = useSelector(selectHasErrorWithDetail);
    const wishlistSummary = useSelector(selectWishlistSummary);
    const eta = estimateArrival(2);

    const [num, setNum] = useState(1);    
    const increment = () => {setNum(preNum => preNum + 1)};
    const decrement = () => {setNum(preNum => preNum===1 ? 1 : preNum - 1)};

    useEffect(() => {

        dispatch(loadProductDetail({productId}));

    }, []);

    const handleClickAddingToCartButton = () => {
        if (loginStatus) {

            dispatch(setRequirePrompt(true));

            dispatch(handleAddItemToCart({productId: product.product_id, num}));

        } else {
            dispatch(setShowLoginModal(true));
        }
    };

    const wishlistSection = () => {

        if (loginStatus) {
            if (wishlistSummary.includes(product.product_id)) {
                return (
                    <Row className="clickable-icon mt-sm-2 mt-lg-1 text-nowrap align-items-center"
                        onClick={() => dispatch(removeItemAndLoadWishlist({productId: product.product_id}))}
                    >
                        <Col xs={4} sm={3} md={1} className="px-0 text-end text-md-center"><CiBookmarkMinus className="fs-4 fs-md-3"/></Col>
                        <Col xs={8} sm={9} md={11} className="fs-md-5 my-0 px-0 underline-hovered">
                            Remove Item
                        </Col>
                    </Row>
                );
            } else {
                return (
                    <Row className="clickable-icon mt-sm-2 mt-lg-1 text-nowrap align-items-center"
                        onClick={() => dispatch(addNewAndLoadWishlist({productId: product.product_id}))}
                    >
                        <Col xs={4} sm={3} md={1} className="px-0 text-end text-md-center"><CiBookmarkPlus className="fs-4 fs-md-3"/></Col>
                        <Col xs={8} sm={9} md={11} className="fs-md-5 my-0 px-0 underline-hovered">
                            Add to Wishlist
                        </Col>
                    </Row>
                );
            }

        } else {
            return (
                <Row className="clickable-icon mt-sm-2 mt-lg-1 text-nowrap align-items-center"
                    onClick={() => dispatch(setShowLoginModal(true))}
                >
                    <Col xs={4} sm={3} md={1} className="px-0 text-end text-md-center"><CiBookmarkPlus className="fs-4 fs-md-3"/></Col>
                    <Col xs={8} sm={9} md={11} className="fs-md-5 my-0 px-0 underline-hovered">
                        Add to Wishlist
                    </Col>
                </Row>
            );
        }

    };

    if (isLoadingDetail && !hasErrorWithDetail) {
        return (
            <>
                <Header />
                <div className="vh-100">
                    <DotsPreloader />
                </div>
                <Footer />
            </>
        )
    };

    if(!isLoadingDetail && hasErrorWithDetail) {
        return (
            <ErrorHandler />
        );
    }

    if (product && !isLoadingDetail && !hasErrorWithDetail) {
        return (
            <>
                <Header />
                <div className="d-flex flex-column position-relative z-0 pb-5">
                    <div id="product-details-1st-container">
                        <Container className="p-3 p-lg-4 mx-xxl-5 px-xxl-5 py-xxl-5">
                            <Row className="w-100 mx-auto">
                                <Col xs={12} sm={9} md={12} lg={8} className="mx-auto">
                                    <Row className="p-3 p-md-0 w-100">
                                        <Col xs={5} md={2} lg={2} className="d-flex align-items-center mx-auto px-lg-0">
                                            <img 
                                                src={`/wineImg/${product.picture}.png`}
                                                alt={`${product.picture} sample picture`} 
                                                className="w-100 ms-auto"
                                            />
                                        </Col>

                                        <Col xs={7} sm={7} md={8} lg={9}
                                            className="mx-0 px-0 d-flex flex-column align-item-center justify-content-center justify-content-sm-start"
                                        >
                                            <div className="d-none d-md-flex flex-column">
                                                <ProductDetailsWineInfo 
                                                    winery={product.winery}
                                                    grapes={product.grapes}
                                                    vintage={product.vintage}
                                                    country={product.country}
                                                    region={product.region}
                                                    type={product.type}
                                                />
                                            </div>

                                        <Row className="mx-auto my-auto mx-md-0 me-md-auto w-100">
                                            <Col xs={12} className="align-item-center">
                                                <ProductDetailsRatingWishlist 
                                                    rating={product.rating}
                                                    wishlistSection={wishlistSection}
                                                />
                                            </Col>
                                        </Row>

                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12} className="mt-2 d-md-none">
                                    <ProductDetailsWineInfo 
                                        winery={product.winery}
                                        grapes={product.grapes}
                                        vintage={product.vintage}
                                        country={product.country}
                                        region={product.region}
                                        type={product.type}
                                    />
                                </Col>

                                <Col xs={12} lg={4} className="mt-5 mt-lg-0">

                                    <ProductDetailsFunctionBox 
                                        price={product.price}
                                        HiMinusSm={HiMinusSm}
                                        decrement={decrement}
                                        num={num}
                                        increment={increment}
                                        HiPlusSm={HiPlusSm}
                                        handleClickAddingToCartButton={handleClickAddingToCartButton}
                                        CiDeliveryTruck={CiDeliveryTruck}
                                        eta={eta}
                                        Container={Container}
                                        Row={Row}
                                        Col={Col}
                                    />
                                
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="my-4">
                        <Container id="details-tasting-container" className="mx-xxl-5 p-xxl-5">
                            <h2 className="mb-3 text-uppercase">TASTING OF THE WINE</h2>

                            <div className="d-flex flex-column">

                                <Row className="my-2">
                                    <Col xs={12} sm={2} lg={2} className="fs-5 fw-semibold text-center">Body</Col>
                                    <Col xs={12} sm={9} lg={8} className="mx-auto">
                                        <Row className="h-100 d-flex align-items-center justify-content-between">
                                            <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">light</Col>
                                            <Col xs={8} md={10}>
                                                <TastingFeature taste={product.body}/>
                                            </Col>
                                            <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">full</Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="my-2">
                                    <Col xs={12} sm={2} lg={2} className="fs-5 fw-semibold text-center">Sweetness</Col>
                                    <Col xs={12} sm={9} lg={8} className="mx-auto">
                                        <Row className="h-100 d-flex align-items-center justify-content-between">
                                            <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">Dry</Col>
                                            <Col xs={8} md={10}>
                                                <TastingFeature taste={product.sweetness}/>
                                            </Col>
                                            <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">Luscious</Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {
                                    product.type==='Red' || 'red' || 'RED'
                                    ? 
                                    <Row className="my-2">
                                        <Col xs={12} sm={2} lg={2} className="fs-5 fw-semibold text-center">Tannin</Col>
                                        <Col xs={12} sm={9} lg={8} className="mx-auto">
                                            <Row className="h-100 d-flex align-items-center justify-content-between">
                                                <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">Low</Col>
                                                <Col xs={8} md={10}>
                                                    <TastingFeature taste={product.tannin}/>
                                                </Col>
                                                <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">High</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    :
                                    ''
                                }

                                <Row className="my-2">
                                    <Col xs={12} sm={2} lg={2} className="fs-5 fw-semibold text-center">Acidity</Col>
                                    <Col xs={12} sm={9} lg={8} className="mx-auto">
                                        <Row className="h-100 d-flex align-items-center justify-content-between">
                                            <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">Low</Col>
                                            <Col xs={8} md={10}>
                                                <TastingFeature taste={product.acidity}/>
                                            </Col>
                                            <Col xs={2} md={1} className="fs-6 m-0 text-capitalize fw-light text-nowrap text-center">High</Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="my-3">
                                    <Col xs={12} sm={2} lg={2} className="fs-5 fw-semibold text-center">Aroma</Col>
                                    <Col xs={12} sm={9} lg={8} className="mx-sm-auto">
                                        <Row xs={3} sm={4} md={5} lg={6} className="d-flex flex-wrap">
                                            {
                                                product.aroma && product.aroma.map((aromaElement, index) => (
                                                    <AromaCube 
                                                        aromaElement={aromaElement}
                                                        Col={Col}
                                                        key={index}
                                                    />
                                                ))
                                            }
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        </Container>
                    </div>

                    <div className="w-100 mb-5">
                        <Container className="mx-xxl-5 p-xxl-5">
                            <h2 className="mb-3 text-uppercase">FACTS ABOUT THE WINE</h2>

                                <Row className="facts-info-row py-2 px-1 border-bottom border-secondary-subtle">
                                    <Col xs={5} lg={3} className="d-flex align-items-center px-0 mx-auto">
                                        <p className="m-0 text-capitalize fs-6"><PiCastleTurretLight className="text-black-50"/> Winery</p>
                                    </Col>
                                    <Col xs={7} lg={9} className="fs-6 text-capitalize px-0 mx-auto">{product.winery}</Col>
                                </Row>

                                <Row className="facts-info-row py-2 px-1 border-bottom border-secondary-subtle">
                                    <Col xs={5} lg={3} className="d-flex align-items-center px-0 mx-auto">
                                        <p className="m-0 text-capitalize fs-6"><GiGrapes className="text-black-50"/> Grapes</p>
                                    </Col>
                                    <Col xs={7} lg={9} className="fs-6 text-capitalize px-0 mx-auto">{product.grapes.join(', ')}</Col>
                                </Row>

                                <Row className="facts-info-row py-2 px-1 border-bottom border-secondary-subtle">
                                    <Col xs={5} lg={3} className="d-flex align-items-center px-0 mx-auto">
                                        <p className="m-0 text-capitalize fs-6"><SlLocationPin className="fs-5 text-black-50"/> Region</p>
                                    </Col>
                                    <Col xs={7} lg={9} className="fs-6 text-capitalize px-0 mx-auto">{product.country}, {product.region}</Col>
                                </Row>

                                <Row className="facts-info-row py-2 px-1 border-bottom border-secondary-subtle">
                                    <Col xs={5} lg={3} className="d-flex align-items-center px-0 mx-auto">
                                        <p className="m-0 text-capitalize fs-6"><GiWineBottle className="fs-5 text-black-50"/> Wine Style</p>
                                    </Col>
                                    <Col xs={7} lg={9} className="fs-6 text-capitalize px-0 mx-auto">{product.type} wine</Col>
                                </Row>

                                <Row className="facts-info-row py-2 px-1 border-bottom border-secondary-subtle">
                                    <Col xs={5} lg={3} className="d-flex align-items-center px-0 mx-auto">
                                        <p className="m-0 text-capitalize fs-6"><HiOutlineInformationCircle className="fs-5 text-black-50"/> Allergens</p>
                                    </Col>
                                    <Col xs={7} lg={9} className="fs-6 text-capitalize px-0 mx-auto">Contains Sulfites</Col>
                                </Row>

                                <Row className="facts-info-row py-2 px-1 border-bottom border-secondary-subtle">
                                    <Col xs={5} lg={3} className="d-flex align-items-center px-0 mx-auto">
                                        <p className="m-0 text-capitalize fs-6"><RiWaterPercentLine className="fs-5 text-black-50"/> Alcohol by Volumn</p>
                                    </Col>
                                    <Col xs={7} lg={9} className="fs-6 text-capitalize px-0 mx-auto">{product.alcohol}%</Col>
                                </Row>

                        </Container>
                    </div>      
                </div>
                <Footer />
            </>
        )
    }
};


export default ProductDetails;