export const invalidationBorder = (domEle) => {
    domEle.classList.remove("border-dark-subtle", "border-success-subtle", "border-3");
    domEle.classList.add("border-danger");
};

export const validationBorder = (domEle) => {
    domEle.classList.remove("border-dark-subtle", "border-danger");
    domEle.classList.add("border-success-subtle", "border-3");
};

export const defaultBorder = (domEle) => {
    domEle.classList.remove("border-danger", "border-success-subtle", "border-3");
    domEle.classList.add("border-dark-subtle");
};