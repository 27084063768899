import React from "react";
import { PiShoppingCart } from "react-icons/pi";
import { handleAddItemToCart } from "../../features/cartSlice";


const WishList = ({
    Row, Col, Card, Button, Link, navigate, dispatch, wishlistArr, isLoadingWishlist, hasErrorWithWishlist, Spinner
}) => {

    const handleClickCartBtn = (productId) => {
        dispatch(handleAddItemToCart({productId, num: 1}));
        navigate("/cart");
    };

    if(isLoadingWishlist && !hasErrorWithWishlist) {
        return (
            <div className="w-100 my-auto d-flex justify-content-center">
                <Spinner animation="grow" className="mx-2" variant="danger" size="sm"/>
            </div>
        );
    };

    if(!isLoadingWishlist && hasErrorWithWishlist) {
        return (
            <div className="w-100 my-auto d-flex justify-content-center">
                Error occurred. Refresh the page to try again.
            </div>
        );
    };

    return (
        <>
            {
                wishlistArr && wishlistArr.length > 0 && wishlistArr.map((itemObj, index) => (
                    <Card key={index} className="mb-3 py-1 px-3 rounded-3">
                        <Row>
                            <Col xs={3} 
                                className="d-flex jutify-content-center align-items-center"
                            >
                                <Link to={`/product/${itemObj.product_id}`}
                                    className="text-decoration-none mx-auto"
                                >
                                    <Card.Img 
                                        src={`/wineImg/${itemObj.picture}.png`}
                                        alt={`${itemObj.picture} picture`}
                                        className="wishlist-img-resize w-50 d-block mx-auto my-auto"
                                    />
                                </Link>
                            </Col>
                            <Col xs={9} className="mx-auto">
                                <Card.Body className="px-1">
                                    <Row>
                                        <p className="text-black my-1 text-capitalize">
                                            {itemObj.winery}
                                        </p>
                                        <p className="text-black fw-semibold my-1 text-capitalize">
                                            {itemObj.grapes.length>2?'Blend':itemObj.grapes.join(', ')} {itemObj.vintage}
                                        </p>
                                        <p className="my-1 text-black d-flex align-items-center text-capitalize">
                                            {itemObj.region}, {itemObj.country}
                                        </p> 
                                    </Row>
                                    
                                    <hr className="my-1"></hr>
                                    
                                    <Row>
                                        <Col xs={7} sm={8}>
                                            <Row>
                                                <Col sm={6} className="d-flex align-items-center my-1">
                                                    <p className="d-block my-auto fw-medium fs-6">
                                                        <span className="fw-medium">£{itemObj.price}</span><span>/bottle</span>
                                                    </p>
                                                </Col>
                                                <Col sm={6} className="d-flex align-items-center justify-content-sm-center my-1">
                                                    <p className="d-block my-auto fw-medium fs-6">
                                                        <span className="fw-normal me-1">{Number(itemObj.rating).toFixed(1)}</span><span>/ 5</span>
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={5} sm={4} className="d-flex align-items-center justify-content-center my-1">
                                            <Button variant="success" 
                                                className="w-75 h-100 d-flex align-items-center justify-content-between rounded"
                                                onClick={() => handleClickCartBtn(itemObj.product_id)}
                                            >
                                                <PiShoppingCart className="d-block my-auto mx-auto"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                ))
            }

            {
                wishlistArr && wishlistArr.length===0 && 
                <div className="p-5 text-center">
                    No item in wishlist
                </div>
            }
        </>
    )
};

export default WishList;