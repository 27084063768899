import React, { useEffect, useRef, useState } from "react";
import HeaderRegionNavDropdown from "./HeaderRegionNavDropdown";

const HeaderRegionNav = ({
    dispatch, NavLink, SlLocationPin, showProductDropdown, hideProductDropdown, modifyConditionToSingleGRC, Row, Col
}) => {
    
    const [showDropdown, setShowDropDown] = useState(false);
    const dropdownTimeoutRef = useRef();

    useEffect(() => {
        if (dropdownTimeoutRef.current) {
            clearTimeout(dropdownTimeoutRef.current);
        }
    }, []);

    return (
        <div className="hover-cursor d-flex align-items-center text-decoration-none text-black"
            onMouseEnter = {() => showProductDropdown(dropdownTimeoutRef, setShowDropDown)}
            onMouseLeave = {() => hideProductDropdown(dropdownTimeoutRef, setShowDropDown)}
        >
            <SlLocationPin className="products-navigation-icons"/> 
            <h6 className="products-navigation-text">Regions</h6>
            {
                showDropdown && 
                <div className="header-region-dropdown p-4">
                    <HeaderRegionNavDropdown 
                        NavLink={NavLink} 
                        dispatch={dispatch}
                        modifyConditionToSingleGRC={modifyConditionToSingleGRC}
                        Row={Row}
                        Col={Col}
                    />
                </div>
            }
        </div>
    )
}

export default HeaderRegionNav;