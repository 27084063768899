import React, { useEffect, useState } from "react";
import Order from "./Order";
import { useDispatch, useSelector } from "react-redux";
import { 
    loadOrdersList, 
    selectOrdersHasError, 
    selectOrdersIsLoading, 
    selectOrdersList 
} from "../../features/orderSlice";
import { 
    selectLoginStatus,
    selectSessionHasError,
    selectSessionIsLoading, 
} from "../../features/sessionSlice";
import { Link, useNavigate } from "react-router-dom";
import "../../stylesheets/Orders.css";
import Header from "../Header/Header";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../Footer/Footer";
import DotsPreloader from "../../loading/DotsPreloader";
import ErrorHandler from "../ErrorPage/ErrorHandler";
import EmptyHandler from "../EmptyPage/EmptyHandler";


const Orders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sessionIsLoading = useSelector(selectSessionIsLoading);
    const sessionHasError = useSelector(selectSessionHasError);
    const loginStatus = useSelector(selectLoginStatus);
    const ordersArr = useSelector(selectOrdersList); 
    const ordersIsLoading = useSelector(selectOrdersIsLoading);
    const ordersHasError = useSelector(selectOrdersHasError);

    const [countdown, setCountdown] = useState(3);
    useEffect(() => {
        
        if(!sessionIsLoading && sessionHasError && !loginStatus) {
            
            const countdownInterval = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);

            return () => clearInterval(countdownInterval);
        
        }

    }, [sessionIsLoading, sessionHasError]);

    useEffect(() => {
        if (loginStatus && !sessionIsLoading && !sessionHasError) {

            dispatch(loadOrdersList());

        }
    }, [dispatch, sessionIsLoading, sessionHasError]);

    if(!loginStatus && !sessionIsLoading && sessionHasError) {
        return (
            <ErrorHandler />
        )
    };

    if (loginStatus && ordersIsLoading && !ordersHasError) {
        return (
            <>
                <Header />
                <div className="vh-100">
                    <DotsPreloader />
                </div>
            </>
        );
    };

    if (loginStatus && !ordersIsLoading && !ordersHasError && ordersArr.length===0) {
        return (
            <EmptyHandler title="No Order Placed Yet" />
        )
    };

    return (
        <>
            <Header />
            <div id="orders-body-container" className="pb-5">
                <Container className="px-2 px-sm-0 py-3 py-lg-5 vh-100">
                    <Row className="fs-4 fw-medium mb-4 mx-auto">
                        Your Orders: 
                    </Row>
                    <Row className="mb-2 mx-auto text-body-secondary fw-semibold d-none d-lg-flex">
                        <Col md={3} className="text-center px-0">Items</Col>
                        <Col md={2} className="text-center px-0">Ordered On</Col>
                        <Col md={2} className="text-center px-0">Delivery</Col>
                        <Col md={2} className="text-center px-0">Quantity</Col>
                        <Col md={2} className="text-center px-0">Total</Col>
                    </Row>
                    <Container className="mb-5 mx-auto bg-light-subtle rounded shadow">
                        {
                            ordersArr && ordersArr.map((orderObj, index) => (
                                <Order
                                    key={index}
                                    orderObj={orderObj} 
                                    index={index}
                                    Link={Link}
                                    Row={Row}
                                    Col={Col}
                                />
                            ))
                        }
                    </Container>
                </Container>
            </div>
            <Footer marginTop={0}/>
        </>
    );
};


export default Orders;