import React, { useEffect } from "react";
import HeaderForIntermedia from "../Header/HeaderForIntermedia";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { getPaymentStatus } from "../../features/cartSlice";

const PaymentIntermediaCheckPayment = ({ dispatch, paymentStatus }) => {

    useEffect(() => {
        
        const intervalPolling = setInterval(() => {
            dispatch(getPaymentStatus());
        }, 4000);

        return () => clearInterval(intervalPolling); 

    }, [paymentStatus]);

    return (
        <>
            <HeaderForIntermedia />
            <div id="payment-intermedia-body">
                <Container className="py-5 px-3 px-sm-4 px-md-5 h-100 w-100">
                    <Row className="mx-auto my-5 fs-5">
                        <Col xs={3} sm={2} className="mx-0 ms-auto text-end">
                            <Spinner 
                                variant="success"
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                className="me-1 my-auto fs-6"
                            />
                        </Col>
                        <Col xs={9} lg={8} xxl={6} className="mx-0 me-auto">
                            <p className="fs-4 text-warning-emphasis fw-medium">
                                Please wait, we are confirming payment...
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );

}

export default PaymentIntermediaCheckPayment;