import React from "react";
import Rating from "../Rating/Rating";
import "../../stylesheets/WineRatingPrice.css";

const WineStarRating = ({ rating, prefixName }) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center mb-2">
            <h4 className="times-font-family text-black mb-1 fw-medium">
                {rating.toFixed(1)}
            </h4>
            <Rating rating={rating} prefixName={prefixName}/>
        </div>
    );
}

export default WineStarRating;
