import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { handleAddItemToCart } from "../../features/cartSlice";
import { useSelector } from "react-redux";
import { selectLoginStatus, setShowLoginModal } from "../../features/sessionSlice";
import { Card } from "react-bootstrap";
import WineCard from "../Card/WineCard";


const Product = ({ product, dispatch, Col, Row }) => {

    const loginStatus = useSelector(selectLoginStatus);
    const navigate = useNavigate();

    const [hovered, setHovered] = useState(false);

    const handleClickPrice = (event, productId) => {
        event.preventDefault();
        event.stopPropagation();

        if (loginStatus) {
            dispatch(handleAddItemToCart({productId, num: 1}));
            navigate("/cart");
        } else {
            dispatch(setShowLoginModal(true));
        }
        
    };

    return (
        <Col className="p-1 mb-5">
            <Card className="product-container">
                <Link 
                    to={`/product/${product.product_id}`}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    className="text-decoration-none d-flex flex-column"
                >
                    <WineCard 
                        Row={Row}
                        Col={Col}
                        hovered={hovered}
                        picture={product.picture}
                        winery={product.winery}
                        grapes={product.grapes}
                        vintage={product.vintage}
                        country_code={product.country_code}
                        region={product.region}
                        country={product.country}
                        rating={product.rating}
                        price={product.price}
                        product_id={product.product_id}
                        handleClickPrice={handleClickPrice}
                        starPrefixName={"products-product-list"}
                    />
                </Link>
            </Card>
        </Col>
    );
}

export default Product;
