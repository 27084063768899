import React from "react";

const HeaderRegionNavDropdown = ({ NavLink, dispatch, modifyConditionToSingleGRC, Row, Col }) => {

    return (
        <Row className="my-3 my-lg-0">
            <Col xs={6} className="d-flex flex-column align-items-center flex-lg-row justify-content-lg-evenly">
                
                <div className="mb-1 mb-lg-0 d-flex flex-column align-items-center">
                    <NavLink className="nav-dropdown-element-strong text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "country", targetEle: "australia"}))}>Australia</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "barossa valley"}))}>Barossa Valley</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "coonawarra"}))}>Coonawarra</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "yarra valley"}))}>Yarra Valley</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "claire valley"}))}>Claire Valley</NavLink>
                </div>

                <div className="mt-1 mt-lg-0 d-flex flex-column align-items-center">
                    <NavLink className="nav-dropdown-element-strong text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "country", targetEle: "america"}))}>America</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "napa valley"}))}>Napa Valley</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "los carneros"}))}>Los Carneros</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "santa maria"}))}>Santa Maria</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "russia river valley"}))}>Russia River Valley</NavLink>
                </div>

            </Col>

            <Col xs={6} className="d-flex flex-column align-items-center flex-lg-row justify-content-lg-evenly">

                <div className="mb-1 mb-lg-0 d-flex flex-column align-items-center">
                    <NavLink className="nav-dropdown-element-strong text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "country", targetEle: "italy"}))}>Italy</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "barolo"}))}>Barolo</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "valpolicella"}))}>Valpolicella</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "chianti"}))}>Chianti</NavLink>
                    <NavLink className="nav-dropdown-element-normal text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "region", targetEle: "soave"}))}>Soave</NavLink>
                </div>

                <div className="mt-1 mt-lg-0 d-flex flex-column align-items-center">
                    <NavLink className="nav-dropdown-element-strong text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "country", targetEle: "new zealand"}))}>New Zealand</NavLink>
                    <NavLink className="nav-dropdown-element-strong text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "country", targetEle: "spain"}))}>Spain</NavLink>
                    <NavLink className="nav-dropdown-element-strong text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "country", targetEle: "chile"}))}>Chile</NavLink>
                    <NavLink className="nav-dropdown-element-strong text-nowrap my-lg-1 responsive-nav-element" to="/product" onClick={() => dispatch(modifyConditionToSingleGRC({category: "country", targetEle: "south africa"}))}>South Africa</NavLink>
                </div>
            </Col>
        </Row>
    );
};

export default HeaderRegionNavDropdown;