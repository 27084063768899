import "../../stylesheets/Register.css";
import React, { useEffect, useRef } from "react";
import { 
    registerUser, 
    selectRegisterErrorMsg, 
    selectRegisterHasError,
 } from "../../features/sessionSlice";
import { Modal, Button, Form, Container, Row, Col, Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import isEmail from 'validator/lib/isEmail';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import FormEmail from "./FormEmail";
import FormPwd from "./FormPwd";
import FormReentering from "./FormReentering";
import FormName from "./FormName";

const RegisterModal = ({
    dispatch, useState, useSelector, showRegisterModal, setShowRegisterModal, setShowLoginModal, FaEye, FaEyeSlash, PiWarningCircleLight, validationBorder, invalidationBorder, defaultBorder
}) => {
    
    const hasError = useSelector(selectRegisterHasError);
    const errorMsg = useSelector(selectRegisterErrorMsg);

    // ref
    const emailRef = useRef(null);

    // form control
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [disabled, setDisabled] = useState(true);

    // email
    const [emailValidation, setEmailValidation] = useState(false);
    const [showEmailMsg, setShowEmailMsg] = useState(false);
    const [emailMsg, setEmailMsg] = useState('');

    useEffect(() => {

        if (hasError) {
            setEmailMsg(errorMsg);
        } else {
            setEmailMsg('');
        };

    }, [hasError, dispatch]);

    useEffect(() => {

        setShowEmailMsg(emailMsg ? true : false);

    }, [emailMsg]);

    // pwd
    const [checkAlphaNumeric, setCheckAlphaNumeric] = useState(false);
    const [checkUppercase, setCheckUppercase] = useState(false);
    const [checkLength, setCheckLength] = useState(false);

    const [pwdConfirmed, setPwdConfirmed] = useState(true);
    const [hide1st, setHide1st] = useState(true);
    const [hide2nd, setHide2nd] = useState(true);

    useEffect(() => {

        if (firstName && lastName && email && emailValidation && password && checkAlphaNumeric && checkLength && checkUppercase && confirmPassword && pwdConfirmed) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [firstName, lastName, email, emailValidation, password, confirmPassword, pwdConfirmed]);

    const handleNameKeyUp = (e) => {

        const { value } = e.target;

        if(value) {
            validationBorder(e.target);
        } else {
            invalidationBorder(e.target);
        }

    };

    const handleEmailFocus = (ref) => {
        setEmailMsg('');
        defaultBorder(ref.current);
    };
    
    const handleBlurEmail = (ref) => {

        const validation = isEmail(email);
        setEmailValidation(validation);

        if (!validation) {
            invalidationBorder(ref.current);
            setEmailMsg("Invalid Email");
        } else {
            validationBorder(ref.current);
        }

    };
    
    const toggle1st = () => setHide1st(!hide1st);
    const toggle2nd = () => setHide2nd(!hide2nd);

    const handlePwdChange = (e) => {
        const { value } = e.currentTarget;
        setPassword(value);
        setCheckAlphaNumeric(value && isAlphanumeric(value));
        setCheckUppercase(value && value.match(/[A-Z]/));
        setCheckLength(value && value.length >= 6);
    };

    const handlePwdKeyUp = (e) => {

        const reenteringInput = document.getElementById("register-confirm-password");

        if(checkAlphaNumeric && checkLength && checkUppercase && password===confirmPassword) {

            validationBorder(e.target);
            validationBorder(reenteringInput);
            setPwdConfirmed(true);

        } else if(checkAlphaNumeric && checkLength && checkUppercase && password!==confirmPassword) {

            validationBorder(e.target);
            invalidationBorder(reenteringInput);
            setPwdConfirmed(false);

        } else if((!checkAlphaNumeric || !checkLength || !checkUppercase) && (password===confirmPassword)) {

            invalidationBorder(e.target);
            invalidationBorder(reenteringInput);
            setPwdConfirmed(true);

        } else {

            invalidationBorder(e.target);
            invalidationBorder(reenteringInput);
            setPwdConfirmed(false);

        }

    };

    const handleReenteringKeyUp = (e) => {

        if(password===confirmPassword && checkAlphaNumeric && checkUppercase && checkLength) {

            setPwdConfirmed(true);
            validationBorder(e.target);

        } else if((password===confirmPassword) && (!checkAlphaNumeric || !checkUppercase || !checkLength)) {

            setPwdConfirmed(true);
            invalidationBorder(e.target);

        } else {

            setPwdConfirmed(false);
            invalidationBorder(e.target);

        }

    };

    // form control
    const handleRegisterSubmit = (e) => {
        e.preventDefault();
        dispatch(registerUser({firstName, lastName, email, password}));
    };

    const defaultRegisterModal = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setDisabled(true);
        setEmailValidation(false);
        setShowEmailMsg(false);
        setEmailMsg('');
        setCheckAlphaNumeric(false);
        setCheckUppercase(false);
        setCheckLength(false);
        setPwdConfirmed(true);
        setHide1st(false);
        setHide2nd(false);
    };

    const swapModal = () => {
        dispatch(setShowRegisterModal(false));
        dispatch(setShowLoginModal(true));
    };

    return (
        <Modal centered
            size="lg" 
            show={showRegisterModal} 
            fullscreen={true}
            dialogClassName="modal-width"
            onHide={() => dispatch(setShowRegisterModal(false))}
            onExited={() => defaultRegisterModal()}
            aria-labelledby="example-modal-sizes-title-lg"
            id="register-modal"
        >

            <Modal.Body className="py-0 px-0">
                <div className="modal-close-cube">
                    <FontAwesomeIcon 
                        icon={faXmark} 
                        className="modal-close-icon fs-4" 
                        onClick={() => dispatch(setShowRegisterModal(false))}
                    />
                </div>
                <Container className="">
                    <Row className="modal-row">
                        <Col md={5} lg={6} className="px-0 d-none d-md-block"><div id="register-image"></div></Col>
                        <Col md={7} lg={6} className="my-auto">

                            <p className="m-0 modal-logo">
                                <span className="modal-logo-j">J·</span>
                                <span className="modal-logo-cellar">CELLAR</span>
                                <span className="text-secondary mx-1 mx-xl-2 modal-vertical-rules"> | </span>
                                <span className="modal-red-text text-secondary">Register</span>
                            </p>
                            
                            <Form id="register-form" onSubmit={handleRegisterSubmit}>

                                <FormName 
                                    Form={Form}
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    handleNameKeyUp={handleNameKeyUp}
                                />

                                <FormEmail 
                                    Form={Form}
                                    showEmailMsg={showEmailMsg}
                                    emailMsg={emailMsg}
                                    email={email}
                                    setEmail={setEmail}
                                    handleBlurEmail={handleBlurEmail}
                                    PiWarningCircleLight={PiWarningCircleLight}
                                    emailRef={emailRef}
                                    handleEmailFocus={handleEmailFocus}
                                />

                                <FormPwd 
                                    OverlayTrigger={OverlayTrigger}
                                    Popover={Popover}
                                    Form={Form}
                                    hide1st={hide1st}
                                    toggle1st={toggle1st}
                                    password={password}
                                    FaEyeSlash={FaEyeSlash}
                                    FaEye={FaEye}
                                    handlePwdChange={handlePwdChange}
                                    checkLength={checkLength}
                                    checkAlphaNumeric={checkAlphaNumeric}
                                    checkUppercase={checkUppercase}
                                    handlePwdKeyUp={handlePwdKeyUp}
                                    useEffect={useEffect}
                                    useState={useState}
                                />

                                <FormReentering 
                                    Form={Form}
                                    Badge={Badge}
                                    pwdConfirmed={pwdConfirmed}
                                    hide2nd={hide2nd}
                                    FaEyeSlash={FaEyeSlash}
                                    FaEye={FaEye}
                                    toggle2nd={toggle2nd}
                                    confirmPassword={confirmPassword}
                                    setConfirmPassword={setConfirmPassword}
                                    handleReenteringKeyUp={handleReenteringKeyUp}
                                />

                                <div className="d-grid my-3">
                                    <Button 
                                        variant="outline-success" 
                                        size="lg"
                                        type="submit" 
                                        className="rounded-pill"
                                        disabled={disabled}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Form>

                            <Row className="mt-4">
                                <Col xl="4" className="ms-auto text-end">
                                    <Button 
                                        variant="outline-danger" 
                                        className="border-0 rounded-pill"
                                        onClick={() => swapModal()}
                                    >
                                        To Login
                                    </Button>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>

    );
};

export default RegisterModal;