import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "../../stylesheets/Home.css";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import TopPicksBox from "./TopPicksBox";
import { useDispatch, useSelector } from "react-redux";
import { loadTopPicks, selectTopPicks, selectTopPicksHasError, selectTopPicksIsLoading } from "../../features/productSlice";
import { Link } from "react-router-dom";
import { FaAnglesRight } from "react-icons/fa6";
import Footer from "../Footer/Footer";


const Home = () => {
    const dispatch = useDispatch();
    const topPicks = useSelector(selectTopPicks);
    const topPicksIsLoading = useSelector(selectTopPicksIsLoading);
    const topPicksHasError = useSelector(selectTopPicksHasError);
    const [showDeclaratrion, setShowDeclaration] = useState(true);

    useEffect(() => {
        dispatch(loadTopPicks());
    }, [dispatch]);

    return (
        <>
            <Header />
            <Container className="px-2 px-sm-0 px-lg-2 px-xxl-5 mx-xxl-5 pb-5">
                <Row className="my-2 mx-auto me-lg-4">
                    <Alert variant="danger" 
                        onClose={() => setShowDeclaration(false)} 
                        className="px-3 px-md-4 px-xl-5 z-0"
                        dismissible
                    >
                        <Alert.Heading>
                            Declaration
                        </Alert.Heading>
                        <p className="m-0 fs-6 declaration-text">
                            J·Cellar is a e-commerce <strong>SHOWCASE</strong> website that focused on wine spectrum. 
                            All SKU and prices are <strong>VIRTUALLY</strong> fabricated therefore they serve <strong>NO commerical purpose</strong>. 
                            Payment information wll be provided for <strong>TESTING</strong> at late of your experience here. 
                            Please go ahead and try it out!
                        </p>
                    </Alert>
                </Row>

                <Row>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-inline-flex align-items-center">
                            <img id="top-rated-img" src="/bgImg/top-rated.png" alt="top rated image"
                                className="w-25 d-lg-block d-none"
                            />
                            <p className="fs-5 fs-lg-3 fw-medium p-0 m-0 ms-1 text-nowrap">Premium Selections</p>
                        </div>
                        <Link to="product" className="text-decoration-none me-lg-4">
                            <Button className="btn-warning fw-semibold rounded-pill">More <span className="d-none d-sm-inline-block">Wines </span><FaAnglesRight /></Button>
                        </Link>
                    </div>
                    <TopPicksBox 
                        products={topPicks}
                        productIsLoading={topPicksIsLoading}
                        productHasError={topPicksHasError}
                        Row={Row}
                        Col={Col}
                        dispatch={dispatch}
                    />
                </Row>
            </Container>
            <Footer />
        </>
    );
};


export default Home;