import React, { useEffect, useRef } from "react";
import { 
    modifyConditionToSingleGRC, 
    modifyConditionToSingleType, 
    setSearchResult 
} from "../../features/productSlice";
import InitialResultDropdown from "./InitialResultDropdown";
import SearchResultDropdown from "./SearchResultDropdown";

const Search = ({ NavLink, RiSearchLine, useState, dispatch, useSelector, showBackgroundCover, removeBackgroundCover }) => {

    const searchRef = useRef(null);

    const [keyWord, setKeyWord] = useState('');
    const [showSearchResult, setShowSearchResult] = useState(false);

    useEffect(() => {

        const handleClickOutside = (e) => {

            if(searchRef.current && !searchRef.current.contains(e.target)) {
                setShowSearchResult(false);
                removeBackgroundCover();
            }

        }

        document.addEventListener("mousedown", handleClickOutside);
        return() => document.removeEventListener("mousedown", handleClickOutside);

    }, [searchRef]);

    const handleFocusOnSearchInput = () => {
        setShowSearchResult(true);
        showBackgroundCover();
    };

    const handleSearchChange = (e) => {

        const inputString = String(e.currentTarget.value);
        setKeyWord(inputString);

        // if keywords is 3-words-long, search result in filter
        if (inputString && inputString.length >= 3) {
            dispatch(setSearchResult(inputString.toLowerCase()));
        }

    };

    const handleClickTypeResult = (wineType) => {

        dispatch(modifyConditionToSingleType(wineType));
        setShowSearchResult(false);
        removeBackgroundCover();

    };

    const handleClickGRCResult = (category, targetEle) => {

        dispatch(modifyConditionToSingleGRC({category, targetEle}));
        setShowSearchResult(false);
        removeBackgroundCover();

    };

    const displaySearchResult = () => {

        if((showSearchResult && !keyWord) || (showSearchResult && keyWord.length < 3)) {

            return (
                <InitialResultDropdown 
                    NavLink={NavLink}
                    handleClickTypeResult={handleClickTypeResult}
                />
            );

        } else if (showSearchResult && keyWord.length >= 3) {

            return (
                <SearchResultDropdown 
                    NavLink={NavLink}
                    useSelector={useSelector} 
                    handleClickTypeResult={handleClickTypeResult}
                    handleClickGRCResult={handleClickGRCResult}
                />
            );

        } else {
            return (
                <>No match</>
            );
        }

    };

    return (

        <div id="header-search-cube" className="position-relative z-2" ref={searchRef}>
            <div className="">
                <RiSearchLine id="header-search-icon" className="position-absolute top-50 start-0 translate-middle-y ms-3 text-black-50"/>
                <input 
                    className="header-search-bar w-100 rounded-pill ps-5 border border-secondary-subtle text-primary-emphasis fw-medium"
                    type="text"
                    placeholder="Search"
                    value={keyWord}
                    onChange={handleSearchChange}
                    onFocus={() => handleFocusOnSearchInput()}
                    autoComplete="off"
                />
            </div>
            {
                showSearchResult && 
                <div id="search-dropdown" className="px-3 py-4 fw-light">
                    {displaySearchResult()}
                </div>
            }
        </div>

    );
}

export default Search;