import React from "react";
import { ListGroup } from "react-bootstrap";
import { BiLandscape } from "react-icons/bi";


const RegionSearchResult = ({ NavLink, region, rank, handleClickGRCResult }) => {

    return (
        <>
        { rank===0 ? "" : <hr></hr> }
        <ListGroup variant="flush">
            <ListGroup.Item className="text-uppercase fw-medium fs-result-title border-0">shop wine by region</ListGroup.Item>
            {
                region && region.length > 0 && region.map((ele, index) => (
                    <NavLink to="/product" className="text-decoration-none" key={index}>
                        <ListGroup.Item action 
                            className="text-capitalize fw-light border-0 d-flex align-items-center text-body-secondary"
                            onClick={() => handleClickGRCResult("region", ele)}
                        >
                            <BiLandscape className="fs-4 me-2 text-dark"/>{ele}
                        </ListGroup.Item>
                    </NavLink>
                ))
            }
        </ListGroup>
        </>
    );

}

export default RegionSearchResult;