export const getPaymentInfo = async () => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/payment/status`, {
            headers: { 
                'Content-type': 'application/json',
             },
            credentials: 'include',
        });

        if(!res.ok) {

            if([403, 404, 500].includes(res.status)) {                
                const response = await res.json();
                response["ok"] = res.ok;
                response["status"] = res.status;
                return response;
            }

            return { ok: res.ok, status: res.status };
        }

        const response = await res.json();
        response["ok"] = res.ok;
        response["status"] = res.status;

        return response;

    } catch (error) {
        throw { error };
    }
};