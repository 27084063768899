import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import FilterList from "./FilterList";

const FilterModal = ({ 
    showFilterModal, handleHideFilterModal, filter, dispatch, condition, Row, Col, useState 
}) => {

    const [browserWidth, setBrowserWidth] = useState(window.innerWidth);

    useEffect(() => {

        const manageFilterModal = () => {

            setBrowserWidth(window.innerWidth);

            if(browserWidth >= 992) {
                handleHideFilterModal();
            }

        };

        manageFilterModal();
        window.addEventListener("resize", manageFilterModal);

        return () => window.removeEventListener("resize", manageFilterModal);

    }, [browserWidth]);

    return (
        <Modal backdrop="static"
            scrollable
            size="lg"
            show={showFilterModal} 
            onHide={handleHideFilterModal}
            fullscreen="md-down"
        >
            <Modal.Header closeButton
                className="py-2"
            >
                <Modal.Title>
                    <p className="text-uppercase fw-lighter fs-6 m-0">Filters</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FilterList 
                    filter={filter}
                    dispatch={dispatch}
                    condition={condition}
                    prefixName="modal-filter"
                    Row={Row}
                    Col={Col}
                />
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center py-1">
                <Button variant="outline-dark" 
                    className="text-capitalize rounded-pill w-50"
                    onClick={handleHideFilterModal}
                >
                    Apply
                </Button>
            </Modal.Footer>
        </Modal>
    );

};

export default FilterModal;