import React, { useState } from "react";
import { Button } from "react-bootstrap";
import FilterModal from "./FilterModal";

const FilterButton = ({ filter, dispatch, condition, Row, Col }) => {

    const [showFilterModal, setShowFilterModal] = useState(false);

    const handleShowFilterModal = () => setShowFilterModal(true);
    const handleHideFilterModal = () => setShowFilterModal(false);    

    let filtersAmount = 0;
    for (const key of Object.keys(condition)) {
        if (!condition[key] || condition[key].length===0) {
            continue;
        } else {
            filtersAmount++;
        }
    };

    return (
        <>
            <Button variant="light" 
                id="filter-modal-btn"
                className="text-capitalize w-75 rounded-pill d-flex justify-content-center align-items-center border-dark"
                onClick={handleShowFilterModal}
            >
                <span className="my-auto fw-semibold">Filters</span><span className="dimensioned-badge xxs-fs fw-semibold ms-2 my-auto p-2 bg-dark rounded-circle d-flex justify-content-center align-items-center text-white">{filtersAmount}</span>
            </Button>
            <FilterModal 
                showFilterModal={showFilterModal}
                handleHideFilterModal={handleHideFilterModal}
                filter={filter}
                dispatch={dispatch}
                condition={condition}
                Row={Row}
                Col={Col}
                useState={useState}
            />
        </>
    );

};

export default FilterButton;