import React from "react";
import { selectConditionType } from "../../features/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { filterActivity } from "../../utils/filterActivity";
import { setCondtionType } from "../../features/productSlice";


const FilterType = ({ type, Row, Col }) => {
    const dispatch = useDispatch();
    const conditionTpye = useSelector(selectConditionType);

    return (
        <fieldset className="products-filters-box">
            <legend className="products-filters-box-title">
                <h3>Wine Types</h3>
                <h6>Select multiple</h6>
            </legend>
            <Row className="mt-2 d-flex flex-wrap justify-content-evenly">
                {
                    type && type.map((typeEle, index) => (
                        <Col className={`filter-wine-type${filterActivity(typeEle, conditionTpye)} mx-1`} 
                            key={index}
                            onClick={() => dispatch(setCondtionType(index))}
                        >
                            <p className="m-0 text-capitalize">{typeEle}</p>
                        </Col>
                    ))
                }
            </Row>
        </fieldset>
    );
}

export default FilterType;