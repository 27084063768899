import React from "react";

const EditionLastName = ({ 
    useEffect, useState, dispatch, lastName, updateProfileInfo, profileIsLoading, profileHasError, profileErrorMessage, setProfileMsgDefault, profileNotifySuccess, setProfileSuccessNotification, ListGroup, Button, Form, Spinner, FaCheckCircle 
}) => {

    const [edition, setEdition] = useState(false);
    const [newValue, setNewValue] = useState('');
    const [identical, setIdentical] = useState(true);

    useEffect(() => {

        if(!newValue || newValue.toLowerCase()===lastName.toLowerCase()) {
            setIdentical(true);
        } else {
            setIdentical(false);
        }

    }, [newValue]);

    useEffect(() => {

        if (!profileIsLoading && !profileHasError && profileNotifySuccess) {
            setEdition(false);
        }

    }, [profileIsLoading, profileHasError, profileNotifySuccess, dispatch]);

    const handleClickEdit = () => {
        dispatch(setProfileSuccessNotification(false));
        dispatch(setProfileMsgDefault());
        setNewValue('');
        setEdition(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateProfileInfo({aspect: 'lastName', newValue}));
    };

    const renderButton = () => {

        if(!profileNotifySuccess) {

            return (
                profileIsLoading ? 
                <Button variant="success" size="sm" type="submit" 
                    className="rounded-pill mt-2"
                    disabled={true}
                >
                    <Spinner 
                        variant="light"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2 fs-6 my-0"
                    />
                    Loading
                </Button>
                :
                <Button variant="success" size="sm" type="submit" 
                    className="rounded-pill mt-2"
                    disabled={identical ? true : false}
                >
                    Submit
                </Button>
            );

        } else {

            return (
                <Button variant="success" size="sm" type="submit" 
                    className="rounded-pill mt-2"
                    disabled={true}
                >
                     <FaCheckCircle className="fs-5"/> 
                </Button>
            );

        }

    };

    return (
        <ListGroup.Item className="d-flex justify-content-between align-items-center">
            {
                edition && 
                <>
                    <Form onSubmit={handleSubmit}>
                        <Form.Label htmlFor="edit-last-name" className="my-2 fw-light">Last Name</Form.Label>
                        <Form.Control
                            id="edit-last-name"
                            className="input-width-resize"
                            type="text"
                            placeholder={lastName}
                            onChange={(e) => setNewValue(e.currentTarget.value)}
                            onFocus={() => dispatch(setProfileMsgDefault())}
                            value={newValue}
                        />
                        {renderButton()}
                        <p className="text-danger my-0 fst-italic">{profileErrorMessage}</p>
                    </Form>
                    <Button variant="outline-dark" 
                        className="rounded-pill xs-fs"
                        onClick={() => setEdition(false)}
                    >
                        Cancel
                    </Button>
                </>
            }
            {
                !edition && 
                <>
                    <div>
                        <p className="my-2 fw-light">Last Name</p>
                        <p className="my-2 fw-semibold text-capitalize fs-6">{lastName}</p>
                    </div>
                    <Button variant="outline-dark" 
                        className="rounded-pill fs-6"
                        onClick={() => handleClickEdit()}
                    >
                        Edit
                    </Button>
                </>

            }
        </ListGroup.Item>
    );
};

export default EditionLastName;