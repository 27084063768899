import React from "react";

const ProductDetailsWineInfo = ({
    winery, grapes, vintage, country, region, type
}) => {

    return (
        <>
            <div className="text-capitalize mt-3 mt-md-2 fs-4 fw-normal">
                {winery}
            </div>
            <div className="text-capitalize mt-3 mt-md-2 fs-3 fs-xl-2 fw-bold">
                {grapes.length>2?'Blend':grapes.join(', ')} {vintage}
            </div>
            <div className="text-capitalize mt-3 mt-md-2 fs-6 ">
                {country} · {region} · {winery} · {`${type} Wine`} · {grapes.length>2?'Blend':grapes.join(' · ')}
            </div>
        </>
    );

};

export default ProductDetailsWineInfo;