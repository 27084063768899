import React from "react";
import { selectCountryCodeObj } from "../../features/productSlice";
import { ListGroup } from "react-bootstrap";

const CountrySearchResult = ({ NavLink, country, useSelector, rank, handleClickGRCResult }) => {

    const countryCodeObj = useSelector(selectCountryCodeObj);

    return (
        <>
        { rank===0 ? "" : <hr></hr> }
        <ListGroup variant="flush">
            <ListGroup.Item className="text-uppercase fw-medium fs-result-title border-0">shop wine by country</ListGroup.Item>
            {
                country && country.length > 0 && country.map((ele, index) => (
                    <NavLink to="/product" className="text-decoration-none" key={index}>
                        <ListGroup.Item action
                            className="text-capitalize fw-light border-0 d-flex align-items-center text-body-secondary"
                            onClick={() => handleClickGRCResult("country", ele)}
                        >
                            <span className={`fi fi-${countryCodeObj[ele]} fis`}></span>{ele}
                        </ListGroup.Item>
                    </NavLink>
                ))
            }
        </ListGroup>
        </>
    );

}

export default CountrySearchResult;