import React, { useEffect, useRef } from "react";
import { processForSuccess } from "../../features/cartSlice";
import HeaderForIntermedia from "../Header/HeaderForIntermedia";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

const PaymentIntermediaProcessOrder = () => {

    const dispatch = useDispatch();

    const hasDispatched = useRef(null);

    useEffect(() => {
        
        if(!hasDispatched.current) {

            dispatch(processForSuccess());

            hasDispatched.current = true;

        }

    }, [dispatch]);

    return (
        <>
            <HeaderForIntermedia />
            <div id="payment-intermedia-body">
                <Container className="py-5 px-3 px-sm-4 px-md-5 h-100 w-100">
                    <Row className="mx-auto my-5 fs-5">
                        <Col xs={3} sm={2} className="mx-0 ms-auto text-end">
                            <Spinner 
                                variant="success"
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                className="me-1 my-auto fs-6"
                            />
                        </Col>
                        <Col xs={9} lg={8} xxl={6} className="mx-0 me-auto">
                            <p className="fs-4 text-warning-emphasis fw-medium">
                                Payment is confirmed! We are proccessing order for you...
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default PaymentIntermediaProcessOrder;