import React from "react";
import { Dropdown } from "react-bootstrap";
import { logOutUser } from "../../features/sessionSlice";

const UserIconDropdown = ({ userInfo, RiUserLine, dispatch, setShowLoginModal, NavLink }) => {

    const handleLogOut = () => {
        dispatch(logOutUser());
    };
    
    return (
        <Dropdown>
            <Dropdown.Toggle variant="warning" className="rounded-pill d-flex align-items-center px-1 px-md-2">
                <RiUserLine id="header-user-icon" className="mx-1 my-0 p-0 fs-5 fs-md-4"/>
                <span className="text-capitalize fs-6 d-none d-sm-block">{userInfo && userInfo.firstName}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="rounded">
                <NavLink className="header-function-dropdown-list my-1 px-3 py-1"
                    to={`/user/${userInfo.firstName && userInfo.firstName.toLowerCase()}`}
                >
                    Profile
                </NavLink>
                <NavLink className="header-function-dropdown-list my-2 px-3 py-1"
                    to='/orders'
                >
                    My Orders
                </NavLink>
                <NavLink className="header-function-dropdown-list my-1 px-3 py-1"
                    to='/edit'
                >
                    Setting
                </NavLink>
                <Dropdown.Divider />
                <NavLink className="header-function-dropdown-list my-1 px-3 py-1"
                    to='/'
                    onClick={() => handleLogOut()}
                >
                    Log Out
                </NavLink>
            </Dropdown.Menu>
        </Dropdown>
    )
}


export default UserIconDropdown;
