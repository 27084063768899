import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const HeaderForIntermedia = () => {

    return (
        <div className="px-1 z-2 shadow">
            <Container className="pt-2 px-2 px-lg-3 px-xxl-5 mx-xxl-5">
                <Row id="top-header" className="align-items-center">
                    <Col className="px-0 px-sm-2 px-lg-3">
                        <div className="text-center">
                            <span id="header-logo" className="fs-2">
                                <span id="header-logo-j">J·</span>CELLAR
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default HeaderForIntermedia;