import '../../stylesheets/Cart.css';
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserInfoContext } from '../../app/Context';
import { 
    handleAddItemToCart, 
    handleRemoveItemFromCart, 
    loadCart, 
    selectCartInfo,
    selectTotalAmount,
    selectCartIsLoading,
    selectCartHasError,
    selectOnPaymentProcess,
    selectIsLoadingOnPaymentProcess,
    processCarStatus,
} from "../../features/cartSlice";
import { selectLoginStatus, selectSessionHasError, selectSessionIsLoading } from "../../features/sessionSlice";
import CartItems from "./CartItems";
import { useNavigate } from "react-router-dom";
import Header from '../Header/Header';
import { Col, Container, Row, Spinner, Button } from 'react-bootstrap';
import DotsPreloader from '../../loading/DotsPreloader';
import Footer from '../Footer/Footer';
import ErrorHandler from '../ErrorPage/ErrorHandler';
import EmptyHandler from '../EmptyPage/EmptyHandler';


const Cart = () => {
    const userInfo = useContext(UserInfoContext);
    const sessionIsLoading = useSelector(selectSessionIsLoading);
    const sessionHasError = useSelector(selectSessionHasError);
    const loginStatus = useSelector(selectLoginStatus);
    const cartInfoArr = useSelector(selectCartInfo);
    const cartIsLoading = useSelector(selectCartIsLoading);
    const cartHasError = useSelector(selectCartHasError);
    const onPaymentProcess = useSelector(selectOnPaymentProcess);
    const isLoadingOnPaymentProcess = useSelector(selectIsLoadingOnPaymentProcess);
    const totalAmount = useSelector(selectTotalAmount);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // when cart component loads, always check cart status firstly
    useEffect(() => {
        if (!sessionIsLoading && !sessionHasError && loginStatus) {
            dispatch(processCarStatus());
        }
    }, [sessionIsLoading, sessionHasError]);

    // after above procedure, loading cart info
    useEffect(() => {
        // when onPaymentProcess is neither default nor true and isLoadingOnPaymentProcess is false
        // then load cart
        if (onPaymentProcess!=='none' && !onPaymentProcess && !isLoadingOnPaymentProcess) {
            setTimeout(() => {
                dispatch(loadCart());
            }, 1000);
        }
    }, [onPaymentProcess]);

    const onAddItemToCart = (e, productId) => {
        e.preventDefault();
        dispatch(handleAddItemToCart({productId, num: 1}));
    };

    const onRemoveItemFromCart = (e, index) => {
        e.preventDefault();
        const itemIdArray = cartInfoArr[index].cart_item_id_arr;
        const itemId = Number(itemIdArray[0]);

        if (itemIdArray.length===1) {
            return;
        } else {
            dispatch(handleRemoveItemFromCart({itemId}));
        }
    };

    const [proceed, setProceed] = useState(false);
    const handleClickCheckout = (e) => {
        e.preventDefault();
        setProceed(true);

        setTimeout(() => {
            navigate('/checkout');
        }, 1500);
    };

    if(!sessionIsLoading && sessionHasError && !loginStatus) {
        return (
            <ErrorHandler />
        )
    };

    if (loginStatus && cartIsLoading && !cartHasError && cartInfoArr.length===0) {
        return (
            <>
                <Header />
                <div className='vh-100'>
                    <DotsPreloader />
                </div>
                <Footer />
            </>
        );
    };
    
    if (loginStatus && !cartIsLoading && !cartHasError && cartInfoArr.length===0) {
        return (
            <EmptyHandler title="Your Cart is Empty" />
        )
    };


    return (
        <>
            <Header />
            <div id="cart-body-container" className='z-0 position-relative pb-5'>
                <Container className='pt-4 px-2 px-lg-4 px-xxl-5 pb-5 mb-5'>
                    <Row className='mb-4 mx-3 fs-3 text-capitalize'>
                        {userInfo && `${userInfo.firstName}'s Cart:`}
                    </Row>
                    <Row>
                        <Col xs={12} md={8} xl={7}
                            id='list-container' 
                            className='d-flex flex-column mx-auto'
                        >
                            {
                                cartInfoArr && cartInfoArr.map((itemObj, index) => (
                                    <CartItems 
                                        key={index}
                                        itemObj={itemObj} 
                                        index={index}
                                        onAddItemToCart={onAddItemToCart}
                                        onRemoveItemFromCart={onRemoveItemFromCart}
                                        Container={Container}
                                        Row={Row}
                                        Col={Col}
                                        Button={Button}
                                    />
                                ))
                            }
                        </Col>
                        <Col xs={12} md={4} className="py-5 mx-auto">
                            <Row className='sticky-md-top'>
                                <Col xs={8} md={12} lg={10} className='ms-auto mx-md-auto text-center'>
                                    <p className='my-0 fs-4'>
                                        <span className='me-1 fw-light text-capitalize'>Total:</span><span className='fw-semibold bg-warning bg-gradient rounded text-dark px-2'><span className='fw-light'>£ </span>{totalAmount}</span>
                                    </p>
                                    <Button variant='success'
                                        className='checkout-button rounded-pill text-capitalize my-4 py-2 w-100 d-flex align-items-center justify-content-center'
                                        onClick={handleClickCheckout}
                                        disabled={proceed ? true : false}
                                    >
                                        {
                                            proceed ? 
                                            <Spinner animation="border" variant="warning" className='spinner-size mx-auto my-auto'/>
                                            :
                                            <p className='m-0 fs-6 text-center text-capitalize'>Checkout</p>
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer marginTop={0}/>
        </>
    )
};


export default Cart;
