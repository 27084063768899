import React from "react";

const HeaderOfferNavDropdown = ({ NavLink, dispatch, modifyConditionBasedOnPrice }) => {

    return (
        <>
            <NavLink className="nav-dropdown-element-normal responsive-nav-element my-2 my-lg-0" to="/product" onClick={() => dispatch(modifyConditionBasedOnPrice({min: 0, max: 20}))}>Less than £20</NavLink>
            <NavLink className="nav-dropdown-element-normal responsive-nav-element my-2 my-lg-0" to="/product" onClick={() => dispatch(modifyConditionBasedOnPrice({min: 20, max: 50}))}>From £20 To £50</NavLink>
            <NavLink className="nav-dropdown-element-normal responsive-nav-element my-2 my-lg-0" to="/product" onClick={() => dispatch(modifyConditionBasedOnPrice({min: 50, max: 100}))}>From £50 To £100</NavLink>
            <NavLink className="nav-dropdown-element-normal responsive-nav-element my-2 my-lg-0" to="/product" onClick={() => dispatch(modifyConditionBasedOnPrice({min: 100, max: 1000}))}>Over £100</NavLink>
        </>
    );
};

export default HeaderOfferNavDropdown;