import React from "react";

const OrderItemsList = ({ ele, Row, Col }) => {

    return (
        <Row>
            <Col xs="12" className="text-nowrap text-capitalize text-start text-sm-center px-1 px-sm-auto">
                {ele}
            </Col>
        </Row>
    )
};


export default OrderItemsList;