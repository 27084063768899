const api_endpoint = process.env.REACT_APP_API_ENDPOINT;

export const verifyAuthentication = async () => {
  try {
    const res = await fetch(`${api_endpoint}/auth/status`, {
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include',
    });

    const response = await res.json();

    return response;

  } catch (error) {
    return { error };
  }
};

export const getUserInfoById = async (userId) => {
  try {
    const res = await fetch(`${api_endpoint}/users/${userId}`, {
      headers: { 
        'Content-type': 'application/json', 
      },
      credentials: "include",
    });

    const response = await res.json();

    return response;

  } catch (error) {
    return { error };
  }
};

export const createUser = async (data) => {
    try {
        const res = await fetch(`${api_endpoint}/auth/register`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-type': 'application/json',
              'Auth-Method': 'local',
            },
            credentials: 'include',
        });

        const response = await res.json();
        response['ok'] = res.ok;
        response['status'] = res.status;
        
        return response;
        
    } catch (error) {
        return { error };
    }
};

export const createGoogleUser = async(utObj) => {
  try {
    const res = await fetch(`${api_endpoint}/auth/google`, {
      method: "POST",
      body: JSON.stringify(utObj),
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include',
    });
    
    if (!res.ok) {
      const response = { ok: res.ok, status: res.status };
      return response;
    }

    const response = await res.json();
    response["ok"] = res.ok;
    response["status"] = res.status;

    return response;

  } catch (error) {
    return{ error };
  }
};

export const loginLocalUser = async(userObj) => {
  try {
    const res = await fetch(`${api_endpoint}/auth/login`, {
      method: "POST",
      body: JSON.stringify(userObj),
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include',
    });

    if (!res.ok) {
      const response = { ok: res.ok };
      return response;
    }

    const response = await res.json();
    response["ok"] = res.ok;
    response["status"] = res.status;

    return response;

  } catch (error) {
    return { error };
  }
};

export const signOut = async () => {
  try {
    const response = await fetch(`${api_endpoint}/users/logout`, {
      method: "DELETE",
      headers: {
        'Content-type': 'application/json',
      },
      credentials: 'include',
    })

    const { ok, status } = response;

    return { ok, status };

  } catch (error) {
    return { error };
  }
}

export const getWishlist = async () => {
  try {
    const res = await fetch(`${api_endpoint}/users/profile/wishlist/display`, {
      headers: { 
        'Content-type': 'application/json', 
      },
      credentials: "include",
    });

    if(res.status==204) {
      return [];
    }

    const response = await res.json();

    return response;

  } catch (error) {
    return { error };
  }
};

export const getWishlistSummary = async() => {
  try {
    
    const res = await fetch(`${api_endpoint}/users/profile/wishlist`, {
      headers: { 'Content-type': 'application/json', },
      credentials: "include",
    });

    if (res.status==204) {
      return [];
    }

    const response = await res.json();

    return response;

  } catch (error) {
    return { error };
  }
};

export const addItemToWishlist = async(productId) => {
  try {
    const res = await fetch(`${api_endpoint}/users/profile/wishlist`, {
      method: "POST",
      body: JSON.stringify({productId}),
      headers: { 
        'Content-type': 'application/json', 
      },
      credentials: "include",
    });

    if(!res.ok) {
      return { res: res.ok, status: res.status };
    }

    const response = await res.json();
    response["ok"] = res.ok;
    response["status"] = res.status;

    return response;

  } catch (error) {
    return { error };
  }
};

export const removeWishlistItem = async(productId) => {
  try {
    const res = await fetch(`${api_endpoint}/users/profile/wishlist`, {
      method: "DELETE",
      body: JSON.stringify({productId}),
      headers: { 
        'Content-type': 'application/json', 
      },
      credentials: "include",
    });

    if(!res.ok) {
      return { res: res.ok, status: res.status };
    }

    const response = await res.json();
    response["ok"] = res.ok;
    response["status"] = res.status;

    return response;

  } catch (error) {
    return { error };
  }
};

export const updateProfile = async(aspect, newValue) => {
  try {
    const res = await fetch(`${api_endpoint}/users/profile/${aspect}`, {
      method: "PUT",
      body: JSON.stringify({[aspect]: newValue}),
      headers: { 
        'Content-type': 'application/json', 
      },
      credentials: "include",
    });

    if (!res.ok) {
      const response = { ok: res.ok, status: res.status };
      return response;
    }

    const response = await res.json();
    response["ok"] = res.ok;
    response["status"] = res.status;

    return response;

  } catch (error) {
    return { error };
  }
};