import React from "react";

const ProductDetailsFunctionBoxEta = ({ CiDeliveryTruck, eta }) => {

    return (
        <div id="etd-row">
            <CiDeliveryTruck 
                id="delivery-icon"
                className="fs-1"
            />
            <p>Estimated date of reception from {eta[0][0]}, {eta[0][1]} {eta[0][2]} to {eta[1][0]}, {eta[1][1]} {eta[1][2]}.</p>
        </div>
    );

}

export default ProductDetailsFunctionBoxEta;