import React from "react";

const ProductDetailsFunctionBoxPrice = ({ price, Row }) => {

    return (
        <Row>
            <h2 className="m-0">£ {price.toFixed(2)}</h2>
            <h6 className="font-family-cambria m-0 mt-2 fw-light">The price is per bottle</h6>
        </Row>
    );

};

export default ProductDetailsFunctionBoxPrice;