import React, { useEffect } from "react";
import Product from "./Product";
import { setProductListToDefault } from "../../features/productSlice";
import ProductListLoading from "../../loading/ProductListLoading";


const ProductList = ({ products, productIsLoading, productHasError, dispatch, Col, Row }) => {

    useEffect(() => {
        dispatch(setProductListToDefault());
    }, []);

    if (productIsLoading) {
        return (
            <Row xs={1} sm={2} lg={1} className="mt-5 px-0 mx-auto w-100">
                <ProductListLoading numOfcontainers={5}/>
            </Row>
        )
    };

    if(products && products.length===0) {

        return (
            <div className="vh-100 w-100">
                <p className="fs-6 text-center fw-semibold mt-5 text-capitalize">
                    - no matched wine -
                </p>
            </div>
        );

    }

    return (
        <Row xs={1} sm={2} lg={1} className="mt-5 px-0 mx-auto w-100 flex-lg-column">
            {
                products && products.length > 0 && products.map((product, index) => (
                    <Product 
                        key={index}
                        product={product}
                        dispatch={dispatch}
                        Col={Col}
                        Row={Row}
                    />
                ))
            }
        </Row>
    );
}

export default ProductList;