import React from "react";
import UserIconDropdown from "./UserIconDropdown";
import PromptDropdown from "./PromptDropdown";
import CartOffcanvas from "../Cart/CartOffcavans";
import LoginModal from "../Login/LoginModal";
import RegisterModal from "../Register/RegisterModal";
import { 
    selectLoginStatus, 
    selectShowLoginModal, 
    selectShowRegisterModal, 
    selectUser,
    setShowLoginModal,
    setShowRegisterModal, 
} from "../../features/sessionSlice";
import { Badge } from "react-bootstrap";
import { 
    selectCartItemsTotalQuan, 
    selectCartSummary, 
    selectEditCartItemShowPromptDropdown 
} from "../../features/cartSlice";
import { invalidationBorder, validationBorder, defaultBorder } from "../../utils/borderStyle"
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { RiUserLine } from "react-icons/ri";
import { PiShoppingCart, PiWarningCircleLight } from "react-icons/pi";


const HeaderUserSection = ({
    useSelector, dispatch, NavLink, useState, 
}) => {

    const loginStatus = useSelector(selectLoginStatus);
    const userInfo = useSelector(selectUser);
    const cartSummary = useSelector(selectCartSummary);
    const cartItemsTotalQuan = useSelector(selectCartItemsTotalQuan);
    const editCartItemShowPromptDropdown = useSelector(selectEditCartItemShowPromptDropdown);
    const showLoginModal = useSelector(selectShowLoginModal);
    const showRegisterModal = useSelector(selectShowRegisterModal);

    const [showCartOffcanvas, setShowCartOffcanvas] = useState(false);
    
    return (
        <div id="header-function-cube" className="d-flex align-items-center justify-content-sm-end justify-content-center">
            {
                loginStatus ? 
                <>
                    <UserIconDropdown 
                        userInfo={userInfo}
                        RiUserLine={RiUserLine}
                        dispatch={dispatch}
                        setShowLoginModal={setShowLoginModal}
                        NavLink={NavLink}
                    />

                    <span className="fs-4 fw-light text-secondary mx-1 mx-md-2"> | </span>

                    <NavLink className="position-relative d-inline-flex flex-column align-items-center" 
                        onClick={() => setShowCartOffcanvas(true)}
                    >
                        <PiShoppingCart id="header-cart-icon" className="fs-sm-3 fs-2"/>
                        <Badge bg="danger" 
                            className={`position-absolute top-0 start-100 translate-middle ${cartItemsTotalQuan===0?"d-none":"d-flex"} align-items-center justify-content-center fw-normal rounded-circle`}
                            id="items-quantity-badge"
                        >
                            {cartItemsTotalQuan}
                        </Badge>
                        {
                            editCartItemShowPromptDropdown && 
                            <PromptDropdown setShowCartOffcanvas={setShowCartOffcanvas} />
                        }
                    </NavLink>
                    {
                        showCartOffcanvas && 
                        <CartOffcanvas 
                            showCartOffcanvas={showCartOffcanvas}
                            setShowCartOffcanvas={setShowCartOffcanvas}
                            cartSummary={cartSummary}
                        />
                    }
                </>
                :
                <>
                    <NavLink className="d-flex align-items-center text-decoration-none"  
                        onClick={() => dispatch(setShowLoginModal(true))}
                    >
                        <RiUserLine id="header-user-icon" /> 
                        <h6 id="header-sign-in-text">Sign In</h6>
                    </NavLink>
                    <LoginModal 
                        dispatch={dispatch}
                        useState={useState}
                        useSelector={useSelector}
                        showLoginModal={showLoginModal} 
                        setShowLoginModal={setShowLoginModal} 
                        setShowRegisterModal={setShowRegisterModal}
                        FaEye={FaEye}
                        FaEyeSlash={FaEyeSlash}
                        PiWarningCircleLight={PiWarningCircleLight}
                        validationBorder={validationBorder}
                        invalidationBorder={invalidationBorder}
                        defaultBorder={defaultBorder}
                    />
                    <RegisterModal 
                        dispatch={dispatch}
                        useState={useState}
                        useSelector={useSelector}
                        showRegisterModal={showRegisterModal}
                        setShowRegisterModal={setShowRegisterModal}
                        setShowLoginModal={setShowLoginModal}
                        FaEye={FaEye}
                        FaEyeSlash={FaEyeSlash}
                        PiWarningCircleLight={PiWarningCircleLight}
                        validationBorder={validationBorder}
                        invalidationBorder={invalidationBorder}
                        defaultBorder={defaultBorder}
                    />
                </>
            }
        </div>
    );

};

export default HeaderUserSection;