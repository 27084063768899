import React from "react";
import ProductLoading from "./ProductLoading";


const ProductListLoading = ({numOfcontainers, fixedDoubleDecker=false}) => {

    return (
        <>
            {
                Array.from({length: numOfcontainers}).map((_, index) => (
                    <ProductLoading 
                        key={index}
                        fixedDoubleDecker={fixedDoubleDecker}
                    />
                ))
            }
        </>
    )
}


export default ProductListLoading;