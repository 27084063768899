import React from "react";
import { Container, Spinner } from "react-bootstrap";

const DotsPreloader = () => {

    return (
        <Container className="px-5">
            <div className="mt-5 d-flex justify-content-center">
                <Spinner animation="grow" className="mx-2" variant="danger" size="sm"/>
                <Spinner animation="grow" className="mx-2" variant="danger" size="sm"/>
                <Spinner animation="grow" className="mx-2" variant="danger" size="sm"/>
            </div>
        </Container>
    );

};

export default DotsPreloader;