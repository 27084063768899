import React from "react";
import { Outlet } from "react-router-dom";


const Root = () => {

    return (
        <main id="root-container" className="position-relative">
            <div id="bg-cover-for-search" className="d-none position-absolute w-100 h-100 bg-dark opacity-25 z-1"></div>
            <Outlet />
        </main>
    );
};


export default Root;