import React from "react";

const ItemListItems = ({ Row, Col, itemObj, page="checkout" }) => {

    const productDescription = (page) => {
        if(page==="checkout") {
            return `${itemObj.winery} ${itemObj.grapes.length>2?'Blend':itemObj.grapes.join(', ')} ${itemObj.vintage}`;
        } else if(page==="order") {
            return `${itemObj.product_description} - ${itemObj.grapes && itemObj.grapes.length>2?"Blend":itemObj.grapes.join(', ')}`;
        } else {
            return '';
        }
    };

    return (
        <>
            <Col xs={12} lg={6}>
                <Row>
                    <Col xs={3} className="d-flex align-items-center">
                        <img className="w-50 width-narrow-25 width-narrow-20 width-narrow-15 d-block mx-auto my-0" 
                            src={`/wineImg/${itemObj.picture}.png`}
                            alt={`${itemObj.picture} picture`}
                        />
                    </Col>
                    <Col xs={9} className="d-flex flex-column justify-content-between ">
                        <p className="my-auto w-100 text-capitalize fs-6">
                            {
                                productDescription(page)
                            }
                        </p>
                        <p className="d-lg-none my-auto w-100 fs-6 fw-semibold">
                            {itemObj.quantity} {itemObj.quantity>1?"bottles":"bottle"}
                        </p>
                        <p className="d-lg-none my-auto w-100 fs-6">
                            £{(itemObj.quantity * itemObj.price).toFixed(2)} (£{itemObj.price.toFixed(2)}/bottle)
                        </p>
                    
                    </Col>
                </Row>
            </Col>

            <Col lg={2} className="d-none d-lg-flex align-items-center">
                <p className="my-auto w-100 fs-6 fw-semibold text-center">
                    {itemObj.quantity} {itemObj.quantity>1?"bottles":"bottle"}
                </p>
            </Col>

            <Col lg={2} className="d-none d-lg-flex align-items-center">
                <p className="my-auto w-100 fs-6 text-center">
                    £{itemObj.price.toFixed(2)}
                </p>
            </Col>

            <Col lg={2} className="d-none d-lg-flex align-items-center">
                <p className="my-auto w-100 fs-6 text-center">
                    £{(itemObj.quantity * itemObj.price).toFixed(2)}
                </p>
            </Col>
        </>
    );

}

export default ItemListItems;