import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    selectCheckPaymentStatusHasError, 
    selectCheckPaymentStatusIsLoading, 
    selectPaymentStatus, 
    selectPaymentStatusMessage, 
    selectProcessOrderHasError, 
    selectProcessOrderIsLoading,
    selectProcessOrderMessage,
    selectProcessOrderResult
} from "../../features/cartSlice";
import "../../stylesheets/PaymentIntermedia.css";
import PaymentIntermediaCheckPayment from "./PaymentIntermediaCheckPayment";
import PaymentIntermediaErrors from "./PaymentIntermediaErrors";
import PaymentIntermediaProcessOrder from "./PaymentIntermediaProcessOrder";
import PaymentIntermediaSuccess from "./PaymentIntermediaSuccess";


const PaymentIntermedia = () => {
    const dispatch = useDispatch();
    // payment status
    const paymentStatus = useSelector(selectPaymentStatus);
    const checkPaymentStatusIsLoading = useSelector(selectCheckPaymentStatusIsLoading);
    const checkPaymentStatusHasError = useSelector(selectCheckPaymentStatusHasError);
    const paymentStatusMessage = useSelector(selectPaymentStatusMessage);
    // order process status
    const processOrderIsLoading = useSelector(selectProcessOrderIsLoading);
    const processOrderHasError = useSelector(selectProcessOrderHasError);
    const processOrderStatus = useSelector(selectProcessOrderResult);
    const processOrderMessage = useSelector(selectProcessOrderMessage);

    // loading paymentStatus
    if (paymentStatus!=="paid" && !checkPaymentStatusHasError && !processOrderIsLoading) {
        return (
            <PaymentIntermediaCheckPayment 
                dispatch={dispatch}
                paymentStatus={paymentStatus}
            />
        )
    }

    // error on loading paymentStatus
    if (paymentStatus!=="paid" && checkPaymentStatusHasError) {
        return (
            <PaymentIntermediaErrors
                message={paymentStatusMessage}
            />
        )
    }

    // payment confirmed and start to process order
    if (paymentStatus==='paid' && !checkPaymentStatusIsLoading && !checkPaymentStatusHasError && !processOrderStatus) {
        return (
            <PaymentIntermediaProcessOrder />
        )
    }

    // error on loading processOrder
    if (paymentStatus==='paid' && processOrderHasError) {
        return (
            <PaymentIntermediaErrors 
                message={processOrderMessage}
            />
        )
    }

    // order placed successfully
    if(paymentStatus==='paid' && processOrderStatus) {
        return (
            <PaymentIntermediaSuccess />
        );
    }
};

export default PaymentIntermedia;
