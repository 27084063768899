import React, { useState } from "react";
import "../../stylesheets/Footer.css";
import { Container, Row, Col } from "react-bootstrap";
import { PiSmileyStickerFill } from "react-icons/pi";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Footer = ({marginTop=5}) => {

    const [hovered, setHovered] = useState(false);
    
    return (
        <div id="footer-container" 
            className={`mt-${marginTop} p-2 py-md-3 bg-success bg-gradient text-white border-top border-success-subtle`}
        >
            <Container className="p-auto h-100">
                <Row className="mx-auto my-auto">
                    <Col xs={12} md={7} className="my-1">
                        <p className="m-0 text-capitalize text-center fw-light">
                            J·Cellar - A Skillset Showcase Website By JC
                        </p>
                    </Col>
                    <Col xs={12} md={5} className="my-1">
                        <a 
                            href="https://jamiechen.co.uk/" 
                            target="_blank"
                            className="text-decoration-none text-white"
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                        >
                            <p className={`m-0 text-capitalize text-center ${hovered?'text-decoration-underline fw-normal text-warning':'fw-medium'}`}>
                                visit more
                                {
                                    hovered ? 
                                    <MdKeyboardDoubleArrowRight className="fs-5 text-warning ms-1" />
                                    :
                                    <PiSmileyStickerFill className="fs-5 text-light ms-1"/>
                                }
                            </p>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default Footer;