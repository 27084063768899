import React, { useState } from "react";
import ItemListItems from "../ItemList/ItemListItems";

const OrderDetailsItems = ({ itemObj, index, Row, Col, Link }) => {

    const [hovered, setHovered] = useState(false);

    return (
        <>
        {index===0 ? "" : <hr className="my-0"></hr>}
        <Link to={`/product/${itemObj.product_id}`} 
            className="text-decoration-none text-dark w-100 mx-auto"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Row className={`mx-auto py-2 d-flex align-items-center rounded ${hovered?"shadow-lg bg-light":""}`}>
                <ItemListItems 
                    Row={Row}
                    Col={Col}
                    itemObj={itemObj}
                    page="order"
                />
            </Row>
        </Link>
        </>
    )

};


export default OrderDetailsItems;