import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getOrderDetails, getOrdersList } from "../utils/orders";


export const loadOrdersList = createAsyncThunk(
    "order/loadOrdersList",
    async () => {
        const response = await getOrdersList();

        if (response.length===0) { return response; };

        return response;

    }
);

export const loadOrderDetails = createAsyncThunk(
    "order/loadOrderDetails",
    async(data) => {
        const { orderId } = data;
        
        const response = await getOrderDetails(orderId);

        return response;

    }
);

const orderSlice = createSlice({
    name: "order",
    initialState: {
        orders: [],
        isLoading: true,
        hasError: false,
        details: {},
        isLoadingDetails: true,
        hasErrorWithDetails: false,
    },
    reducers: {
        defaultOrderStates: (state) => {
            state.orders = [];
            state.details = {};
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(loadOrdersList.pending, (state) => {
            state.isLoading = true;
            state.hasError = false;
        })
        .addCase(loadOrdersList.fulfilled, (state, action) => {
            state.orders = action.payload;
            state.isLoading = false;
            state.hasError = false;
        })
        .addCase(loadOrdersList.rejected, (state) => {
            state.isLoading = false;
            state.hasError = true;
        })
        .addCase(loadOrderDetails.pending, (state) => {
            state.isLoadingDetails = true;
            state.hasErrorWithDetails = false;
        })
        .addCase(loadOrderDetails.fulfilled, (state, action) => {
            state.details = action.payload;
            state.isLoadingDetails = false;
            state.hasErrorWithDetails = false;
        })
        .addCase(loadOrderDetails.rejected, (state) => {
            state.isLoadingDetails = false;
            state.hasErrorWithDetails = true;
        })
    }
});

export const selectOrdersList = state => state.order.orders;

export const selectOrdersIsLoading = state => state.order.isLoading;

export const selectOrdersHasError = state => state.order.hasError;

export const selectOrderDetails = state => state.order.details;

export const selectIsLoadingOrderDetails = state => state.order.isLoadingDetails;

export const selectHasErrorWithOrderDetails = state => state.order.hasErrorWithDetails;

export const { defaultOrderStates } = orderSlice.actions;

export default orderSlice.reducer;