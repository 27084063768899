import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    loadWishlist, 
    selectHasErrorWithWishlist, 
    selectIsLoadingWishlist, 
    selectLoginStatus, 
    selectSessionHasError, 
    selectSessionIsLoading, 
    selectUser, 
    selectWishlist 
} from "../../features/sessionSlice";
import { useNavigate, Link } from "react-router-dom";
import "../../stylesheets/User.css";
import Header from "../Header/Header";
import { Container, Row, Col, Card, Button, Tab, Tabs, Table, Image, Spinner } from "react-bootstrap";
import WishList from "./Wishlist";
import Footer from "../Footer/Footer";
import DotsPreloader from "../../loading/DotsPreloader";
import ErrorHandler from "../ErrorPage/ErrorHandler";

const User = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUser);
    const sessionIsLoading = useSelector(selectSessionIsLoading);
    const sessionHasError = useSelector(selectSessionHasError);
    const loginStatus = useSelector(selectLoginStatus);
    const navigate = useNavigate();
    const wishlistArr = useSelector(selectWishlist);
    const isLoadingWishlist = useSelector(selectIsLoadingWishlist);
    const hasErrorWithWishlist = useSelector(selectHasErrorWithWishlist);
    
    useEffect(() => {

        if(!sessionIsLoading && !sessionHasError && loginStatus) {
            dispatch(loadWishlist());
        }

    }, [sessionIsLoading, sessionHasError, loginStatus]);

    if(sessionIsLoading && !sessionHasError && !loginStatus) {
        return (        
            <>
                <Header />
                <div className="vh-100">
                    <DotsPreloader />
                </div>
            </>
        );
    };

    if(!sessionIsLoading && sessionHasError && !loginStatus) {
        return (
            <ErrorHandler />
        )
    };

    return (
        <>
            <Header />
            <div id="profile-page-body-container" className="mx-auto px-2 py-3 p-lg-3 p-xxl-5">
                <Container className="mx-auto px-lg-4 px-xxl-5 mb-5">
                    <Row>
                        <Col xs={12} lg={5} 
                            className="min-height mx-auto mb-4 my-lg-0 py-4 text-center border shadow bg-light-subtle rounded"
                        >
                            <Image roundedCircle 
                                src={!userInfo.picture || userInfo.picture=="-1" ? "/avatar/profile_pic_cat.jpg" : userInfo.picture}
                                className="profile-img-resize mx-auto d-block mx-auto mb-4"
                            />
                            <Table className="mb-4 rounded">
                                <tbody>
                                    <tr>
                                        <td className="fw-light">First Name:</td>
                                        <td className="text-capitalize">{userInfo.firstName}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-light">Last Name:</td>
                                        <td className="text-capitalize">{userInfo.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-light">Email:</td>
                                        <td>{userInfo.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="fw-light">Joined on:</td>
                                        <td>{userInfo.created}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Link to="/edit">
                                <Button variant="outline-dark" className="mx-auto w-50">
                                    <p className="m-0">Edit Profile</p>
                                </Button>
                            </Link>
                        </Col>

                        <Col xs={12} lg={7} id="user-wishlist-section" className="mx-auto px-0 px-lg-2">
                            <Tabs defaultActiveKey="wishlist" className="border-0">
                                <Tab eventKey="wishlist" title="Wishlist" className="p-2 p-xl-4 border">
                                    <WishList
                                        Container={Container}
                                        Row={Row}
                                        Col={Col}
                                        Card={Card}
                                        Button={Button}
                                        Link={Link}
                                        navigate={navigate}
                                        dispatch={dispatch}
                                        wishlistArr={wishlistArr}
                                        isLoadingWishlist={isLoadingWishlist}
                                        hasErrorWithWishlist={hasErrorWithWishlist}
                                        Spinner={Spinner}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );

};

export default User;