import "../../stylesheets/Header.css";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LuWine } from "react-icons/lu";
import { GiGrapes } from "react-icons/gi";
import { SlLocationPin } from "react-icons/sl";
import { RiSearchLine } from "react-icons/ri";
import { MdOutlineLocalOffer } from "react-icons/md";
import HeaderGrapesNav from "./HeaderGrapesNav";
import { 
    modifyConditionToSingleGRC,
    modifyConditionBasedOnPrice,
} from "../../features/productSlice";
import HeaderRegionNav from "./HeaderRegionNav";
import HeaderOfferNav from "./HeaderOfferNav";
import { Col, Container, Row } from "react-bootstrap";
import Search from "../Search/Search";
import HeaderUserSection from "./HeaderUserSection";
import ResponsiveHeaderNavMenu from "./ResponsiveHeaderNavMenu";


const Header = () => {

    const dispatch = useDispatch();

    // product dropdown event handlers
    const showProductDropdown = (hideTimoutRef, setVisible) => {
        if (hideTimoutRef.current) {
            clearTimeout(hideTimoutRef.current);
        }
        setVisible(true);
    };

    const hideProductDropdown = (hideTimoutRef, setVisible) => {
        hideTimoutRef.current = setTimeout(() => {
            setVisible(false);
        }, 200);
    };

    const showBackgroundCover = () => {
        document.getElementById("bg-cover-for-search").classList.remove("d-none");
    };

    const removeBackgroundCover = () => {
        document.getElementById("bg-cover-for-search").classList.add("d-none");
    };


    return(
        <div className="px-1 bg-body shadow position-relative z-2">
            <Container className="pt-2 px-2 px-lg-3 px-xxl-5 mx-xxl-5">
                <Row id="top-header" className="align-items-center">

                    <Col xs={2} className="d-lg-none px-0">
                        <ResponsiveHeaderNavMenu 
                            Container={Container}
                            Row={Row}
                            Col={Col}
                            NavLink={NavLink}
                            dispatch={dispatch}
                            modifyConditionBasedOnPrice={modifyConditionBasedOnPrice}
                            MdOutlineLocalOffer={MdOutlineLocalOffer}
                            GiGrapes={GiGrapes}
                            modifyConditionToSingleGRC={modifyConditionToSingleGRC}
                            SlLocationPin={SlLocationPin}
                            useState={useState}
                        />
                    </Col>

                    <Col xs={5} sm={3} className="px-0 px-sm-2 px-lg-3">
                        <div>
                            <NavLink id="header-logo" className="fs-2" to="/">
                                <span id="header-logo-j">J·</span>CELLAR
                            </NavLink>
                        </div>
                    </Col>

                    <Col lg={5} className="d-none d-lg-block">
                        <Search 
                            NavLink={NavLink}
                            RiSearchLine={RiSearchLine}
                            useState={useState}
                            dispatch={dispatch}
                            useSelector={useSelector}
                            showBackgroundCover={showBackgroundCover}
                            removeBackgroundCover={removeBackgroundCover}
                        />
                    </Col>

                    <Col xs={5} sm={4} className="d-flex justify-content-center justify-content-lg-end ms-auto">
                        <HeaderUserSection 
                            useSelector={useSelector}
                            dispatch={dispatch}
                            NavLink={NavLink}
                            useState={useState}
                        />
                    </Col>

                </Row>

                <Row id="products-navigation-header" className="d-flex align-items-center">

                    <Col xs={7} className="mx-lg-auto d-lg-none">
                        <Search 
                            NavLink={NavLink}
                            RiSearchLine={RiSearchLine}
                            useState={useState}
                            dispatch={dispatch}
                            useSelector={useSelector}
                            showBackgroundCover={showBackgroundCover}
                            removeBackgroundCover={removeBackgroundCover}
                        />
                    </Col>

                    <Col xs={5} lg={8} xl={7} xxl={6} className="mx-auto ms-lg-0 d-flex align-items-center justify-content-center justify-content-lg-start">
                        <Row className="w-100 mx-auto">

                            <Col lg={3} className="text-center text-lg-start d-block my-auto">
                                <NavLink className="text-nowrap d-flex align-items-center justify-content-center justify-content-lg-start text-decoration-none text-black"
                                    to="/product"
                                >
                                    <LuWine className="products-navigation-icons"/> 
                                    <h6 className="products-navigation-text" id="navigation-all-wines">All Wines</h6>
                                </NavLink>
                            </Col>

                            <Col lg={3} className="d-none d-lg-flex position-relative">
                                <HeaderOfferNav 
                                    dispatch={dispatch}
                                    NavLink={NavLink}
                                    MdOutlineLocalOffer={MdOutlineLocalOffer}
                                    showProductDropdown={showProductDropdown}
                                    hideProductDropdown={hideProductDropdown}
                                    modifyConditionBasedOnPrice={modifyConditionBasedOnPrice}
                                />
                            </Col>

                            <Col lg={3} className="d-none d-lg-flex position-relative">
                                <HeaderGrapesNav 
                                    dispatch={dispatch}
                                    NavLink={NavLink}
                                    GiGrapes={GiGrapes}
                                    showProductDropdown={showProductDropdown}
                                    hideProductDropdown={hideProductDropdown}
                                    modifyConditionToSingleGRC={modifyConditionToSingleGRC}
                                />
                            </Col>

                            <Col lg={3} className="d-none d-lg-flex position-relative">
                                <HeaderRegionNav 
                                    dispatch={dispatch}
                                    NavLink={NavLink}
                                    SlLocationPin={SlLocationPin}
                                    showProductDropdown={showProductDropdown}
                                    hideProductDropdown={hideProductDropdown}
                                    modifyConditionToSingleGRC={modifyConditionToSingleGRC}
                                    Row={Row}
                                    Col={Col}
                                />
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};


export default Header;