import { Button, Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import { FaAnglesRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import "../../stylesheets/CartOffcanvas.css";

const CartOffcanvas = ({ showCartOffcanvas, setShowCartOffcanvas, cartSummary }) => {

    return (
        <Offcanvas 
            show={showCartOffcanvas} 
            onHide={() => setShowCartOffcanvas(false)} 
            placement="end"
            id="offcavans-container"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-3 text-warning-emphasis">Cart Items</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0 px-3">
                <Link to="/cart"
                    className="p-0 text-decoration-none text-warning-emphasis fw-semibold"
                >
                    <Button variant="warning" className="w-100 text-center rounded-5">
                        Go To Cart <FaAnglesRight />
                    </Button>
                </Link>
                <Container className="px-0">
                    {
                        cartSummary.length===0 && 
                        <p className="my-5 text-center">
                            No Item
                        </p>
                    }
                    {
                        cartSummary && cartSummary.map(itemObj => (
                            <Card key={itemObj.product_id} className="p-2 my-2 rounded-5 border-secondary-subtle">
                                <Row className="my-auto">
                                    <Col xs="6" className="d-flex align-items-center justify-content-center px-0">
                                        <Card.Img 
                                            src={`/wineImg/${itemObj.picture}.png`}
                                            alt={`${itemObj.picture} picture`}
                                            className="offcanvas-product-img"
                                        />
                                    </Col>
                                    <Col xs="6" className="px-0">
                                        <Card.Body className="d-flex flex-column align-items-center justify-content-evenly h-100 px-0">
                                            <p className="mb-0">£{itemObj.price.toFixed(2)} / bottle</p>
                                            <p className="mb-0">{itemObj.quantity} {Number(itemObj.quantity)>1?"bottles":"bottle"}</p>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        ))
                    }
                </Container>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default CartOffcanvas;