import React from "react";
import { Card, Placeholder } from "react-bootstrap";

const WineStarRatingBtnLoading = () => {

    return (
        <Card.Body >
            <Placeholder as={Card.Title} animation="glow" className="text-center fs-1">
                <Placeholder xs={6} size="lg" bg="secondary" className="rounded-1"/>
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow" className="text-center mb-1">
                <Placeholder xs={10} size="sm" bg="secondary" className="rounded-1"/>
            </Placeholder>
            <Placeholder.Button 
                variant="success" 
                animation="wave"
                size="lg" 
                xs={12} 
                className="text-center rounded-pill fs-3"
            />
        </Card.Body>
    );

}

export default WineStarRatingBtnLoading;