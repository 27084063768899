import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    selectCondition, 
    selectFilter, 
    selectProductHasError, 
    selectProductIsLoading, 
    selectProductList, 
} from "../../features/productSlice";
import "../../stylesheets/Products.css";
import ProductList from "./ProductList";
import FilterList from "./FilterList";
import ProductHeadline from "./ProductHeadline";
import Header from "../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import FilterButton from "./FilterButton";
import Footer from "../Footer/Footer";


const Products = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectFilter);
    const condition = useSelector(selectCondition);
    // products
    const products = useSelector(selectProductList);
    const productIsLoading = useSelector(selectProductIsLoading);
    const productHasError = useSelector(selectProductHasError);

    return (
        <>
        <Header />
        <div id="products-body-container" className="pb-5">
            <Container className="px-0 px-lg-2 px-xxl-5 mx-xxl-5 pb-5">

                <ProductHeadline 
                    numOfSelections={products.length} 
                    condition={condition}
                    filterType={filter.type}
                    dispatch={dispatch}
                    Row={Row}
                />

                <Row className="mt-1 d-flex d-lg-none justify-content-center">
                    <FilterButton 
                        filter={filter}
                        dispatch={dispatch}
                        condition={condition}
                        Row={Row}
                        Col={Col}
                    />
                </Row>
                
                <Row id="products-main-container" className="my-3 mx-0">
                    
                    <Col lg={4} 
                        id="products-filters-container"
                        className="d-none d-lg-flex me-lg-auto"
                    >
                        <FilterList 
                            filter={filter}
                            dispatch={dispatch} 
                            condition={condition} 
                            prefixName="column-filter"
                            Row={Row}
                            Col={Col}
                        />
                    </Col>

                    <Col xs={12} lg={8}
                        className="d-flex px-sm-0 ps-lg-2 ps-xl-4"
                    >
                        <ProductList 
                            products={products}
                            productIsLoading={productIsLoading}
                            productHasError={productHasError}
                            dispatch={dispatch} 
                            Col={Col}
                            Row={Row}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer />
        </>
    );

};


export default Products;