import React from "react";
import { ListGroup } from "react-bootstrap";
import { PiWineFill, PiWine, PiWineDuotone } from "react-icons/pi";


const TypeSearchResult = ({ NavLink, type, rank, handleClickTypeResult }) => {

    const displayContent = (wineType) => {
        if(wineType==="red") {
            return <PiWineFill className="fs-5 me-2 text-dark"/>
        } else if(wineType==="white") {
            return <PiWine className="fs-5 me-2 text-dark"/>
        } else {
            return <PiWineDuotone className="fs-5 me-2 text-dark"/>
        }
    };

    return (
        <>
        { rank===0 ? "" : <hr></hr> }
        <ListGroup variant="flush">
            <ListGroup.Item className="text-uppercase fw-medium fs-result-title border-0">shop wine by type</ListGroup.Item>
            {
                type && type.length > 0 && type.map((ele, index) => (
                    <NavLink to="/product" className="text-decoration-none" key={index}>
                        <ListGroup.Item action
                            className="text-capitalize fw-light border-0 d-flex align-items-center text-body-secondary"
                            onClick={() => handleClickTypeResult(ele)}
                        >
                            {displayContent(ele)}{ele}
                        </ListGroup.Item>
                    </NavLink>
                ))
            }
        </ListGroup>
        </>
    );

}

export default TypeSearchResult;