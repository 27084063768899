import React from "react";

const ItemListHeader = ({ Row, Col }) => {

    return (
        <Row className="mx-0 d-none d-lg-flex mb-lg-2">
            <Col lg={6}>
                <Row>
                    <Col lg={3} className="xxs-fs text-body-secondary fw-normal mx-auto text-uppercase text-center">product</Col>
                    <Col lg={9} className="xxs-fs text-body-secondary fw-normal mx-auto text-uppercase text-center"></Col>
                </Row>
            </Col>
            
            <Col lg={2} className="xxs-fs text-body-secondary fw-normal mx-auto text-uppercase text-center">quantity</Col>
            <Col lg={2} className="xxs-fs text-body-secondary fw-normal mx-auto text-uppercase text-center">item price</Col>
            <Col lg={2} className="xxs-fs text-body-secondary fw-normal mx-auto text-uppercase text-center">total</Col>
        </Row>
    );

}

export default ItemListHeader;