import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    loadOrderDetails, 
    selectHasErrorWithOrderDetails, 
    selectIsLoadingOrderDetails, 
    selectOrderDetails 
} from "../../features/orderSlice";
import OrderDetailsItems from "./OrderDetailsItems";
import "../../stylesheets/OrderDetails.css";
import Header from "../Header/Header";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { selectLoginStatus, selectSessionHasError, selectSessionIsLoading } from "../../features/sessionSlice";
import ItemListHeader from "../ItemList/ItemListHeader";
import Footer from "../Footer/Footer";
import DotsPreloader from "../../loading/DotsPreloader";
import ErrorHandler from "../ErrorPage/ErrorHandler";

const OrderDetails = () => {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const loginStatus = useSelector(selectLoginStatus);
    const sessionIsLoading = useSelector(selectSessionIsLoading);
    const sessionHasError = useSelector(selectSessionHasError);
    const detailsData = useSelector(selectOrderDetails);
    const hasErrorWithOrderDetails = useSelector(selectHasErrorWithOrderDetails);
    const isLoadingOrderDetails = useSelector(selectIsLoadingOrderDetails);

    useEffect(() => {

        if (loginStatus && !sessionIsLoading && !sessionHasError) {
            dispatch(loadOrderDetails({orderId}));
        }

    }, [sessionIsLoading, sessionHasError]);

    if(!loginStatus && !sessionIsLoading && sessionHasError) {
        return (
            <ErrorHandler />
        )
    };

    if (loginStatus && isLoadingOrderDetails && !hasErrorWithOrderDetails) {
        return (
            <>
                <Header />
                <div className="vh-100">
                    <DotsPreloader />
                </div>
            </>
        );
    };

    const { orderDetails, orderItems } = detailsData;
    return (
        <>
            <Header />
            <div id="order-details-body-container" className="pb-5">
                <Container className="px-2 py-4 px-lg-4 p-xxl-5">
                    <Row className="mb-4 mb-sm-5">
                        <Col xs={12} sm={10} md={8} lg={6}>
                            <div className="fs-4 text-secondary-emphasis fw-light mb-3 text-uppercase">Order Details</div>
                            { 
                                orderDetails &&                           
                                <ListGroup variant="flush" className="rounded shadow">
                                    <ListGroup.Item variant="light" className="fw-medium">Ordered on: <span className="text-secondary-emphasis ms-1 fw-semibold">{orderDetails.created_long}</span></ListGroup.Item>
                                    <ListGroup.Item variant="light" className="fw-medium">Paid on: <span className="text-secondary-emphasis ms-1 fw-semibold">{orderDetails.payment_date_long}</span></ListGroup.Item>
                                    <ListGroup.Item variant="light" className="fw-medium">Status: <span className="text-secondary-emphasis ms-1 fw-semibold text-capitalize">Delivering</span></ListGroup.Item>
                                    <ListGroup.Item variant="light" className="fw-medium">Payment: <span className="text-secondary-emphasis ms-1 fw-semibold text-capitalize">{orderDetails.payment_status}</span></ListGroup.Item>
                                    <ListGroup.Item variant="light" className="fw-medium">Quantity: <span className="text-secondary-emphasis ms-1 fw-semibold">{orderDetails.quantity}</span></ListGroup.Item>
                                    <ListGroup.Item variant="light" className="fw-medium">Total: <span className="text-secondary-emphasis ms-1 fw-semibold">£{orderDetails.total.toFixed(2)}</span></ListGroup.Item>
                                </ListGroup>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <div className="fs-4 text-secondary-emphasis fw-light mb-3 text-uppercase">Item List</div>
                        <Col xs={12} className="mx-auto">
                            <ItemListHeader 
                                Row={Row}
                                Col={Col}
                            />
                            <Container className="px-1 mx-auto mb-5 bg-light-subtle rounded shadow">
                                {
                                    orderItems && orderItems.map((itemObj, index) => (
                                        <OrderDetailsItems  
                                            key={index}
                                            itemObj={itemObj}
                                            index={index}
                                            Row={Row}
                                            Col={Col}
                                            Link={Link}
                                        />
                                    ))
                                }
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer marginTop={0}/>
        </>
    );
};


export default OrderDetails;