import React from "react";
import { selectSearchResult } from "../../features/productSlice";
import CountrySearchResult from "./CountrySearchResult";
import TypeSearchResult from "./TypeSearchResult";
import GrapeSearchResult from "./GrapeSearchResult";
import RegionSearchResult from "./RegionSearchResult";


const SearchResultDropdown = ({ NavLink, useSelector, handleClickTypeResult, handleClickGRCResult }) => {

    const resultObj = useSelector(selectSearchResult);
    const resultArr = Object.keys(resultObj).filter(ele => resultObj[ele].length > 0);

    const resultComponent = (ele, index) => {

        switch(ele) {
            case "type":
                return <TypeSearchResult NavLink={NavLink} type={resultObj[ele]} rank={index} handleClickTypeResult={handleClickTypeResult}/>;
            case "grapes":
                return <GrapeSearchResult NavLink={NavLink} grapes={resultObj[ele]} rank={index} handleClickGRCResult={handleClickGRCResult}/>;
            case "region":
                return <RegionSearchResult NavLink={NavLink} region={resultObj[ele]} rank={index} handleClickGRCResult={handleClickGRCResult}/>;
            case "country":
                return <CountrySearchResult NavLink={NavLink} country={resultObj[ele]} useSelector={useSelector} rank={index} handleClickGRCResult={handleClickGRCResult}/>;
            default:
                return;
        }
        
    };

    return (
        <>
            {
                resultArr.length===0 ? 
                <p>No Match</p>
                :
                resultArr.map((ele, index) => (
                    <div key={index}>
                        {resultComponent(ele, index)}
                    </div>
                ))
            }
        </>
    );

}

export default SearchResultDropdown;