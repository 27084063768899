import React from "react";

const WineImgLoading = () => {

    return (
        <img className="product-img"
            src="/wineImg/0_product_loading.png"
            alt="product loading img"
        />
    );

}

export default WineImgLoading;