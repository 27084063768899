import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Route, 
  RouterProvider, 
  createBrowserRouter, 
  createRoutesFromElements 
} from 'react-router-dom';
import Root from '../components/Root/Root';
import Home from '../components/Home/Home';
import User from '../components/User/User';
import Products from '../components/Product/Products';
import ProductDetails from '../components/Product/ProductDetails';
import Cart from '../components/Cart/Cart';
import Checkout from '../components/Checkout/Checkout';
import Orders from '../components/Orders/Orders';
import OrderDetails from '../components/Orders/OrderDetails';
import { 
  loadUserInfo, 
  selectLoginStatus, 
  selectSessionHasError, 
  selectSessionIsLoading, 
  selectUser 
} from '../features/sessionSlice';
import PaymentIntermedia from '../components/Payment/PaymentIntermedia';
import { UserInfoContext } from './Context';
import { loadFilter } from '../features/productSlice';
import { 
  handleAddItemToCart,
  handleRemoveItemFromCart,
  loadCartSummary,
  selectProcessOrderResult,
} from '../features/cartSlice';
import ProfileEdition from '../components/User/ProfileEdition';
import ErrorHandler from '../components/ErrorPage/ErrorHandler';


const router = createBrowserRouter(createRoutesFromElements(
  <Route path='/' element={ <Root/> } >
    <Route index element={ <Home/> } />
    <Route path='product' element={ <Products/> } />
    <Route path='product/:productId' element={ <ProductDetails /> } />
    <Route path='user/:userName' element={ <User /> } />
    <Route path='edit' element={<ProfileEdition />} />
    <Route path='cart' element={ <Cart /> } />
    <Route path='checkout' element={ <Checkout /> } />
    <Route path='checkout/:userName/feedback' element={ <PaymentIntermedia /> }/>
    <Route path='orders' element={ <Orders /> } />
    <Route path='orders/:orderId' element={ <OrderDetails /> } />
    <Route path='*' element={<ErrorHandler message="Page does not exist."/>} />
  </Route >
));


function App() {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const sessionIsLoading = useSelector(selectSessionIsLoading);
  const sessionHasError = useSelector(selectSessionHasError);
  const loginStatus = useSelector(selectLoginStatus);
  const processCarStatus = useSelector(selectProcessOrderResult);

  // checking if there is valid session when app starts or refreshes.
  useEffect(() => {

    if(!loginStatus) {
      dispatch(loadUserInfo());
    } 

    dispatch(loadFilter());

  }, [dispatch]);

  useEffect(() => {

    if (loginStatus) {
      dispatch(loadCartSummary());
    }

  }, [dispatch, handleAddItemToCart, handleRemoveItemFromCart, processCarStatus]);


  return (
    <UserInfoContext.Provider value={userInfo}>
      <RouterProvider router={router} />
    </UserInfoContext.Provider>
  )
}

export default App;