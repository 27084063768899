import React, {} from "react";
import { ListGroup } from "react-bootstrap";
import PwdAlphaNumericValidation from "../ValidationUnity/PwdAlphaNumericValidation";
import PwdUppercaseValidation from "../ValidationUnity/PwdUppercaseValidation";
import PwdLengthValidation from "../ValidationUnity/PwdLengthValidation";


const FormPwd = ({ 
    OverlayTrigger, Popover, Form, hide1st, toggle1st, password, FaEyeSlash, FaEye, handlePwdChange, checkLength, checkAlphaNumeric, checkUppercase, handlePwdKeyUp, useEffect, useState
}) => {

    const [browserWidth, setBrowserWidth] = useState(window.innerWidth);

    useEffect(() => {
                
        const updateBrowserWidth = () => {

            setBrowserWidth(window.innerWidth);

        };

        updateBrowserWidth();
        window.addEventListener("resize", updateBrowserWidth);

        return () => window.removeEventListener("resize", updateBrowserWidth);

    }, [browserWidth]);

    const pwdPopover = (
        <Popover>
            <Popover.Body className="px-1">
                <ListGroup variant="flush">
                    <PwdAlphaNumericValidation check={checkAlphaNumeric}/>
                    <PwdUppercaseValidation check={checkUppercase}/>
                    <PwdLengthValidation check={checkLength}/>
                </ListGroup>
            </Popover.Body>
        </Popover>
    );
    
    return (
        <OverlayTrigger  
            trigger="focus" 
            placement={browserWidth<768?"top":"left"}
            overlay={pwdPopover}
        >
            <Form.Floating className="my-4">
                <div className="position-absolute top-50 end-0 translate-middle-y px-3 fs-5 text-secondary">
                    { hide1st ? <FaEyeSlash className="clickable-icon" onClick={() => toggle1st()}/> : <FaEye className="clickable-icon" onClick={() => toggle1st()}/> }
                </div>
                <Form.Control 
                    className="input-field border-dark-subtle" 
                    id="register-password"
                    type={hide1st ? "password" : "text"}
                    name="password"
                    placeholder="Set yours"
                    onChange={handlePwdChange}
                    onKeyUp={handlePwdKeyUp}
                    value={password}
                    autoComplete="pw"
                    required
                />
                <label htmlFor="register-password" className="input-label text-black-50">Password</label>
            </Form.Floating>
        </OverlayTrigger>
    );
}

export default FormPwd;