import React from "react";
import ProductDetailsFunctionBoxPrice from "./ProductDetailsFunctionBoxPrice";
import ProductDetailsFunctionBoxBtn from "./ProductDetailsFunctionBoxBtn";
import ProductDetailsFunctionBoxEta from "./ProductDetailsFunctionBoxEta";

const ProductDetailsFunctionBox = ({
    price, HiMinusSm, decrement, num, increment, HiPlusSm, handleClickAddingToCartButton, CiDeliveryTruck, eta, Container, Row, Col
}) => {

    return (
        <Container className="w-100 p-4 bg-light shadow-lg">
            
            <Row>
                <Col xs={12} sm={6} lg={12} className="">

                    <ProductDetailsFunctionBoxPrice 
                        price={price}
                        Row={Row}
                    />

                    <Row className="d-none d-sm-flex d-lg-none mt-sm-4">
                        <ProductDetailsFunctionBoxEta 
                            CiDeliveryTruck={CiDeliveryTruck}
                            eta={eta}
                        />
                    </Row>

                </Col>
            
                <Col xs={12} sm={6} lg={12}>
                    <ProductDetailsFunctionBoxBtn 
                        HiMinusSm={HiMinusSm}
                        decrement={decrement}
                        num={num}
                        HiPlusSm={HiPlusSm}
                        increment={increment}
                        handleClickAddingToCartButton={handleClickAddingToCartButton}
                    />
                </Col>

                <Col xs={12} className="d-flex d-sm-none d-lg-flex">
                    <ProductDetailsFunctionBoxEta 
                        CiDeliveryTruck={CiDeliveryTruck}
                        eta={eta}
                    />
                </Col>

            </Row>

        </Container>
    );

};

export default ProductDetailsFunctionBox;