import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { 
    getFilter, 
    getProductDetails, 
    getProducts,
    getTopPicksForHome, 
} from "../utils/product";
import qs from "qs";
import { loadWishlistSummary } from "./sessionSlice";


export const loadFilter = createAsyncThunk(
    "product/loadFilter",
    async () => {
        const response = await getFilter();
        return response;
    }
);

export const loadProductList = createAsyncThunk(
    "product/loadProducts",
    async (_, { getState }) => {
        const startTime = Date.now();
        const minimumLoadingTime = 700;

        const { condition } = getState().product;
        const queryString = qs.stringify(condition, { arrayFormat: "brackets" });

        const response = await getProducts(queryString);

        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < minimumLoadingTime) {
            await new Promise((resolve) => setTimeout(resolve, minimumLoadingTime - elapsedTime));
        }

        return response;
    }
);

export const loadProductDetail = createAsyncThunk(
    "product/loadProductDetail",
    async(data, { dispatch, getState, rejectWithValue }) => {

        const { productId } = data;

        const response = await getProductDetails(productId);

        if(!response.ok) {
            return rejectWithValue();
        }

        response.data.grapes = response.data.grapes.split(',').map(ele => ele.trim());
        response.data.aroma = response.data.aroma.split(',').map(ele => ele.trim());

        const { loginStatus } = getState().session;

        if (loginStatus) {

            dispatch(loadWishlistSummary());

        };

        return response.data;

    }
);

export const loadTopPicks = createAsyncThunk(
    "product/getTopPicks",
    async() => {
        const response = await getTopPicksForHome();

        response.forEach(obj => {
            obj.grapes = obj.grapes.split(',').map(ele => ele.trim());
        });

        return response;
    }
);

const productSlice = createSlice({
    name: 'product',
    initialState: {
        product: [],
        isLoading: true,
        hasError: false,
        viewDetails: 'none',
        detail: {},
        isLoadingDetail: true,
        hasErrorWithDetail: false,
        condition: {
            type: ["red", "white"],
            price: [10, 75],
            rating: 4.2,
            grapes: [],
            region: [],
            country: [],
        },
        filter: {},
        isLoadingFilter: true,
        hasErrorWithFilter: false,
        topPicks: [],
        isLoadingTopPicks: true,
        hasErrorWithTopPicks: false,
        search: {
            type: ["red", "white", "rose"],
            grapes: [],
            region: [],
            country: [],
        },
    },
    reducers: {
        setProductListToDefault: (state) => {
            state.product = [];
            state.isLoading = true;
        },
        setViewDetails: (state, action) => {
            state.viewDetails = action.payload;
        },
        setCondtionType: (state, action) => {
            const targetedEle = state.filter.type[action.payload];
            if (state.condition.type.includes(targetedEle)) {
                state.condition.type = state.condition.type.filter(ele => ele !== targetedEle);
            } else {
                state.condition.type.push(targetedEle);
            }
        },
        setConditionPrice: (state, action) => {
            state.condition.price = action.payload;
        },
        setCondtionRating: (state, action) => {
            state.condition.rating = Number(action.payload);
        },
        setConditionGRC: (state, action) => {
            const { targetedEle, category } = action.payload;

            if (!state.condition[category].includes(targetedEle)) {
                // remove active elements from filter array
                state.filter[category].splice(0, state.condition[category].length);
                // add target element to the condition array
                state.condition[category].push(targetedEle);
                // return a new array that does not contain target element
                const shifted = state.filter[category].filter((ele) => ele !== targetedEle);
                // assign the filter arr to the concatenation of condition array and returned array by last step
                state.filter[category] = state.condition[category].concat(shifted);
            } else {
                // remove active elements from filter array
                state.filter[category].splice(0, state.condition[category].length);
                // remove target element from condition array
                state.condition[category] = state.condition[category].filter(ele => ele !== targetedEle);
                // add target element to filter arr and sort
                state.filter[category].push(targetedEle);
                state.filter[category].sort();
                // assign the filter arr to the concatenation of condition array and returned array by last step
                state.filter[category] = state.condition[category].concat(state.filter[category]);
            }
        },
        modifyConditionBasedOnPrice: (state, action) => {
            const { min, max } = action.payload;
            state.condition = {
                type: [],
                price: [min, max],
                rating: 0,
                grapes: [],
                region: [],
                country: [],
            };
        },
        modifyConditionToSingleGRC: (state, action) => {
            const { category, targetEle } = action.payload;
            state.condition = {
                ...state.condition,
                grapes: [],
                region: [],
                country: [],
            };
            state.condition = {
                ...state.condition,
                [category]: [targetEle],
                type: [],
                price: [0, 1000],
                rating: 0,
            };
            const shifted = state.filter[category].filter((ele) => ele !== targetEle);
            state.filter[category] = state.condition[category].concat(shifted);
        },
        setSearchResult: (state, action) => {
            const categories = ["type", "grapes", "region", "country"];
            categories.forEach(category => {
                state.search[category] = state.filter[category].filter(ele => ele.includes(action.payload));
            });
        },
        modifyConditionToSingleType: (state, action) => {
            state.condition = {
                type: [action.payload],
                price: [0, 1000],
                rating: 0,
                grapes: [],
                region: [],
                country: [],
            }
        }
    },
    extraReducers: (buidler) => {
        buidler
        .addCase(loadProductList.pending, (state) => {
            state.isLoading = true;
            state.hasError = false;
            state.product = [];
        })
        .addCase(loadProductList.fulfilled, (state, action) => {
            state.isLoading = false; 
            state.hasError = false;
            state.product = action.payload;
        })
        .addCase(loadProductList.rejected, (state) => {
            state.isLoading = false;
            state.hasError = true;
        })
        .addCase(loadFilter.pending, (state) => {
            state.isLoadingFilter = true;
            state.hasErrorWithFilter = false;
        })
        .addCase(loadFilter.fulfilled, (state, action) => {
            state.isLoadingFilter = false;
            state.hasErrorWithFilter = false;
            state.filter = action.payload;
        })
        .addCase(loadFilter.rejected, (state) => {
            state.isLoadingFilter = false;
            state.hasErrorWithFilter = true;
        })
        .addCase(loadProductDetail.pending, (state) => {
            state.isLoadingDetail = true;
            state.hasErrorWithDetail = false;
        })
        .addCase(loadProductDetail.fulfilled, (state, action) => {
            state.isLoadingDetail = false;
            state.hasErrorWithDetail = false;
            state.detail = action.payload;
        })
        .addCase(loadProductDetail.rejected, (state) => {
            state.isLoadingDetail = false;
            state.hasErrorWithDetail = true;
        })
        .addCase(loadTopPicks.pending, (state) => {
            state.isLoadingTopPicks = true;
            state.hasErrorWithTopPicks = false;
        })
        .addCase(loadTopPicks.fulfilled, (state, action) => {
            state.isLoadingTopPicks = false;
            state.hasErrorWithTopPicks = false;
            state.topPicks = action.payload;
        })
        .addCase(loadTopPicks.rejected, (state) => {
            state.isLoadingTopPicks = false;
            state.hasErrorWithTopPicks = true;
        })
    }
});

// product list part
export const selectProductList = state => state.product.product;
export const selectProductDetails = state => state.product.product[Number(state.product.viewDetails)];
export const selectProductIsLoading = state => state.product.isLoading;
export const selectProductHasError = state => state.product.hasError;
export const selectViewDetails = state => state.product.viewDetails;

// filter part
export const selectFilter = state => state.product.filter;
export const selectFilterIsLoading = state => state.product.isLoadingFilter;
export const selectFilterHasError = state => state.product.hasErrorWithFilter;
export const selectCountryCodeObj = state => state.product.filter.countryCode;

// product detail
export const selectProductDetail = state => state.product.detail;
export const selectIsLoadingDetail = state => state.product.isLoadingDetail;
export const selectHasErrorWithDetail = state => state.product.hasErrorWithDetail;

// condition part
export const selectCondition = state => state.product.condition;
export const selectConditionType = state => state.product.condition.type;
export const selectConditionPrice = state => state.product.condition.price;
export const selectConditionRating = state => state.product.condition.rating;
export const selectConditionGrapes = state => state.product.condition.grapes;
export const selectConditionRegion = state => state.product.condition.region;
export const selectConditionCountry = state => state.product.condition.country;

// topPicks part
export const selectTopPicks = state => state.product.topPicks;
export const selectTopPicksIsLoading = state => state.product.isLoadingTopPicks;
export const selectTopPicksHasError = state => state.product.hasErrorWithTopPicks;

// search part
export const selectSearchResult = state => state.product.search;

export const { 
    setViewDetails, 
    setConditionGRC,
    setCondtionType,
    setCondtionRating,
    setConditionPrice,
    modifyConditionBasedOnPrice,
    modifyConditionToSingleGRC,
    setSearchResult,
    modifyConditionToSingleType,
    setProductListToDefault,
} = productSlice.actions;

export default productSlice.reducer;