import React from "react";
import { HiOutlineLightBulb } from "react-icons/hi";


const PromptDropdown = ({ setShowCartOffcanvas }) => {

    return (
        <div className="prompt-dropdown text-center text-nowrap fs-6 p-4">
            <p className="mb-1 text-danger-emphasis d-flex align-items-center"><HiOutlineLightBulb className="me-1 fs-4 text-warning"/> New Item Added In Cart</p>
            <p className="hover-cursor mb-0 text-decoration-underline fw-light"
                onClick={() => setShowCartOffcanvas(true)}
            >
                View More
            </p>
        </div>
    );

}

export default PromptDropdown;