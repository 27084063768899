import React from "react";
import ItemListItems from "../ItemList/ItemListItems";

const CheckoutItem = ({ Row, Col, index, itemObj }) => {

    return (
        <>
            {index===0 ? "" : <hr className="my-0"></hr>}
            <Row key={index} className="py-2">
                <ItemListItems 
                    Row={Row}
                    Col={Col}
                    index={index}
                    itemObj={itemObj}
                />
            </Row>
        </>
    );

}

export default CheckoutItem;