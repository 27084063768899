import React from "react";
import { ListGroup, Tab } from "react-bootstrap";
import { IoMenu, IoClose } from "react-icons/io5";
import HeaderOfferNavDropdown from "./HeaderOfferNavDropdown";
import HeaderGrapesNavDropdown from "./HeaderGrapesNavDropdown";
import HeaderRegionNavDropdown from "./HeaderRegionNavDropdown";

const ResponsiveHeaderNavMenu = ({ 
    Container, Row, Col, NavLink, dispatch, modifyConditionBasedOnPrice, MdOutlineLocalOffer, GiGrapes, modifyConditionToSingleGRC, SlLocationPin, useState
}) => {

    const [showNavMenu, setShowNavMenu] = useState(false);

    return (
        <>
            <div className="position-relative d-flex align-items-center justify-content-center hover-cursor">
                {
                    showNavMenu ? 
                    <IoClose className="fs-2" onClick={() => setShowNavMenu(!showNavMenu)}/>
                    :
                    <IoMenu className="fs-3" onClick={() => setShowNavMenu(!showNavMenu)}/>
                }
            </div>
            {
                showNavMenu && 
                <Container id="responsive-nav-menu" 
                    className="border border-secondary-subtle z-3 mt-4 shadow rounded-2 border-0 mx-auto px-0"
                >
                    <Tab.Container defaultActiveKey="#offers">
                        <Row className="px-0 mx-auto">
                            <Col xs={3} className="px-0 border-0 border-end">
                                <ListGroup variant="flush" className="menu-option-name">
                                    <ListGroup.Item action href="#offers" className="px-0 text-center">
                                        <MdOutlineLocalOffer className="fs-6 me-1"/>Offers
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#grapes" className="px-0 text-center">
                                        <GiGrapes className="fs-6 me-1"/>Grapes
                                    </ListGroup.Item>
                                    <ListGroup.Item action href="#regions" className="px-0 text-center">
                                        <SlLocationPin className="fs-6 me-1"/>Regions
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                            <Col xs={9}>
                                <Tab.Content id="responsive-tab-container">
                                    <Tab.Pane eventKey="#offers" className="flex-column responsive-menu-height py-2">
                                        <HeaderOfferNavDropdown 
                                            NavLink={NavLink}
                                            dispatch={dispatch}
                                            modifyConditionBasedOnPrice={modifyConditionBasedOnPrice}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#grapes" className="flex-column flex-wrap responsive-menu-height py-2">
                                        <HeaderGrapesNavDropdown 
                                            NavLink={NavLink}
                                            modifyConditionToSingleGRC={modifyConditionToSingleGRC}
                                            dispatch={dispatch}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#regions" className="flex-column responsive-menu-height">
                                        <HeaderRegionNavDropdown 
                                            NavLink={NavLink}
                                            dispatch={dispatch}
                                            modifyConditionToSingleGRC={modifyConditionToSingleGRC}
                                            Row={Row}
                                            Col={Col}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            }
        </>
    );

}

export default ResponsiveHeaderNavMenu;