import React from "react";


const FormEmail = ({ 
    Form, showEmailMsg, emailMsg, email, setEmail, handleBlurEmail, PiWarningCircleLight, emailRef, handleEmailFocus
}) => {

    return (
        <Form.Floating className="my-4">
            {
                showEmailMsg && 
                <div className="position-absolute top-50 end-0 translate-middle-y px-3 fs-5 text-danger d-flex align-items-center">
                    <PiWarningCircleLight className="m-0 me-1" /><p className="fs-6 m-0">{emailMsg}</p>
                </div>
            }
            <Form.Control 
                ref={emailRef}
                className="input-field border border-dark-subtle" 
                id="register-email"
                type="email" 
                name="email"
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.currentTarget.value)}
                onFocus={() => handleEmailFocus(emailRef)}
                onBlur={() => handleBlurEmail(emailRef)}
                value={email}
                autoComplete="off"
                required
            />
            <label htmlFor="register-email" className="input-label text-black-50">Email Address</label>
        </Form.Floating>
    );

}

export default FormEmail;