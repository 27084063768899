import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Button, Col, Container, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { 
    selectLoginStatus,
    selectProfileErrorMessage,
    selectProfileHasError, 
    selectProfileIsLoading, 
    selectProfileNotifySuccess, 
    selectSessionHasError, 
    selectSessionIsLoading, 
    selectUser, 
    setProfileMsgDefault, 
    setProfileSuccessNotification, 
    updateProfileInfo
} from "../../features/sessionSlice";
import EditionFirstName from "./EditionFirstName";
import EditionLastName from "./EditionLastName";
import EditionEmail from "./EditionEmail";
import EditionPassword from "./EditionPassword";
import { FaCheckCircle } from "react-icons/fa";
import Footer from "../Footer/Footer";
import DotsPreloader from "../../loading/DotsPreloader";
import ErrorHandler from "../ErrorPage/ErrorHandler";


const ProfileEdition = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUser);
    const sessionIsLoading = useSelector(selectSessionIsLoading);
    const sessionHasError = useSelector(selectSessionHasError);
    const loginStatus = useSelector(selectLoginStatus);
    const profileIsLoading = useSelector(selectProfileIsLoading);
    const profileHasError = useSelector(selectProfileHasError);
    const profileErrorMessage = useSelector(selectProfileErrorMessage);
    const profileNotifySuccess = useSelector(selectProfileNotifySuccess);

    if(sessionIsLoading && !sessionHasError && !loginStatus) {
        return (        
            <>
            <Header />
            <div className="vh-100">
                <DotsPreloader />
            </div>
            <Footer />
            </>
        );
    };

    if(!sessionIsLoading && sessionHasError && !loginStatus) {
        return (
            <ErrorHandler />
        )
    };

    return (
        <>
            <Header />
            <div className="w-100 p-3 pb-5 p-xxl-5">
                <Container className="mb-5 pb-5">
                    <div className="">
                        <p className="fs-3 m-0 fw-medium text-secondary">Edition</p>
                    </div>
                    <hr className="my-1"></hr>
                    <Row className="mb-5">
                        <Col xs={12} md={8} className="px-0 px-sm-auto">
                            <ListGroup variant="flush">

                                <EditionFirstName 
                                    useEffect={useEffect}
                                    useState={useState}
                                    dispatch={dispatch}
                                    firstName={userInfo.firstName}
                                    updateProfileInfo={updateProfileInfo}
                                    profileIsLoading={profileIsLoading}
                                    profileHasError={profileHasError}
                                    profileErrorMessage={profileErrorMessage}
                                    setProfileMsgDefault={setProfileMsgDefault}
                                    profileNotifySuccess={profileNotifySuccess}
                                    setProfileSuccessNotification={setProfileSuccessNotification}
                                    ListGroup={ListGroup}
                                    Button={Button}
                                    Form={Form}
                                    Spinner={Spinner}
                                    FaCheckCircle={FaCheckCircle}
                                    Row={Row}
                                    Col={Col}
                                />

                                <EditionLastName 
                                    useEffect={useEffect}
                                    useState={useState}
                                    dispatch={dispatch}
                                    lastName={userInfo.lastName}
                                    updateProfileInfo={updateProfileInfo}
                                    profileIsLoading={profileIsLoading}
                                    profileHasError={profileHasError}
                                    profileErrorMessage={profileErrorMessage}
                                    setProfileMsgDefault={setProfileMsgDefault}
                                    profileNotifySuccess={profileNotifySuccess}
                                    setProfileSuccessNotification={setProfileSuccessNotification}
                                    ListGroup={ListGroup}
                                    Button={Button}
                                    Form={Form}
                                    Spinner={Spinner}
                                    FaCheckCircle={FaCheckCircle}
                                />

                                <EditionEmail 
                                    useEffect={useEffect}
                                    useState={useState}
                                    dispatch={dispatch}
                                    email={userInfo.email}
                                    updateProfileInfo={updateProfileInfo}
                                    profileIsLoading={profileIsLoading}
                                    profileHasError={profileHasError}
                                    profileErrorMessage={profileErrorMessage}
                                    setProfileMsgDefault={setProfileMsgDefault}
                                    profileNotifySuccess={profileNotifySuccess}
                                    setProfileSuccessNotification={setProfileSuccessNotification}
                                    ListGroup={ListGroup}
                                    Button={Button}
                                    Form={Form}
                                    Spinner={Spinner}
                                    FaCheckCircle={FaCheckCircle}
                                />

                                {
                                    userInfo && userInfo.authMethod==="local" && 
                                    <EditionPassword
                                        useSelector={useSelector}
                                        useEffect={useEffect}
                                        useState={useState}
                                        dispatch={dispatch}
                                        updateProfileInfo={updateProfileInfo}
                                        profileIsLoading={profileIsLoading}
                                        profileHasError={profileHasError}
                                        profileErrorMessage={profileErrorMessage}
                                        setProfileMsgDefault={setProfileMsgDefault}
                                        profileNotifySuccess={profileNotifySuccess}
                                        setProfileSuccessNotification={setProfileSuccessNotification}
                                        ListGroup={ListGroup}
                                        Button={Button}
                                        Form={Form}
                                        Spinner={Spinner}
                                        FaCheckCircle={FaCheckCircle}
                                    /> 
                                }

                            </ListGroup>
                        </Col>

                        <Col md={4} className="d-none d-md-block">
                            <div id="edition-bg-img"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );

};


export default ProfileEdition;