import React from "react";
import { HiPlusSm, HiMinusSm, } from 'react-icons/hi';
import { FaCheck, FaXmark, FaRegTrashCan } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { 
    manageProductIdArr, 
    removeCartItemsByProductId, 
    setToggleEditQuantity 
} from "../../features/cartSlice";
import { Card } from "react-bootstrap";
import CartItemsBtnSet from "./CartItemsBtnSet";


const CartItems = ({
    itemObj, index, onAddItemToCart, onRemoveItemFromCart, Container, Row, Col, Button
}) => {

    const dispatch = useDispatch();

    const handleSetToggleEditQuantity = (e) => {
        e.preventDefault();
        dispatch(setToggleEditQuantity(index)); 
        dispatch(manageProductIdArr({index, productId: itemObj.product_id}));
    };


    return (
        <Card className="item-box rounded-5 position-relative">
            <Button variant="outline-danger" 
                className={`cart-delete-btn-position rounded-3 px-2 py-1 ${itemObj.toggleEditQuantity?"d-flex":"d-none"} align-items-center justify-content-center`}
                onClick={() => dispatch(removeCartItemsByProductId({productId: itemObj.product_id}))}
            >
                <FaRegTrashCan className="mx-auto my-auto fs-6"/>
            </Button>
            <Container className="py-2">
                <Row>
                    <Col xs="4" className="d-flex jutify-content-center align-items-center">
                        <Card.Img 
                            src={`/wineImg/${itemObj.picture}.png`}
                            alt={`${itemObj.picture} picture`}
                            className="cart-product-img mx-auto w-50"
                        />
                    </Col>
                    <Col xs="8" className="mx-auto p-0 ">
                        <Card.Body className="d-flex flex-column justify-content-between h-100">
                            <Row className="text-capitalize">
                                <p className="cart-fs-x-larger text-black my-1 fs-6">
                                    {itemObj.winery}
                                </p>
                                <p className="cart-fs-x-larger text-black fw-semibold my-1 fs-6">
                                    {itemObj.grapes.length>2?'Blend':itemObj.grapes.join(', ')} {itemObj.vintage}
                                </p>
                                <p className="cart-fs-smaller my-2 text-black d-flex align-items-center">
                                    <span className={`fi fi-${itemObj.country_code} fis`}></span>
                                    {itemObj.region}, {itemObj.country}
                                </p> 
                            </Row>
                            <Row>
                                <p className="d-flex justify-content-start align-items-baseline m-0">
                                    <span className="fs-4 fw-semibold me-1">£{itemObj.price}</span><span>/ bottle</span>
                                </p>
                            </Row>

                            <Row className="d-none d-sm-flex">
                                <CartItemsBtnSet 
                                    toggleEditQuantity={itemObj.toggleEditQuantity}
                                    Row={Row}
                                    Col={Col}
                                    Button={Button}
                                    quantity={itemObj.quantity}
                                    onRemoveItemFromCart={onRemoveItemFromCart}
                                    index={index}
                                    HiMinusSm={HiMinusSm}
                                    onAddItemToCart={onAddItemToCart}
                                    product_id={itemObj.product_id}
                                    HiPlusSm={HiPlusSm}
                                    handleSetToggleEditQuantity={handleSetToggleEditQuantity}
                                    FaCheck={FaCheck}
                                    FaXmark={FaXmark}
                                />
                            </Row>

                        </Card.Body>
                    </Col>
                </Row>

                <Row className="d-flex d-sm-none align-items-center px-2 my-2">
                    <CartItemsBtnSet 
                        toggleEditQuantity={itemObj.toggleEditQuantity}
                        Row={Row}
                        Col={Col}
                        Button={Button}
                        quantity={itemObj.quantity}
                        onRemoveItemFromCart={onRemoveItemFromCart}
                        index={index}
                        HiMinusSm={HiMinusSm}
                        onAddItemToCart={onAddItemToCart}
                        product_id={itemObj.product_id}
                        HiPlusSm={HiPlusSm}
                        handleSetToggleEditQuantity={handleSetToggleEditQuantity}
                        FaCheck={FaCheck}
                        FaXmark={FaXmark}
                    />
                </Row>

            </Container>

            <Card.Footer >
                <Row className="cart-product-footer">
                    <Col xs="auto mx-auto">
                        <p className="m-0 fw-medium fs-5">
                            Total: 
                        </p>
                    </Col>
                    <Col xs="auto mx-auto">
                        <p className="m-0 fw-medium fs-5">
                            £ {(itemObj.quantity * itemObj.price).toFixed(2)}
                        </p>
                    </Col>
                </Row>
            </Card.Footer>

        </Card>
    )
};

export default CartItems;