import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderForIntermedia from "../Header/HeaderForIntermedia";

const ErrorHandler = ({ message="whoops, something went wrong." }) => {

    const navigate = useNavigate();

    const [countdown, setCountdown] = useState(5);

    useEffect(() => {

        const countdownInterval = setInterval(() => {
            setCountdown(prev => prev - 1);
        }, 1000);

        return () => clearInterval(countdownInterval);

    }, []);

    return (
        <>
        <HeaderForIntermedia />
        <div id="error-handler-body"
            className="d-flex justify-content-center vh-100"
        >
            <div className="mt-5 fs-6 lg-screen-fs-1point3rem">
                <p className="m-0 text-capitalize my-3 fw-semibold">
                    {message}
                </p>
                <p className="m-0 text-capitalize my-3">
                    Redirect in <span className="text-danger">{countdown}</span> seconds...
                </p>
                <p className="m-0 mt-5">
                    <small className="fw-medium text-warning-emphasis">To redirect immediately, click <Link to="/">here</Link>.</small>
                </p>
            </div>
        </div>
        {
            setTimeout(() => { navigate("/") }, 5000)
        }
        </>
    );

}

export default ErrorHandler;