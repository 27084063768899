import React from "react";

const ProductDetailsFunctionBoxBtn = ({
    HiMinusSm, decrement, num, HiPlusSm, increment, handleClickAddingToCartButton
}) => {

    return (
        <div className="my-4 mx-auto">
            <div id="details-quantity-info" className="rounded-pill w-100 my-1 mx-auto border border-secondary-subtle d-flex align-items-center justify-content-between">
                <HiMinusSm className="details-modify-quan-icon mx-3 text-black-50 fs-4"
                    onClick={decrement}
                />
                <h5 className="m-0 fs-5 fw-medium">
                    {num}
                </h5>
                <HiPlusSm className="details-modify-quan-icon mx-3 text-black-50 fs-4"
                    onClick={increment} 
                />
            </div>

            <button id="details-add-to-cart-button"
                onClick={handleClickAddingToCartButton}
                className="w-100 rounded-pill my-1 mx-auto"
            >
                <p className="m-0 fs-5 fw-semibold">Add to Cart</p>
            </button>
        </div>
    );

}

export default ProductDetailsFunctionBoxBtn;