import React from "react";
import { ListGroup } from "react-bootstrap";
import { LuGrape } from "react-icons/lu";


const GrapeSearchResult = ({ NavLink, grapes, rank, handleClickGRCResult }) => {

    return (
        <>
        { rank===0 ? "" : <hr></hr> }
        <ListGroup variant="flush">
            <ListGroup.Item className="text-uppercase fw-medium fs-result-title border-0">shop wine by grape</ListGroup.Item>
            {
                grapes && grapes.length > 0 && grapes.map((ele, index) => (
                    <NavLink to="/product" className="text-decoration-none" key={index}>
                        <ListGroup.Item action
                            className="text-capitalize fw-light border-0 d-flex align-items-center text-body-secondary"
                            onClick={() => handleClickGRCResult("grapes", ele)}
                        >
                            <LuGrape className="fs-5 me-2 text-dark"/>{ele}
                        </ListGroup.Item>
                    </NavLink>
                ))
            }
        </ListGroup>
        </>
    );

}

export default GrapeSearchResult;