import React from "react";
import { Button, Card } from "react-bootstrap";
import TopPicksProductList from "./TopPicksProductList";
import ProductListLoading from "../../loading/ProductListLoading";

const TopPicksBox = ({products, productIsLoading, productHasError, Row, Col, dispatch}) => {

    if(productIsLoading) {

        return (
            <Row xs={1} sm={2} lg={3} xl={4} className="mt-5 px-0 mx-auto">
                <ProductListLoading 
                    numOfcontainers={4} 
                    fixedDoubleDecker={true}
                />
            </Row>
        );

    }

    return (
        <Row xs={1} sm={2} lg={3} xl={4} className="mt-5 px-2 mx-auto">
            {
                products && products.length > 0 && products.map((product, index) => (
                    <TopPicksProductList 
                        key={index}
                        product={product}
                        Row={Row}
                        Col={Col}
                        dispatch={dispatch}
                        Button={Button}
                        Card={Card}
                    />
                ))
            }
        </Row>
    );

}

export default TopPicksBox;