import React from "react";
import { ListGroup } from "react-bootstrap";
import ValidationIcons from "./ValidationIcons";

const PwdUppercaseValidation = ({ check }) => {

    return (
        <ListGroup.Item className={`d-flex align-content-center ${check?"text-black-50 text-decoration-line-through fw-light":"text-danger-emphasis fst-italic"}`}>
            <ValidationIcons check={check}/> Contains at least 1 uppercase letter
        </ListGroup.Item>
    );

}

export default PwdUppercaseValidation;