import React from "react";
import "../../stylesheets/WineInfo.css";
import { Card } from "react-bootstrap";

const WineInfo = ({ winery, grapes, vintage, country_code, region, country, fixedDoubleDecker }) => {
    return (
        <Card.Body className={`px-2 pt-0 d-flex flex-column justify-cotent-between text-capitalize my-0 text-black fs-6 ${fixedDoubleDecker?'':'mt-lg-4'}`}>
            <p className={`my-1 ${fixedDoubleDecker?'':'my-lg-2 lg-screen-fs-1point2rem'}`}>
                {winery}
            </p>
            <p className={`my-1 fw-semibold ${fixedDoubleDecker?'product-vintage-cube-height':'my-lg-2 lg-screen-fs-1point2rem'}`}>
                {grapes.length>2?'Blend':grapes.join(', ')} {vintage}
            </p>
            <p className="assignated-font-family my-1 my-lg-2 fw-medium d-flex align-items-center fs-6">
                <span className={`fi fi-${country_code} fis`}></span>
                {region}, {country}
            </p>
        </Card.Body>
    );
}

export default WineInfo;
