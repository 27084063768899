import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { 
    addItemToCart, 
    clearItemsByProductId, 
    getCartInfoToDisplay, 
    getCartSummary, 
    handleCartStatusProcess, 
    subtractCartItemQuantity,
    updateCartPaymentProcess, 
} from "../utils/cart";
import { handleCheckout } from "../utils/checkout";
import { getPaymentInfo } from "../utils/stripe";
import { processNewOrder } from "../utils/orders";

export const loadCart = createAsyncThunk(
    "cart/loadCart",
    async (_, { getState }) => {

        const response = await getCartInfoToDisplay();
        
        if (response.length===0) { return response; };

        const underEditingProductIdArr = getState().cart.underEditingProductIdArr;

        response.forEach(obj => {
            // place all grapes into an array
            obj.grapes = obj.grapes.split(',').map(ele => ele.trim());
            // if any product is under editting in newly returned data then set its toggleEditQuantity to true
            if (underEditingProductIdArr.includes(obj.product_id)) {
                obj.toggleEditQuantity = true;
            } 
        });

        return response;

    }
);

// add an item to cart
export const handleAddItemToCart = createAsyncThunk(
    "cart/handleAddItemToCart",
    async (data, { dispatch, rejectWithValue, getState }) => {

        const { productId, num } = data;

        const response = await addItemToCart(productId, num);

        if(!response.ok) {
            dispatch(setRequirePrompt(false));
            return rejectWithValue();
        }
        
        dispatch(loadCart());

        dispatch(loadCartSummary());

        // pop up prompt when item being added on product details page
        if(getState().cart.editCartItem.requirePrompt) {

            dispatch(setPromptDropdown(true));

            const promptTimeout = setTimeout(() => { 
                
                dispatch(setPromptDropdown(false)); 
                dispatch(setRequirePrompt(false));
            
            }, 3000);
    
            dispatch(setTimeoutRefForPrompt(promptTimeout));

        }

    }
);

// remove an item from cart
export const handleRemoveItemFromCart = createAsyncThunk(
    "cart/handleRemoveItemFromCart",
    async (data, { dispatch, rejectWithValue }) => {

        const { itemId } = data;

        const response = await subtractCartItemQuantity(itemId);

        if(!response.ok) {
            return rejectWithValue();
        }

        dispatch(loadCart());

        dispatch(loadCartSummary());

    }
);

export const removeCartItemsByProductId = createAsyncThunk(
    "cart/removeCartItemsByProductId",
    async(data, { dispatch, rejectWithValue }) => {

        const{ productId } = data;

        const response = await clearItemsByProductId(productId);

        if(!response.ok) {
            return rejectWithValue();
        }

        dispatch(loadCart());
        
        dispatch(loadCartSummary());

    }
);

export const loadCartSummary = createAsyncThunk(
    "cart/loadCartSummary",
    async() => {

        const response = await getCartSummary();

        return response;
    }
);

export const processToPayment = createAsyncThunk(
    "cart/processToPayment",
    async(_, { getState }) => {

        const { firstName } = getState().session.session.user;
        const cartInfoArr = getState().cart.cart;
        const productIdArr = getState().cart.checkout.pickedItems;

        // mark user under payment process
        const updatedPaymentProcess = await updateCartPaymentProcess(true);

        const dataObj = {
            productIdArr, cartInfoArr, firstName
        };
        const response = await handleCheckout(dataObj);

        window.location.href = response.url;

    }
);

// verify and get payment status after stripe webhooks
export const getPaymentStatus = createAsyncThunk(
    "cart/getPaymentStatus",
    async(_ ,{ rejectWithValue }) => {

        const startTime = Date.now();
        const minimumPeriod = 2000;

        const response = await getPaymentInfo();

        const elapsedTime = Date.now() - startTime;
        if(elapsedTime < minimumPeriod) {
            await new Promise(resolve => setTimeout(resolve, minimumPeriod - elapsedTime));
        }

        if(!response.ok) {

            if(response.status && [403, 404, 500].includes(response.status)){
                return rejectWithValue(response.message);
            }

            return rejectWithValue('Whoops, Something Went Wrong.');

        }

        const { paymentStatus } = response;

        return paymentStatus;

    }
);

export const processForSuccess = createAsyncThunk(
    "cart/processForSuccess",
    async(_, { rejectWithValue, dispatch }) => {

        const startTime = Date.now();
        const minimumPeriod = 2000;
        
        const response = await processNewOrder();

        const elapsedTime = Date.now() - startTime;
        if(elapsedTime < minimumPeriod) {
            await new Promise(resolve => setTimeout(resolve, minimumPeriod - elapsedTime));
        }

        if(!response.ok || response.status===500) {
            return rejectWithValue(false);
        }

        // dispatch(loadCartSummary());

        return response.success;

    }
);

// handle on backend (to check failure of payment process)
export const processCarStatus = createAsyncThunk(
    "cart/processCarStatus",
    async(_, { rejectWithValue }) => {

        const cartStautsRes = await handleCartStatusProcess();
        
        const { cartStatus } = cartStautsRes;
        if (cartStatus) {
            return rejectWithValue('Unexpected cartStatus to be true.');
        }

        return cartStatus;
    }
);

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cart: [],
        isLoading: true,
        hasError: false,
        underEditingProductIdArr: [],
        editCartItem: {
            isLoading: false,
            hasError: false,
            success: false,
            message: '',
            requirePrompt: false,
            showPromptDropdown: false,
            timeoutRefForPrompt: null,
        },
        summary: [],
        summaryIsLoading: false,
        summaryHasError: false,
        totalQuan: 0,
        checkout: {
            totalAmount: 0.00,
            pickedItems: [],
            message: '',
            paymentStatus: 'none', // none is initial state, payload: a string, either paid or unpaid.
            checkPaymentStatusIsLoading: false,
            checkPaymentStatusHasError: false,
            // for payment check upon cart page loaded
            onPaymentProcess: 'none', // none is initial state, payload: t or f.
            isLoadingOnPaymentProcess: false, 
            hasErrorOnPaymentProcess: false,
        },
        processOrder: {
            isProcessingOrder: false,
            hasErrorOnProcessingOrder: false,
            success: false, // false is initial state, payload: true or false
            message: '',
        },
        processCancel: {
            hasUnfinishedPaymentAttempt: true, // boolen value indicating payment record already removed or not
            finish: false, // none is initial state, payload: a string, either paid or unpaid.
        },
    },
    reducers: {
        setToggleEditQuantity: (state, action) => {
            state.cart[action.payload].toggleEditQuantity = !state.cart[action.payload].toggleEditQuantity;
        },
        manageProductIdArr: (state, action) => {
            const { index, productId } = action.payload;
            // if user is editing quantity, push the productId to array
            if (state.cart[index].toggleEditQuantity) {
                state.underEditingProductIdArr.push(productId);
            } else {
                state.underEditingProductIdArr = state.underEditingProductIdArr.filter(id=>id!==productId);
            }
        },
        removefromProductIdArr: (state, action) => {
            state.underEditingProductIdArr = state.underEditingProductIdArr.filter(id=>id!==action.payload);
        },
        setPromptDropdown: (state, action) => {
            state.editCartItem.showPromptDropdown = action.payload;
        },
        setTimeoutRefForPrompt: (state, action) => {
            if(state.editCartItem.timeoutRefForPrompt) {
                clearTimeout(state.editCartItem.timeoutRefForPrompt);
            }
            state.editCartItem.timeoutRefForPrompt = action.payload;
        },
        setRequirePrompt: (state, action) => {
            state.editCartItem.requirePrompt = action.payload;
        },
        defaultCartStates: (state) => {
            state.cart = [];
            state.underEditingProductIdArr = [];
            state.summary = [];
            state.totalQuan = 0;
            state.checkout = {
                ...state.checkout,
                totalAmount: 0.00,
                pickedItems: [],
                paymentStatus: 'none', 
                onPaymentProcess: 'none', 
                message: '',
            };
            state.processOrder.success = false; 
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(loadCart.pending, (state) => {
            state.isLoading = true;
            state.hasError = false;
        })
        .addCase(loadCart.fulfilled, (state, action) => {
            const cartInfoArr = action.payload;
            state.cart = cartInfoArr;
            state.isLoading = false; 
            state.hasError = false;
            // if returned cartInfo array is not empty, then continue update state
            // otherwise skip the process.
            if (cartInfoArr.length>0) {
                let totalAmount = 0;
                let pickedItemsArr = [];
                cartInfoArr && cartInfoArr.forEach((obj) => {
                    totalAmount = obj.price * obj.quantity + totalAmount;
                    pickedItemsArr.push(obj.product_id);
                });
                state.checkout.totalAmount = parseFloat(totalAmount).toFixed(2);
                state.checkout.pickedItems = pickedItemsArr;
            }
        })
        .addCase(loadCart.rejected, (state) => {
            state.isLoading = false;
            state.hasError = true;
        })
        .addCase(loadCartSummary.pending, (state) => {
            state.summaryIsLoading = true;
            state.summaryHasError = false
        })
        .addCase(loadCartSummary.fulfilled, (state, action) => {
            state.summaryIsLoading = false;
            state.summaryHasError = false;
            state.summary = action.payload;
            if (action.payload.length > 0) {
                state.totalQuan = action.payload.reduce(
                    (accumulator, currentValue) => accumulator + Number(currentValue.quantity),
                    0,
                );
            } else {
                state.totalQuan = 0;
            }
        })
        .addCase(loadCartSummary.rejected, (state) => {
            state.summaryIsLoading = false;
            state.summaryHasError = true;
        })
        .addCase(processToPayment.pending, (state) => {
            state.isLoading = true;
            state.hasError = false;
        })
        .addCase(processToPayment.fulfilled, (state) => {
            state.isLoading = false;
            state.hasError = false;
        })
        .addCase(processToPayment.rejected, (state) => {
            state.isLoading = false;
            state.hasError = true;
        })
        .addCase(getPaymentStatus.pending, (state) => {
            state.checkout.checkPaymentStatusIsLoading = true;
            state.checkout.checkPaymentStatusHasError = false;
            state.checkout.message = '';
        })
        .addCase(getPaymentStatus.fulfilled, (state, action) => {
            state.checkout.checkPaymentStatusIsLoading = false;
            state.checkout.checkPaymentStatusHasError = false;
            state.checkout.message = '';
            state.checkout.paymentStatus = action.payload;
        })
        .addCase(getPaymentStatus.rejected, (state, action) => {
            state.checkout.checkPaymentStatusIsLoading = false;
            state.checkout.checkPaymentStatusHasError = true;
            state.checkout.message = action.payload;
        })
        .addCase(processForSuccess.pending, (state) => {
            state.processOrder.isProcessingOrder = true;
            state.processOrder.hasErrorOnProcessingOrder = false;
            state.processOrder.success = false;
            state.processOrder.message = '';
        })
        .addCase(processForSuccess.fulfilled, (state, action) => {
            // return t or f indicating completion status of order process
            state.processOrder.isProcessingOrder = false;
            state.processOrder.hasErrorOnProcessingOrder = false;
            state.processOrder.success = action.payload;
        })
        .addCase(processForSuccess.rejected, (state) => {
            state.processOrder.isProcessingOrder = false;
            state.processOrder.hasErrorOnProcessingOrder = true;
            state.processOrder.success = false;
            state.processOrder.message = "Error on server";
        })
        .addCase(processCarStatus.pending, (state) => {
            state.checkout.isLoadingOnPaymentProcess = true;
            state.checkout.hasErrorOnPaymentProcess = false;
        })
        .addCase(processCarStatus.fulfilled, (state, action) => {
            state.checkout.isLoadingOnPaymentProcess = false;
            state.checkout.hasErrorOnPaymentProcess = false;
            state.checkout.onPaymentProcess = action.payload;
        })
        .addCase(processCarStatus.rejected, (state) => {
            state.checkout.isLoadingOnPaymentProcess = false;
            state.checkout.hasErrorOnPaymentProcess = true;
        })
        .addCase(handleAddItemToCart.pending, (state) => {
            state.editCartItem.isLoading = true;
            state.editCartItem.hasError = false;
            state.editCartItem.success = false;
            state.editCartItem.message = '';
        })
        .addCase(handleAddItemToCart.fulfilled, (state) => {
            state.editCartItem.isLoading = false;
            state.editCartItem.hasError = false;
            state.editCartItem.success = true;
            state.editCartItem.message = "Added To Cart";
        })
        .addCase(handleAddItemToCart.rejected, (state) => {
            state.editCartItem.isLoading = false;
            state.editCartItem.hasError = true;
            state.editCartItem.success = false;
            state.editCartItem.message = "Something went wrong";
        })
        .addCase(handleRemoveItemFromCart.pending, (state) => {
            state.editCartItem.isLoading = true;
            state.editCartItem.hasError = false;
            state.editCartItem.success = false;
        })
        .addCase(handleRemoveItemFromCart.fulfilled, (state) => {
            state.editCartItem.isLoading = false;
            state.editCartItem.hasError = false;
            state.editCartItem.success = true;
        })
        .addCase(handleRemoveItemFromCart.rejected, (state) => {
            state.editCartItem.isLoading = false;
            state.editCartItem.hasError = true;
            state.editCartItem.success = false;
        })
    }
});

export const selectCartInfo = state => state.cart.cart;

export const selectUnderEditingProductIdArr = state => state.cart.underEditingProductIdArr;

export const selectTotalAmount = state => state.cart.checkout.totalAmount;

export const selectPickedItems = state => state.cart.checkout.pickedItems;

export const selectCartIsLoading = state => state.cart.isLoading;

export const selectCartHasError = state => state.cart.hasError;

export const selectCartSummary = state => state.cart.summary;

export const selectCartSummaryIsLoading = state => state.cart.summaryIsLoading;

export const selectCartSummaryHasError = state => state.cart.summaryHasError;

// check payment status
export const selectPaymentStatus = state => state.cart.checkout.paymentStatus;
export const selectCheckPaymentStatusIsLoading = state => state.cart.checkout.checkPaymentStatusIsLoading;
export const selectCheckPaymentStatusHasError = state => state.cart.checkout.checkPaymentStatusHasError;
export const selectPaymentStatusMessage = state => state.cart.checkout.message;

export const selectCartItemsTotalQuan = state => state.cart.totalQuan;

// process order after successful payment
export const selectProcessOrderIsLoading = state => state.cart.processOrder.isProcessingOrder;
export const selectProcessOrderHasError = state => state.cart.processOrder.hasErrorOnProcessingOrder;
export const selectProcessOrderResult = state => state.cart.processOrder.success;
export const selectProcessOrderMessage = state => state.cart.processOrder.message;

export const selectOnPaymentProcess = state => state.cart.checkout.onPaymentProcess;

export const selectIsLoadingOnPaymentProcess = state => state.cart.checkout.isLoadingOnPaymentProcess;

export const selectHasErrorOnPaymentProcess = state => state.cart.processCancel.hasErrorOnPaymentProcess;

export const selectHasRemovedPaymentRecord = state => state.cart.processCancel.hasUnfinishedPaymentAttempt;

// edit cart item
export const selectEditCartItemIsLoading = state => state.cart.editCartItem.isLoading;
export const selectEditCartItemHasError = state => state.cart.editCartItem.hasError;
export const selectEditCartItemShowPromptDropdown = state => state.cart.editCartItem.showPromptDropdown;
export const selectEditCartItemTimeoutForPrompt = state => state.cart.editCartItem.timeoutRefForPrompt;


export const { 
    setToggleEditQuantity, 
    manageProductIdArr, 
    removefromProductIdArr,
    setPromptDropdown,
    setTimeoutRefForPrompt,
    setRequirePrompt,
    defaultCartStates,
} = cartSlice.actions;


export default cartSlice.reducer;