import React from "react";
import WineStarRating from "../Card/WineStarRating";

const ProductDetailsRatingWishlist = ({ rating, wishlistSection }) => {

    return (
        <>
            <div className="d-flex align-items-center my-4 justify-content-center justify-content-md-start px-md-2 px-xl-3">
                <WineStarRating rating={rating} prefixName="product-details"/>
            </div>
            {
                wishlistSection()
            }
        </>
    );

};

export default ProductDetailsRatingWishlist;