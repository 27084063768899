import React, { useState } from "react";
import OrderItemsList from "./OrderItemsList";
import { TbListDetails } from "react-icons/tb";

const Order = ({ orderObj, index, Link, Row, Col }) => {

    const [hovered, setHovered] = useState(false);

    return (
        <>
            {index===0 ? "" : <hr className="my-2"></hr>}
            <Link to={`/orders/${orderObj.order_id}`} 
                className={`text-decoration-none text-success-emphasis mb-1 rounded position-relative ${hovered?"shadow-lg fw-semibold":""}`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                <TbListDetails className={`absolute-center-right fs-6 ${hovered?"d-inline-block":"d-none"}`}/>
                <Row className="mx-auto py-3 d-flex align-items-center">
                    <Col xs={8} lg={3} className="text-nowrap text-start text-lg-center">
                        {
                            orderObj.winery_vintage && orderObj.winery_vintage.map((ele, index) => (
                                <OrderItemsList  
                                    key={index}
                                    ele={ele}
                                    Row={Row}
                                    Col={Col}
                                />
                            ))
                        }
                    </Col>
                    
                    <Col xs={4} className="d-lg-none">
                        <p className="w-100 my-0 mx-auto px-1 text-start text-sm-center text-wrap">{orderObj.quantity} {orderObj.quantity>1?"bottles":"bottle"}</p>
                        <p className="w-100 my-0 mx-auto px-1 text-sm-center text-wrap">£{orderObj.total.toFixed(2)}</p>
                    </Col>

                    <Col lg={2} className="text-nowrap text-center d-none d-lg-block text-capitalize">{orderObj.created}</Col>
                    <Col lg={2} className="text-nowrap text-center d-none d-lg-block text-capitalize">{orderObj.status}</Col>
                    <Col lg={2} className="text-nowrap text-center d-none d-lg-block">{orderObj.quantity} {orderObj.quantity>1?"bottles":"bottle"}</Col>
                    <Col lg={2} className="text-nowrap text-center d-none d-lg-block">£{orderObj.total.toFixed(2)}</Col>
                </Row>
                <Row className="mx-auto d-lg-none">
                    <Col xs={8} className="text-nowrap text-sm-center text-capitalize px-1">
                        <small>Order on: {orderObj.created}</small>
                    </Col>
                    <Col xs={4} className="text-nowrap text-start text-sm-center text-capitalize">
                        <small>{orderObj.status}</small>
                    </Col>
                </Row>
            </Link>
        </>
    )
};


export default Order;