import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    processToPayment, 
    selectCartInfo, 
    selectTotalAmount 
} from "../../features/cartSlice";
import "../../stylesheets/Checkout.css";
import Header from "../Header/Header";
import { 
    Container, Row, Col, ListGroup, Button, Badge, Spinner, ToastContainer, Toast, Tabs, Tab, Table 
} from "react-bootstrap";
import { getCartPaymentProcess } from "../../utils/cart";
import { useNavigate } from "react-router-dom";
import { FaRegClipboard, FaCheck } from "react-icons/fa";
import CheckoutItem from "./CheckoutItem";
import ItemListHeader from "../ItemList/ItemListHeader";
import Footer from "../Footer/Footer";


const Checkout = () => {
    const checkoutItems = useSelector(selectCartInfo);
    const totalAmount = useSelector(selectTotalAmount);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const successCardNumberRef = useRef(null);
    const failCardNumberRef = useRef(null);
    const [showPaymentInfo, setShowPaymentInfo] = useState(true);
    const [copied, setCopied] = useState(false);
    const [proceed, setProceed] = useState(false);

    useEffect(() => {

        getCartPaymentProcess()
        .then((res) => {
            if(res.status) {
                navigate("/cart");
            }
        })

    }, []);

    useEffect(() => {

        if(copied) {
            setTimeout(() => setCopied(false), 2000);
        }

    }, [copied]);

    const handleClickPay = (e) => {
        e.preventDefault();
        setProceed(true);

        setTimeout(() => {
            dispatch(processToPayment());
        }, 500);
    };

    const handleCopyCardNumber = async(ref) => {

        if (!ref.current) return;

        const cardNumber = ref.current.innerText.replaceAll(' ', '');

        try {
            await navigator.clipboard.writeText(cardNumber);
            setTimeout(() => { setCopied(true) }, 100);
        } catch (error) {
            console.error(error.message);
        }

    };


    return (
    <>
        <Header />
        <div id="checkout-body-container" className="z-0">
            <Container className="p-2 py-sm-4 px-lg-4 py-lg-5 mx-xxl-5 p-xxl-5 position-relative">

                <Row className="mx-auto">
                    <p className="p-0 mb-4 text-success-emphasis fw-light fs-4 text-uppercase">Items To Checkout:</p>
                    <ItemListHeader 
                        Row={Row}
                        Col={Col}
                    />
                    <div className="bg-light bg-gradient rounded shadow">
                        <Container>
                        {
                            checkoutItems && checkoutItems.map((itemObj, index) => (
                                <CheckoutItem 
                                    key={index}
                                    Row={Row}
                                    Col={Col}
                                    index={index}
                                    itemObj={itemObj}
                                />
                            ))
                        }
                        </Container>
                    </div>
                    
                    <Row className="mx-auto my-3">
                        <Col xs={12} lg={5} className="mx-auto me-lg-0">
                            <ListGroup id="checkout-payment-info-cube" className="w-100 d-flex flex-column align-items-center">
                                <ListGroup.Item className="w-100 bg-transparent border-0 border-bottom">
                                    <Row>
                                        <Col xs={6} className="fw-medium fs-6 px-0">Subtotal</Col>
                                        <Col xs={6} className="fw-medium fs-6 px-0 text-end">£{totalAmount}</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item className="w-100 bg-transparent border-0 border-bottom">
                                    <Row>
                                        <Col xs={6} className="fw-medium fs-6 px-0">Tax</Col>
                                        <Col xs={6} className="fw-medium fs-6 px-0 text-end">Included</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item className="w-100 bg-transparent border-0 border-bottom">
                                    <Row>
                                        <Col xs={6} className="fw-medium fs-6 px-0">Shipping</Col>
                                        <Col xs={6} className="fw-medium fs-6 px-0 text-end text-uppercase text-success">FREE</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item className="w-100 bg-transparent border-0">
                                    <Row>
                                        <Col xs={6} className="fw-medium fs-6 px-0">Total</Col>
                                        <Col xs={6} className="fw-semibold fs-6 px-0 text-end">£{(Number(totalAmount)+0).toFixed(2)}</Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>

                            <Row className="my-4 mx-auto">
                                <Col xs={6} sm={5} md={4} lg={7} xl={6} className="ms-auto px-0">
                                {
                                    proceed ? 
                                    <Button variant="success" type="submit"
                                        className="d-flex align-items-center justify-content-center w-100 rounded-pill fw-normal py-2 fst-italic"
                                        disabled={true}
                                    >
                                        <Spinner 
                                            variant="light"
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-1 my-auto fs-6"
                                        />
                                        Processing
                                    </Button>
                                    :
                                    <Button variant="success" type="submit"
                                        className="w-100 rounded-pill fw-medium py-2 text-white"
                                        onClick={(e) => handleClickPay(e)}
                                    >
                                        PAY NOW
                                    </Button>
                                }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>

                {
                    showPaymentInfo ? 
                    <ToastContainer position="bottom-start" className="sticky-bottom" style={{zIndex: 1}}>
                        <Toast bg="warning" show={showPaymentInfo} onClose={() => setShowPaymentInfo(false)}>
                            <Toast.Header closeButton={true}>
                                <strong className="me-auto text-uppercase">Testing Payment Info</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <Tabs defaultActiveKey="successful" id="payment-testing-info" className="mb-3 payment-testing-info-tablist">
                                    <Tab eventKey="successful" title="Successful">
                                        <Table className="text-center">
                                            <tbody>
                                                <tr>
                                                    <td className="text-uppercase">Card Number</td>
                                                    <td className="payment-testing-number d-flex align-items-center justify-content-center fw-semibold"
                                                        onClick={() => handleCopyCardNumber(successCardNumberRef)}
                                                    >
                                                        <span ref={successCardNumberRef}>4000 0082 6000 0000</span>{copied ? <FaCheck className="ms-1 text-success fs-6"/>  : <FaRegClipboard className="ms-1 text-secondary fs-6"/>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-uppercase">CVC</td>
                                                    <td className="fw-medium">Any 3 digits</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-uppercase">DATE</td>
                                                    <td className="fw-medium">Any future date</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Tab>
                                    <Tab eventKey="declined" title="Declined">
                                        <Table className="text-center">
                                            <tbody>
                                                <tr>
                                                    <td className="text-uppercase">Card Number</td>
                                                    <td className="payment-testing-number d-flex align-items-center justify-content-center fw-semibold"
                                                        onClick={() => handleCopyCardNumber(failCardNumberRef)}
                                                    >
                                                        <span ref={failCardNumberRef}>4000 0000 0000 0002</span>{copied ? <FaCheck className="ms-1 text-success fs-6"/>  : <FaRegClipboard className="ms-1 text-secondary fs-6"/>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-uppercase">CVC</td>
                                                    <td className="fw-medium">Any 3 digits</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-uppercase">DATE</td>
                                                    <td className="fw-medium">Any future date</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Tab>
                                </Tabs>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>
                    :
                    <div className="sticky-bottom">
                        <Button variant="warning" 
                            className="position-relative rounded-3 d-flex align-items-center fw-semibold text-uppercase"
                            onClick={() => {setShowPaymentInfo(true)}}
                        >
                            Payment Info<Badge bg="danger" className="position-absolute top-0 start-100 translate-middle rounded-circle">!</Badge>
                        </Button>
                    </div>
                } 
                                
            </Container>
        </div>
        <Footer marginTop={0}/>
    </>
    );
}


export default Checkout;