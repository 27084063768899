import React, { useEffect, useRef, useState } from "react";
import HeaderOfferNavDropdown from "./HeaderOfferNavDropdown";

const HeaderOfferNav = ({
    dispatch, NavLink, MdOutlineLocalOffer, showProductDropdown, hideProductDropdown, modifyConditionBasedOnPrice
}) => {
    
    const [showDropdown, setShowDropDown] = useState(false);
    const dropdownTimeoutRef = useRef();

    useEffect(() => {
        if (dropdownTimeoutRef.current) {
            clearTimeout(dropdownTimeoutRef.current);
        }
    }, []);

    return (
        <div className="hover-cursor d-flex align-items-center text-decoration-none text-black"
            onMouseEnter = {() => showProductDropdown(dropdownTimeoutRef, setShowDropDown)}
            onMouseLeave = {() => hideProductDropdown(dropdownTimeoutRef, setShowDropDown)}
        >
            <MdOutlineLocalOffer className="products-navigation-icons"/> 
            <h6 className="products-navigation-text">Offers</h6>
            {
                showDropdown && 
                <div className="header-offer-dropdown p-4">
                    <HeaderOfferNavDropdown 
                        NavLink={NavLink} 
                        dispatch={dispatch}
                        modifyConditionBasedOnPrice={modifyConditionBasedOnPrice}
                    />
                </div>
            }
        </div>
    )
}

export default HeaderOfferNav;